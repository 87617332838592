import React, { useContext } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Search from './Search'
import { store } from '../../store'
import { useActions } from '../../actions'

const TradeDetails = () => {
  const { state, dispatch } = useContext(store)
  const { tradeDetails, viewDetails, EmailAuditInputFields } = state
  const { tradeupid } = state.SearchId
  const actions = useActions(state, dispatch)
  const { AccessToken, IdToken, RefreshToken } = state.Login
  const history = useHistory()
  // let { EmailAuditInputFields, getEmailAuditReportSuccess, emailReportSelectedPage, masterData } = state

  const tokenHeders = { AccessToken, IdToken, RefreshToken }

  const details = [
    { Key: 'Trade ID', Value: tradeDetails.TradeId },
    { Key: 'Trade ID expiry', Value: tradeDetails?.ExpiryDate ? moment(tradeDetails.ExpiryDate).format('DD MMM YYYY') : '-' },
    { Key: 'Trade IMEI', Value: tradeDetails.IMEI },
    { Key: 'Current Status', Value: tradeDetails.TradeStatus },
    { Key: 'Trade ID Creation Date', Value: tradeDetails?.TradeCreated ? moment(tradeDetails.TradeCreated).format('DD MMM YYYY') : '-' },
    { Key: 'Security Charge Date', Value: tradeDetails?.PrechargeDate ? moment(tradeDetails.PrechargeDate).format('DD MMM YYYY') : '-' },
    { Key: 'Refund Date', Value: tradeDetails?.RefundDate ? moment(tradeDetails.RefundDate).format('DD MMM YYYY') : '-' },
    { Key: 'Graded Status', Value: tradeDetails?.GradedStatus || '-' },
    { Key: 'Track Consignment', Value: tradeDetails?.ConsignmentId ? `https://auspost.com.au/mypost/track/#/details/${tradeDetails?.ConsignmentId}` : '-' },
    { Key: 'Late Fee Charge Date', Value: tradeDetails?.LateFeeChargeDate ? moment(tradeDetails.LateFeeChargeDate).format('DD MMM YYYY') : '-' },
    { Key: 'Late Fee Charge Amount', Value: tradeDetails?.LateFeeChargeAmount ? `$${tradeDetails?.LateFeeChargeAmount}` : '-' },
  ]

  const handleOrderDetailsClick = () => {
    actions.setOrderTab(true)
    actions.setTradeDetailsTab(false)
    history.replace('/confirmorder')
    actions.getTradeInfo(tradeupid, tokenHeders)
  }

  const handleEmailDataClick = () => {
    actions.setTradeDetailsTab(false)
    actions.setReportingTab(true)
    actions.setReportEmailAuditTab(true)
    actions.setEmailReportSelectedPage(0)
    actions.setEmailAuditTradeId(tradeDetails.TradeId)
    history.replace('/reports')
    const GetEmailAuditReportRequest = {
      TradeQuoteNbr: tradeDetails.TradeId,
      StartDate: EmailAuditInputFields && EmailAuditInputFields.StartDate,
      EndDate: EmailAuditInputFields && EmailAuditInputFields.EndDate,
      TemplateName: EmailAuditInputFields && EmailAuditInputFields.TemplateName,
      Status: EmailAuditInputFields && EmailAuditInputFields.Status,
      Email: EmailAuditInputFields && EmailAuditInputFields.searchEmail,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.getEmailAuditReport({ GetEmailAuditReportRequest })
  }

  return (
    <div className="large-desktop:min-h-885 tablet:min-h-772 ">
      <div className="flex flex-col  large-desktop:mx-100 mx-50">
        <Search />
      </div>
      {viewDetails && (
        <div className="m-15 flex flex-col justify-center items-center bg-white max-w-xl pb-4 mx-auto">
          <table className="bg-white w-full table-auto">
            <tbody className="">
              {details?.map((e) => (
                <tr className="border-t-2 border-b-2 h-50 bg-white">
                  <td className="text-left">{e?.Key}</td>
                  <td className="text-right">{e.Key === 'Track Consignment' && tradeDetails?.ConsignmentId ? <a rel="noopener noreferrer" href={e?.Value} target="_blank" className="text-blue-2 underline">{tradeDetails?.ConsignmentId}</a> : e?.Value || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="text-center text-3xl sm:text-2xl font-Samsung-Sharp-Sans-Bold py-30">Select Your Query Type</div> */}
          <div className="mt-30 sm:flex  sm:justify-center sm:max-w-500 flex flex-row justify-between mx-auto mb-12">
            <div className="text-center mt-4 mx-2 px-2">
              <button
                type="button"
                className="bg-black shadow hover:bg-black w-48 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded text-base"
                onClick={() => handleOrderDetailsClick(state)}
              >
                Order Details
              </button>
            </div>
            <div className="text-center mt-4 mx-2 px-2 ">
              <button
                type="button"
                className="bg-blue-600 shadow hover:bg-blue-700 w-180 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded text-base"
                onClick={() => handleEmailDataClick(true)}
              >
                Email Data
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TradeDetails
