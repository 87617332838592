import React from 'react'
import loader from './images/loader.gif'

const Loader = () => (
  <div className="z-1060 bg-black bg-opacity-10 fixed table-cell text-center align-middle w-full h-full top-0 bottom-0">
    <div className="relative inset-45">
      <img className="w-1/12" src={loader} alt="" />
    </div>
  </div>
)

export default Loader
