export default {
  SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
  UNSET_LOADING_INDICATOR: 'UNSET_LOADING_INDICATOR',
  API_ERROR: 'API_ERROR',
  INITIALIZE_REQUEST: 'INITIALIZE_REQUEST',
  INITIALIZE_SUCCESS: 'INITIALIZE_SUCCESS',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_CAPTCHA: 'SET_CAPTCHA',
  EXPIRE_CAPTCHA: 'EXPIRE_CAPTCHA',
  SET_LOCATION: 'SET_LOCATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  SET_TRADEUPID: 'SET_TRADEUPID',
  GET_TRADE_INFO_REQUEST: 'GET_TRADE_INFO_REQUEST',
  GET_TRADE_INFO_SUCCESS: 'GET_TRADE_INFO_SUCCESS',
  SET_EDIT: 'SET_EDIT',
  SET_FIRSTNAME: 'SET_FIRSTNAME',
  SET_LASTNAME: 'SET_LASTNAME',
  SET_MOBILENO: 'SET_MOBILENO',
  SET_ADDLINE1: 'SET_ADDLINE1',
  SET_ADDLINE2: 'SET_ADDLINE2',
  SET_ADDLINE3: 'SET_ADDLINE3',
  SET_ZIPCODE: 'SET_ZIPCODE',
  GET_CITY_STATE_REQUEST: 'GET_CITY_STATE_REQUEST',
  GET_CITY_STATE_SUCCESS: 'GET_CITY_STATE_SUCCESS',
  SET_CITY: 'SET_CITY',
  SET_STATE: 'SET_STATE',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_PREPOPULATED_DATA: 'SET_PREPOPULATED_DATA',
  SET_DEVICE_POPULAR_NAME: 'SET_DEVICE_POPULAR_NAME',
  SET_DEVICE_IMEI_CHECK: 'SET_DEVICE_IMEI_CHECK',
  SET_DEVICE_MODEL_CHECK: 'SET_DEVICE_MODEL_CHECK',
  SET_CUSTOMER_INFO_CHECK: 'SET_CUSTOMER_INFO_CHECK',
  SET_PHYSICAL_RESULTS_CHECK: 'SET_PHYSICAL_RESULTS_CHECK',
  SET_OPEN_POPUP: 'SET_OPEN_POPUP',
  UPDATE_TRADE_INFO_REQUEST: 'UPDATE_TRADE_INFO_REQUEST',
  UPDATE_TRADE_INFO_SUCCESS: 'UPDATE_TRADE_INFO_SUCCESS',
  CLOSE_BAR_BARCODE: 'CLOSE_BAR_BARCODE',
  SET_CURRENT_PASSWORD: 'SET_CURRENT_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_CONFIRM_NEW_PASSWORD: 'SET_CONFIRM_NEW_PASSWORD',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  SET_INVALID_PASSWORD: 'SET_INVALID_PASSWORD',
  GET_FMIP_STATUS_REQUEST: 'GET_FMIP_STATUS_REQUEST',
  GET_FMIP_STATUS_SUCCESS: 'GET_FMIP_STATUS_SUCCESS',
  GET_LOGISTICES_REQUEST: 'GET_LOGISTICES_REQUEST',
  GET_LOGISTICES_SUCCESS: 'GET_LOGISTICES_SUCCESS',
  SET_LOGISTICS_DATA: 'SET_LOGISTICS_DATA',
  SET_LOGISTICS_DATA_ALL: 'SET_LOGISTICS_DATA_ALL',
  SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_MAKE: 'SET_MAKE',
  SET_MODEL_VARRIANT: 'SET_MODEL_VARRIANT',
  LOGISTICS_LBL_CONDITION: 'LOGISTICS_LBL_CONDITION',
  CREATE_BATCH_REQUEST: 'CREATE_BATCH_REQUEST',
  SET_SELECTED_PAGE: 'SET_SELECTED_PAGE',
  SET_ORDER_DETAILS_TAB: 'SET_ORDER_DETAILS_TAB',
  CREATE_BATCH_SUCCESS: 'CREATE_BATCH_SUCCESS',
  GET_LOGISTICES_BATCH_SUCCESS: 'GET_LOGISTICES_BATCH_SUCCESS',
  SET_LOGISTICS_BATCH_DATA: 'SET_LOGISTICS_BATCH_DATA',
  CREATE_PARCEL_REQUEST: 'CREATE_PARCEL_REQUEST',
  CREATE_PARCEL_SUCCESS: 'CREATE_PARCEL_SUCCESS',
  GENERATE_PDF: 'GENERATE_PDF',
  SET_BATCH_NUMBER: 'SET_BATCH_NUMBER',
  SET_BATCH_CONSIGNMENT_NUMBER: 'SET_BATCH_CONSIGNMENT_NUMBER',
  SET_LOGISTICS_TAB: 'SET_LOGISTICS_TAB',
  SET_REPORTING_TAB: 'SET_REPORTING_TAB',
  SET_BATCH_SELECTED_PAGE: 'SET_BATCH_SELECTED_PAGE',
  HIDE_CANVAS_REPRINT: 'HIDE_CANVAS_REPRINT',
  SHOW_CANVAS_REPRINT: 'SHOW_CANVAS_REPRINT',
  GET_LOGISTICES_BATCH_REQUEST: 'GET_LOGISTICES_BATCH_REQUEST',
  SET_BATCH_START_DATE: 'SET_BATCH_START_DATE',
  SET_TRADE_ID_NUMBER: 'SET_TRADE_ID_NUMBER',
  SET_EMAIL_ID: 'SET_EMAIL_ID',
  NAVIGATE_TO_FORGOT_PASSWORD: 'NAVIGATE_TO_FORGOT_PASSWORD',
  FORGORT_PASSWORD_SUCCESS: 'FORGORT_PASSWORD_SUCCESS',
  FORGORT_PASSWORD_REQUEST: 'FORGORT_PASSWORD_REQUEST',
  FORGORT_PASSWORD_FAILURE: 'FORGORT_PASSWORD_FAILURE',
  SET_VERIFICATION_CODE: 'SET_VERIFICATION_CODE',
  CONFIRM_PASSWORD_REQUEST: 'CONFIRM_PASSWORD_REQUEST',
  CONFIRM_PASSWORD_SUCCESS: 'CONFIRM_PASSWORD_SUCCESS',
  CONFIRM_PASSWORD_FAILURE: 'CONFIRM_PASSWORD_FAILURE',
  SHOW_CANVAS: 'SHOW_CANVAS',
  HIDE_CANVAS: 'HIDE_CANVAS',
  REDIRECT_TO_LOGISTICS: 'REDIRECT_TO_LOGISTICS',
  DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  GET_FILE_REQUEST: 'GET_FILE_REQUEST',
  GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
  SET_RECURRING: 'SET_RECURRING',
  SET_BATCH_SELECTED_PAGE: 'SET_BATCH_SELECTED_PAGE',
  CREATE_BATCH_ONSUBMIT: 'CREATE_BATCH_ONSUBMIT',
  CREATE_BATCH_ONSUBMIT_SUCCESS: 'CREATE_BATCH_ONSUBMIT_SUCCESS',
  CREATE_PARCEL_ONSUBMIT_REQUEST: 'CREATE_PARCEL_ONSUBMIT_REQUEST',
  CREATE_PARCEL_ONSUBMIT_SUCCESS: 'CREATE_PARCEL_ONSUBMIT_SUCCESS',
  SET_COGNITO_HEADER: 'SET_COGNITO_HEADER',
  SET_ORDER_TAB: 'SET_ORDER_TAB',
  SET_REPORT_SUMMARY_TAB: 'SET_REPORT_SUMMARY_TAB',
  GET_REPORTING_DETAILS_REQUEST: 'GET_REPORTING_DETAILS_REQUEST',
  GET_REPORTING_DETAILS_SUCCESS: 'GET_REPORTING_DETAILS_SUCCESS',
  SET_MASTER_VIEW_DATA: 'SET_MASTER_VIEW_DATA',
  SET_REPORT_SELECTED_PAGE: 'SET_REPORT_SELECTED_PAGE',
  SET_OSOT_REPORT_DATA: 'SET_OSOT_REPORT_DATA',
  SET_REPORT_DETAILS_TAB: 'SET_REPORT_DETAILS_TAB',
  SET_REPORT_CARE_CREDIT_TAB: 'SET_REPORT_CARE_CREDIT_TAB',
  SET_SHOW_OSOT_REPORT_DATA: 'SET_SHOW_OSOT_REPORT_DATA',
  GET_ROLE_ACCESS_REQUEST: 'GET_ROLE_ACCESS_REQUEST',
  GET_ROLE_ACCESS_SUCCESS: 'GET_ROLE_ACCESS_SUCCESS',
  SET_SEND_LABEL_CHECK: 'SET_SEND_LABEL_CHECK',
  SEND_EMAIL_LABEL_REQUEST: 'SEND_EMAIL_LABEL_REQUEST',
  SEND_EMAIL_LABEL_SUCCESS: 'SEND_EMAIL_LABEL_SUCCESS',
  SEND_EMAIL_LABEL_FAILURE: 'SEND_EMAIL_LABEL_FAILURE',
  GET_EXCEPTION_DETAILS_REQUEST: 'GET_EXCEPTION_DETAILS_REQUEST',
  GET_EXCEPTION_DETAILS_SUCCESS: 'GET_EXCEPTION_DETAILS_SUCCESS',
  GET_EXCEPTION_DETAILS_FAILURE: 'GET_EXCEPTION_DETAILS_FAILURE',
  SET_ESCALATION_CODE: 'SET_ESCALATION_CODE',
  SET_EXCEPTION_TRADEID: 'SET_EXCEPTION_TRADEID',
  SET_EXCEPTION_STARTDATE: 'SET_EXCEPTION_STARTDATE',
  SET_EXCEPTION_ENDDATE: 'SET_EXCEPTION_ENDDATE',
  GET_DAX_IMAGE_REQUEST: 'GET_DAX_IMAGE_REQUEST',
  GET_DAX_IMAGE_SUCCESS: 'GET_DAX_IMAGE_SUCCESS',
  UDPATE_EXCEPTION_ACTION_REQUEST: 'UDPATE_EXCEPTION_ACTION_REQUEST',
  UDPATE_EXCEPTION_ACTION_SUCCESS: 'UDPATE_EXCEPTION_ACTION_SUCCESS',
  UDPATE_EXCEPTION_ACTION_FAILURE: 'UDPATE_EXCEPTION_ACTION_FAILURE',
  SET_REPORT_TRADE_ID_NUMBER: 'SET_REPORT_TRADE_ID_NUMBER',
  SET_REPORT_END_DATE: 'SET_REPORT_END_DATE',
  SET_REPORT_STATE: 'SET_REPORT_STATE',
  SET_REPORT_STORE_NAME: 'SET_REPORT_STORE_NAME',
  SET_REPORT_STATUS: 'SET_REPORT_STATUS',
  SET_EXCEPTION_TAB: 'SET_EXCEPTION_TAB',
  SET_REPORT_START_DATE: 'SET_REPORT_START_DATE',
  /** email audit report */
  SET_REPORT_EMAIL_AUDIT_TAB: 'SET_REPORT_EMAIL_AUDIT_TAB',
  SET_EMAIL_AUDIT_STATUS: 'SET_EMAIL_AUDIT_STATUS',
  SET_EMAIL_AUDIT_TEMPLATE: 'SET_EMAIL_AUDIT_TEMPLATE',
  SET_EMAIL_AUDIT_TRADE_ID: 'SET_EMAIL_AUDIT_TRADE_ID',
  SET_EMAIL_AUDIT_START_DATE: 'SET_EMAIL_AUDIT_START_DATE',
  SET_EMAIL_AUDIT_END_DATE: 'SET_EMAIL_AUDIT_END_DATE',
  GET_EMAIL_AUDIT_REPORT_REQUEST: 'GET_EMAIL_AUDIT_REPORT_REQUEST',
  GET_EMAIL_AUDIT_REPORT_SUCCESS: 'GET_EMAIL_AUDIT_REPORT_SUCCESS',
  GET_EMAIL_AUDIT_REPORT_FAILURE: 'GET_EMAIL_AUDIT_REPORT_FAILURE',
  SET_EMAIL_REPORT_SELECTED_PAGE: 'SET_EMAIL_REPORT_SELECTED_PAGE',
  SET_EMAIL_AUDIT_EMAIL_SEARCH: 'SET_EMAIL_AUDIT_EMAIL_SEARCH',
  // Low Value Devices -- start
  SET_LVD_DEVICE_TYPE: 'SET_LVD_DEVICE_TYPE',
  SET_LVD_DEVICE_OFFERED: 'SET_LVD_DEVICE_OFFERED',
  SET_LVD_FIRST_NAME: 'SET_LVD_FIRST_NAME',
  SET_LVD_LAST_NAME: 'SET_LVD_LAST_NAME',
  SET_LVD_MOBILE_NO: 'SET_LVD_MOBILE_NO',
  SET_LVD_EMAIL_ADDRESS: 'SET_LVD_EMAIL_ADDRESS',
  SET_LVD_ADDRESS_LINE1: 'SET_LVD_ADDRESS_LINE1',
  SET_LVD_ADDRESS_LINE2: 'SET_LVD_ADDRESS_LINE2',
  SET_LVD_ADDRESS_LINE3: 'SET_LVD_ADDRESS_LINE3',
  SET_LVD_POSTAL_CODE: 'SET_LVD_POSTAL_CODE',
  SET_LVD_CITY: 'SET_LVD_CITY',
  SET_LVD_STATE: 'SET_LVD_STATE',
  SET_LVD_COUNTRY: 'SET_LVD_COUNTRY',
  SET_LVD_DEVICE_ACTIVE_ACCOUNT_LOCK: 'SET_LVD_DEVICE_ACTIVE_ACCOUNT_LOCK',
  SET_LVD_DEVICE_PIN_LOCK: 'SET_LVD_DEVICE_PIN_LOCK',
  SET_LVD_DEVICE_REPORT_LOST_OR_STOLEN: 'SET_LVD_DEVICE_REPORT_LOST_OR_STOLEN',
  SET_LVD_DEVICE_POWERED_OFF: 'SET_LVD_DEVICE_POWERED_OFF',
  SET_LVD_DEVICE_FACTORY_RESET: 'SET_LVD_DEVICE_FACTORY_RESET',
  SET_LVD_CUSTOMER_INFO_CHECK: 'SET_LVD_CUSTOMER_INFO_CHECK',
  CREATE_QUOTE_LVD_REQUEST: 'CREATE_QUOTE_LVD_REQUEST',
  CREATE_QUOTE_LVD_SUCCESS: 'CREATE_QUOTE_LVD_SUCCESS',
  SET_LVD_DEVICE_IMEI: 'SET_LVD_DEVICE_IMEI',
  SET_LVD_QUESTIONS: 'SET_LVD_QUESTIONS',
  GET_AMTA_CHECK_LVD_REQUEST: 'GET_AMTA_CHECK_LVD_REQUEST',
  GET_AMTA_CHECK_LVD_SUCCESS: 'GET_AMTA_CHECK_LVD_SUCCESS',
  SET_LVD_AMTA_CALL: 'SET_LVD_AMTA_CALL',
  SET_OPEN_LVD_POPUP: 'SET_OPEN_LVD_POPUP',
  SET_LVD_TAB: 'SET_LVD_TAB',
  CLOSE_LVD_BAR_BARCODE: 'CLOSE_LVD_BAR_BARCODE',
  SET_TRADEDETAILS_TAB: 'SET_TRADEDETAILS_TAB',
  GET_TRADE_DETAILS: 'GET_TRADE_DETAILS',
  GET_TRADE_DETAILS_SUCCESS: 'GET_TRADE_DETAILS_SUCCESS',
  // Low Value Devices -- end
}
