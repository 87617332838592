import React, { useContext } from 'react'
import OrderDetails from './orderdetails'
import BatchDetails from './batchdetails'
import { store } from '../../store'
import { useActions } from '../../actions'
import LabeledInputField from '../../../utils/custom-fields'

import { MemoryRouter as Router, Switch, Route, Link } from 'react-router-dom'

const Logistics = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const batchDetailsTab = () => {
    actions.setOrderDetailsTab(false)
  }

  const orderDetailsTab = () => {
    actions.setOrderDetailsTab(true)
    let GetDeviceListRequest = {
      PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
      PartnerParentCode: state && state.Login && state.Login.PartnerParentStoreCode,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.setSelectedPage(0)
    actions.getLogistics({ GetDeviceListRequest })
  }

  let LogisticsOrderDetailsTab = state && state.LogisticsOrderDetailsTab
  return (
    <Router>
      <div className="large-desktop:min-h-925">
      <div className="large-desktop:mx-100 mx-50 mb-50 laptop:min-h-549 font-SamsungOne-400">
        <div className="flex items-center pt-30">
          <h2 className="text-4xl font-bold leading-37 p-1 font-Samsung-Sharp-Sans-Bold">Logistics</h2>
          <div className={`${LogisticsOrderDetailsTab ? 'text-blue-1' : 'text-grey-1 '} text-17 leading-22 text-center ml-5 font-bold font-SamsungOne-400`}>
            <Link
              to="/orderdetails"
              onClick={() => {
                orderDetailsTab()
              }}
              className="cursor-pointer ml-30"
            >
              Order Details
            </Link>
          </div>
          <div className={`${LogisticsOrderDetailsTab ? 'text-grey-1' : 'text-blue-1'} text-17 text-center font-bold font-SamsungOne-400`}>
            <Link
              to="/batchdetails"
              onClick={() => {
                batchDetailsTab()
              }}
              className="cursor-pointer ml-20"
            >
              Batch Details
            </Link>
          </div>
        </div>
        <Switch>
          <Route path="/orderdetails">
            <OrderDetails />
          </Route>
          <Route path="/batchdetails">
            <BatchDetails />
          </Route>
          <Route path="/">
            <OrderDetails />
          </Route>
        </Switch>
      </div>
      </div>
    </Router>
  )
}
export default Logistics
