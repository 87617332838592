import React from 'react'


import Search from './search-tradeupid'
import TradeInfo from './trade-info'

const ConfirmOrder = () => (
  <div>
    <div className="flex flex-col  large-desktop:mx-100 mx-50">
      <Search />
      <TradeInfo />
    </div>

  </div>
)
export default ConfirmOrder
