import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import LabeledInputField from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import shape from '../../images/Shape.png'

const Search = () => {
  const { state, dispatch } = useContext(store)
  const { tradeupid } = state.SearchId
  const actions = useActions(state, dispatch)
  const { AccessToken, IdToken, RefreshToken } = state.Login

  let tokenHeders = { AccessToken: AccessToken, IdToken: IdToken, RefreshToken: RefreshToken }
  const { register, errors } = useForm({
    mode: 'onChange',
  })

  const getDetails = () => {
    const payload = {
      tradeQuoteNumber: state?.SearchId?.tradeupid,
    }
    actions.getDetails(payload)
  }
  const errorLength = Object.keys(errors).length

  const isFormValid = !!(!tradeupid || errorLength > 0)

  return (
    <div className="flex  justify-between items-center ">
      <div className=" large-desktop:text-4xl text-30  p-1 font-Samsung-Sharp-Sans-Bold">Trade Details</div>
      <div className="flex flex-row h-36 large-desktop:h-h-50 large-desktop:mt-42 mt-15">
        <LabeledInputField
          type="text"
          name="tradeupid"
          label="Trade Id"
          placeholder="Samsung Trade-up ID"
          className="shadow border flex rounded max-w-w-tradeid py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-20"
          register={register}
          errors={errors}
          onChange={(val) => {
            if (val === '' || (val.match(/^[a-z0-9]+$/i) && val.length <= 8)) {
              actions.setTradeId(val)
            }
          }}
          schema="searchid"
          hideLabel
          value={tradeupid}
        />
        <button
          className={`${
            isFormValid ? 'disabled-btn' : ''
          } items-center h-36 large-desktop:h-h-50 hover:bg-blue-700 large-desktop:w-170 w-140 focus:shadow-outline focus:outline-none text-white large-desktop:text-font-20 text-font-15`}
          type="submit"
          disabled={isFormValid}
          onClick={() => getDetails(state)}
        >
          SEARCH
        </button>
      </div>
    </div>
  )
}
export default Search
