/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Helmet } from "react-helmet"
import Header from './components/confirmorder/header'
import { MemoryRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import './style.css'
import Login from './components/login'
import ConfirmOrder from './components/confirmorder'
import ChangePass from './components/login/changepass'
import Logistics from './components/logistics/logistics'
import { store } from './store'
import Loader from './loader'
import { useActions } from '../src/actions'
import ForgotPassword from './components/login/forgotpass'
import Reporting from './components/reports/reports'
import Footer from '../src/components/confirmorder/footer'
import Exceptions from './components/exception/search-exception'
// import LowValueDevice from './components/lowvaluedevices/lowvaluedevices'
import TagManager from 'react-gtm-module'
import TradeDetails from './components/TradeDetails/TradeDetails'
import { Config } from "./config";


const GTMid = process.env.GTMid
const tagManagerArgs = {
  gtmId: GTMid,
}

const App = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const changeHeader = state && state.loginsuccess
  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    actions.InitializeApplication()
  }, [true])

  const { isLoading } = state
  return (
    <>
      <div>
        <Router>
        <Helmet>
          <meta
            name="robots"
            content={Config.APP.isProd ? "index,follow" : "noindex, nofollow"}
          />
          {!Config.APP.isProd && <meta name="googlebot" content="none" />}
        </Helmet>
          {isLoading && <Loader />}
          {changeHeader && <Header />}
          <Switch>
            <Route path="/confirmorder">
              <ConfirmOrder />
            </Route>
            <Route path="/logistics">
              <Logistics />
            </Route>
            <Route path="/changepass">
              <ChangePass />
            </Route>{' '}
            <Route path="/forgotpass">
              <ForgotPassword />
            </Route>
            <Route path="/reports">
              <Reporting />
            </Route>
            <Route path="/exception">
              <Exceptions />
            </Route>
            <Route path="/tradeDetails">
              <TradeDetails />
            </Route>
            {/* <Route path="/lowvaluedevices">
              <LowValueDevice />
            </Route> */}
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    </>
  )
}
export default App
