import axios from 'axios'
import types from './actionTypes'

import { getHmacTokenAndTimestamp } from './helper/hmacHelper'

const hmacResponse = getHmacTokenAndTimestamp()

const API = {}
let headers = {
  Accept: 'application/json',
  'Asurion-Client': 'SAMSUNG',
  'Asurion-channel': 'SitePortal',
  'Asurion-enduser': '',
  'Asurion-lineofbusiness': 'MOBILITY',
  'Asurion-region': 'APAC-SEA',
  'Content-Type': 'application/json',
  'x-api-key': process.env.API_KEY,
}

API[types.INITIALIZE_REQUEST] = () =>
  axios
    .get('/api/store/v3/masterdata', {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.SET_COGNITO_HEADER] = (data) => {
  headers = Object.assign({}, headers, {
    'x-asurion-cognito': data && data.IdToken,
    'Asurion-enduser': data && data.StoreAgentName,
  })
}

API[types.GET_TRADE_INFO_REQUEST] = (tradeid, tokenHeders) => {
  headers = Object.assign({}, headers, {
    //AccessToken: tokenHeders && tokenHeders.AccessToken,
    'x-asurion-cognito': tokenHeders && tokenHeders.IdToken,
    //RefreshToken: tokenHeders && tokenHeders.RefreshToken
  })
  return axios
    .get(`/api/store/v3/findtradeinfo/F50D60E6F9D611E8A8F20A86007B17AC/${tradeid}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
}
API[types.GET_TRADE_DETAILS] = (data) => {
  return axios
    .post('/api/store/v3/trade/details', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
}

API[types.UPDATE_TRADE_INFO_REQUEST] = (data) =>
  axios
    .put('/api/store/v3/submittradeinfo', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_FMIP_STATUS_REQUEST] = (tradeid) => {
  return axios
    .get(`/api/store/v3/imeiverifications/F50D60E6F9D611E8A8F20A86007B17AC/${tradeid}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => {
      return res
    })
}
API[types.CREATE_QUOTE_LVD_REQUEST] = (data) => {
  return axios
    .post(`/api/store/v3/quotes/`, data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => {
      return res
    })
}
API[types.GET_AMTA_CHECK_LVD_REQUEST] = (imei) =>
  axios
    .get(`/api/store/v3/imeiverifications/${imei}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_CITY_STATE_REQUEST] = (zipcode) =>
  axios
    .get(`/api/store/v3/citystates/${zipcode}`, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CHANGE_PASSWORD_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/changetemppassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.LOGIN_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/login', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
API[types.GET_LOGISTICES_REQUEST] = (data) =>
  axios
    .post('api/store/v3/getdevicelist', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CREATE_BATCH_REQUEST] = (data) =>
  axios
    .post('api/store/v3/createbatch', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.GET_LOGISTICES_BATCH_REQUEST] = (data) =>
  axios
    .post('api/store/v3/getbatchdetails', data, {
      headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.FORGORT_PASSWORD_REQUEST] = (data) =>
  axios
    .post('/api/store/v3/forgotpassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)
API[types.CONFIRM_PASSWORD_REQUEST] = (data) =>
  axios
    .post('api/store/v3/confirmpassword', data, {
      headers: { ...headers, 'x-ct-authorization': hmacResponse.hmacToken, 'x-ct-timestamp': hmacResponse.hmacTimestamp, 'Asurion-Application': process.env.REACT_APP_TYPE },
    })
    .then((res) => res)

API[types.CREATE_PARCEL_REQUEST] = (data) => axios.post('api/parcel/v3/create', data, { headers }).then((res) => res)

API[types.GET_FILE_REQUEST] = (data) => axios.get('api/store/v3/tradeincarecreditreport', { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)

API[types.DOWNLOAD_FILE_REQUEST] = (data) => axios.get(`api/store/v3/filedownload/${data}`, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)

API[types.CREATE_PARCEL_ONSUBMIT_REQUEST] = (data) => axios.post('api/parcel/v3/create', data, { headers }).then((res) => res)

API[types.CREATE_BATCH_ONSUBMIT] = (data) => axios.post('api/store/v3/createbatch', data, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)

API[types.GET_REPORTING_DETAILS_REQUEST] = (data) =>
  axios.post('api/store/v3/getreportsummary', data, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)

API[types.SEND_EMAIL_LABEL_REQUEST] = (data) => axios.get(`api/store/v3/sendemail/${data}`, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)

API[types.GET_EXCEPTION_DETAILS_REQUEST] = (data) => axios.get(`api/store/v3/getexceptiondetails/${data}`, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } })

API[types.GET_EXCEPTION_DETAILS_REQUEST] = (data) =>
  axios.get(
    `api/store/v3/getescalations?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&tradeId=${data.tradeId}&startDate=${data.startDate}&endDate=${data.endDate}&escalationCode=${data.escalationCode}&agentId=${data.agentId}`,
    { headers }
  )

API[types.GET_DAX_IMAGE_REQUEST] = (data) => axios.get(`api/store/v3/getdaximageurls?redemptionOrderId=${data}`, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } })

API[types.GET_EMAIL_AUDIT_REPORT_REQUEST] = (data) =>
  axios.post('api/store/v3/getemailauditreport', data, { headers: { ...headers, 'Asurion-Application': process.env.REACT_APP_TYPE } }).then((res) => res)
export default API
