/* eslint-disable import/prefer-default-export */
import actionTypes from './actionTypes'

export const useActions = (state, dispatch) => ({
  InitializeApplication: () => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.INITIALIZE_REQUEST,
    })
  },
  setEmail: (data) => {
    dispatch({
      type: actionTypes.SET_EMAIL,
      data,
    })
  },
  setPassword: (data) => {
    dispatch({
      type: actionTypes.SET_PASSWORD,
      data,
    })
  },
  setCaptcha: (data) => {
    dispatch({ type: actionTypes.SET_CAPTCHA, payload: data })
  },
  expireCaptcha: (data) => {
    dispatch({ type: actionTypes.EXPIRE_CAPTCHA, payload: data })
  },
  setLocation: (data) => {
    dispatch({
      type: actionTypes.SET_LOCATION,
      data,
    })
  },
  login: (data) => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.LOGIN_REQUEST,
      data,
    })
  },
  setTradeId: (data) => {
    dispatch({
      type: actionTypes.SET_TRADEUPID,
      data,
    })
  },
  getTradeInfo: (data, tokenHeders) => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.GET_TRADE_INFO_REQUEST,
      data,
      tokenHeders,
    })
  },

  getDetails: (data) => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.GET_TRADE_DETAILS,
      data,
    })
  },
  setEdit: (data) => {
    dispatch({
      type: actionTypes.SET_EDIT,
      data,
    })
  },
  setFirstName: (data) => {
    dispatch({
      type: actionTypes.SET_FIRSTNAME,
      data,
    })
  },
  setLastName: (data) => {
    dispatch({
      type: actionTypes.SET_LASTNAME,
      data,
    })
  },
  setMobileNo: (data) => {
    dispatch({
      type: actionTypes.SET_MOBILENO,
      data,
    })
  },
  setAddLine1: (data) => {
    dispatch({
      type: actionTypes.SET_ADDLINE1,
      data,
    })
  },
  setAddLine2: (data) => {
    dispatch({
      type: actionTypes.SET_ADDLINE2,
      data,
    })
  },
  setAddLine3: (data) => {
    dispatch({
      type: actionTypes.SET_ADDLINE3,
      data,
    })
  },
  setCity: (data) => {
    dispatch({
      type: actionTypes.SET_CITY,
      data,
    })
  },
  setState: (data) => {
    dispatch({
      type: actionTypes.SET_STATE,
      data,
    })
  },
  getCityStateByZipcode: (data) => {
    dispatch({
      type: actionTypes.SET_ZIPCODE,
      data,
    })
    if (data.length === 4) {
      dispatch({
        type: actionTypes.SET_LOADING_INDICATOR,
      })
      dispatch({
        type: actionTypes.GET_CITY_STATE_REQUEST,
        data,
      })
    }
  },
  setCountry: (data) => {
    dispatch({
      type: actionTypes.SET_COUNTRY,
      data,
    })
  },
  setPrepopulatedData: (data) => {
    dispatch({
      type: actionTypes.SET_PREPOPULATED_DATA,
      data,
    })
  },
  setDevicePopularName: (data) => {
    dispatch({
      type: actionTypes.SET_DEVICE_POPULAR_NAME,
      data,
    })
  },
  setDeviceImeiCheck: (data) => {
    dispatch({
      type: actionTypes.SET_DEVICE_IMEI_CHECK,
      data,
    })
  },
  setDeviceModelCheck: (data) => {
    dispatch({
      type: actionTypes.SET_DEVICE_MODEL_CHECK,
      data,
    })
  },
  setCustomerInfoCheck: (data) => {
    dispatch({
      type: actionTypes.SET_CUSTOMER_INFO_CHECK,
      data,
    })
  },
  setPhysicalResultsCheck: (data) => {
    dispatch({
      type: actionTypes.SET_PHYSICAL_RESULTS_CHECK,
      data,
    })
  },
  setOpenPopUp: (data) => {
    dispatch({
      type: actionTypes.SET_OPEN_POPUP,
      data,
    })
  },
  updateTradeInfo: (data) => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.UPDATE_TRADE_INFO_REQUEST,
      data,
    })
  },
  setClosePopUp: (data) => {
    dispatch({
      type: actionTypes.CLOSE_BAR_BARCODE,
      data,
    })
  },
  setCurrentPassword: (data) => {
    dispatch({
      type: actionTypes.SET_CURRENT_PASSWORD,
      data,
    })
  },
  setNewPassword: (data) => {
    dispatch({
      type: actionTypes.SET_NEW_PASSWORD,
      data,
    })
  },
  setConfirmNewPassword: (data) => {
    dispatch({
      type: actionTypes.SET_CONFIRM_NEW_PASSWORD,
      data,
    })
  },
  changePassword: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({
      type: actionTypes.CHANGE_PASSWORD_REQUEST,
      data,
    })
  },
  setInvalidPassword: () => {
    dispatch({
      type: actionTypes.SET_INVALID_PASSWORD,
    })
  },

  getLogistics: (data) => {
    dispatch({
      type: actionTypes.SET_LOADING_INDICATOR,
    })
    dispatch({
      type: actionTypes.GET_LOGISTICES_REQUEST,
      data,
    })
  },

  setLogisticsData: (id) => {
    dispatch({
      type: actionTypes.SET_LOGISTICS_DATA,
    })
  },
  setLogisticsDataAll: (id) => {
    dispatch({
      type: actionTypes.SET_LOGISTICS_DATA_ALL,
    })
  },

  createBatch: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CREATE_BATCH_REQUEST, data })
  },

  setOrderNumber: (data) => {
    dispatch({
      type: actionTypes.SET_ORDER_NUMBER,
      data,
    })
  },

  setStartDate: (data) => {
    dispatch({
      type: actionTypes.SET_START_DATE,
      data,
    })
  },

  setEndDate: (data) => {
    dispatch({
      type: actionTypes.SET_END_DATE,
      data,
    })
  },

  setMake: (data) => {
    dispatch({
      type: actionTypes.SET_MAKE,
      data,
    })
  },

  setModelVarriant: (data) => {
    dispatch({
      type: actionTypes.SET_MODEL_VARRIANT,
      data,
    })
  },

  setCondition: (data) => {
    dispatch({
      type: actionTypes.LOGISTICS_LBL_CONDITION,
      data,
    })
  },

  setSelectedPage: (data) => {
    dispatch({ type: actionTypes.SET_SELECTED_PAGE, data })
  },

  setOrderDetailsTab: (data) => {
    dispatch({ type: actionTypes.SET_ORDER_DETAILS_TAB, data })
  },

  createParcelApi: (data, GetBatchDetailsRequest) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CREATE_PARCEL_REQUEST, data, GetBatchDetailsRequest })
  },

  setBatchNumber: (data) => {
    dispatch({ type: actionTypes.SET_BATCH_NUMBER, data })
  },

  setBatchConsignmentNumber: (data) => {
    dispatch({ type: actionTypes.SET_BATCH_CONSIGNMENT_NUMBER, data })
  },

  setLogisticsTab: (data) => {
    dispatch({ type: actionTypes.SET_LOGISTICS_TAB, data })
  },
  setOrderTab: (data) => {
    dispatch({ type: actionTypes.SET_ORDER_TAB, data })
  },
  setLowValueDeviceTab: (data) => {
    dispatch({ type: actionTypes.SET_LVD_TAB, data })
  },
  setReportingTab: (data) => {
    dispatch({ type: actionTypes.SET_REPORTING_TAB, data })
  },
  setReportDetailsTab: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_DETAILS_TAB, payload: data })
  },
  setReportCareCreditTab: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_CARE_CREDIT_TAB, payload: data })
  },
  setReportSummaryTab: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_SUMMARY_TAB, payload: data })
  },
  getReportingDetails: (data, Exportflag) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_REPORTING_DETAILS_REQUEST, data, Exportflag })
  },

  setLogisticsBatchData: (data) => {
    dispatch({
      type: actionTypes.SET_LOGISTICS_BATCH_DATA,
      data,
    })
  },

  getLogisticsBatchData: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_LOGISTICES_BATCH_REQUEST, data })
  },
  setBatchSelectedPage: (data) => {
    dispatch({ type: actionTypes.SET_BATCH_SELECTED_PAGE, data })
  },

  setBatchStartDate: (data) => {
    dispatch({ type: actionTypes.SET_BATCH_START_DATE, payload: data })
  },

  setTradeIDNumber: (data) => {
    dispatch({ type: actionTypes.SET_TRADE_ID_NUMBER, payload: data })
  },
  navigateToForgotPassword: () => {
    dispatch({ type: actionTypes.NAVIGATE_TO_FORGOT_PASSWORD })
  },

  setEmailId: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_ID, payload: data })
  },
  forgotPasswordRequest: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.FORGORT_PASSWORD_REQUEST, data })
  },
  setVerificationCode: (data) => {
    dispatch({ type: actionTypes.SET_VERIFICATION_CODE, payload: data })
  },
  confirmPassword: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CONFIRM_PASSWORD_REQUEST, data })
  },
  setRecurring: (data) => {
    dispatch({ type: actionTypes.SET_RECURRING, payload: data })
  },
  downloadReport: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.DOWNLOAD_FILE_REQUEST, payload: data })
  },
  getReports: () => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_FILE_REQUEST })
  },

  createBatchOnsubmit: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CREATE_BATCH_ONSUBMIT, payload: data })
  },
  createParcelOnSubmit: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CREATE_PARCEL_ONSUBMIT_REQUEST, payload: data })
  },

  getFMIPAMTARequest: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_FMIP_STATUS_REQUEST, data: data })
  },
  setMasterViewData: (data) => {
    dispatch({ type: actionTypes.SET_MASTER_VIEW_DATA, payload: data })
  },
  setReportTradeIDNumber: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_TRADE_ID_NUMBER, payload: data })
  },
  setReportStartDate: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_START_DATE, payload: data })
  },
  setReportEndDate: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_END_DATE, payload: data })
  },
  setReportState: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_STATE, payload: data })
  },
  setReportStatus: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_STATUS, payload: data })
  },
  setReportStoreName: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_STORE_NAME, payload: data })
  },
  setReportSelectedPage: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_SELECTED_PAGE, payload: data })
  },
  setOsotReportData: (data) => {
    dispatch({ type: actionTypes.SET_OSOT_REPORT_DATA, payload: data })
  },
  setShowReportingDetails: (data) => {
    dispatch({ type: actionTypes.SET_SHOW_OSOT_REPORT_DATA, payload: data })
  },
  setSendLabelCheck: (data) => {
    dispatch({ type: actionTypes.SET_SEND_LABEL_CHECK, payload: data })
  },
  sendEmailLabel: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.SEND_EMAIL_LABEL_REQUEST, payload: data })
  },
  setExceptionTab: (data) => {
    dispatch({ type: actionTypes.SET_EXCEPTION_TAB, payload: data })
  },
  getExceptionDetails: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_EXCEPTION_DETAILS_REQUEST, data: data.GetExceptionRequest })
  },
  setEscalationCode: (data) => {
    dispatch({ type: actionTypes.SET_ESCALATION_CODE, payload: data })
  },
  setExceptionTradeId: (data) => {
    dispatch({ type: actionTypes.SET_EXCEPTION_TRADEID, payload: data })
  },
  setExceptionStartDate: (data) => {
    dispatch({ type: actionTypes.SET_EXCEPTION_STARTDATE, payload: data })
  },
  setExceptionEndDate: (data) => {
    dispatch({ type: actionTypes.SET_EXCEPTION_ENDDATE, payload: data })
  },
  getDAXImage: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_DAX_IMAGE_REQUEST, data })
  },
  updateAction: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.UDPATE_EXCEPTION_ACTION_REQUEST, data: data.requestAction })
  },
  /** email audit report */
  setReportEmailAuditTab: (data) => {
    dispatch({ type: actionTypes.SET_REPORT_EMAIL_AUDIT_TAB, payload: data })
  },
  setEmailAuditStatus: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_STATUS, payload: data })
  },
  setEmailAuditTemplate: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_TEMPLATE, payload: data })
  },
  setEmailAuditTradeId: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_TRADE_ID, payload: data })
  },
  setEmailAuditStartDate: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_START_DATE, payload: data })
  },
  setEmailAuditEndDate: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_END_DATE, payload: data })
  },
  getEmailAuditReport: (data, EmailAuditExportFlag) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_EMAIL_AUDIT_REPORT_REQUEST, data, EmailAuditExportFlag })
  },
  setEmailReportSelectedPage: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_REPORT_SELECTED_PAGE, payload: data })
  },
  setEmailAuditEmailSearch: (data) => {
    dispatch({ type: actionTypes.SET_EMAIL_AUDIT_EMAIL_SEARCH, payload: data })
  },
  // Low Value Device -- start
  setLVDDeviceType: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_TYPE, payload: data })
  },
  setLVDDeviceOffered: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_OFFERED, payload: data })
  },
  setLVDFirstName: (data) => {
    dispatch({ type: actionTypes.SET_LVD_FIRST_NAME, payload: data })
  },
  setLVDLastName: (data) => {
    dispatch({ type: actionTypes.SET_LVD_LAST_NAME, payload: data })
  },
  setLVDMobileNo: (data) => {
    dispatch({ type: actionTypes.SET_LVD_MOBILE_NO, payload: data })
  },
  setLVDEmailAddress: (data) => {
    dispatch({ type: actionTypes.SET_LVD_EMAIL_ADDRESS, payload: data })
  },
  setLVDAddressLine1: (data) => {
    dispatch({ type: actionTypes.SET_LVD_ADDRESS_LINE1, payload: data })
  },
  setLVDAddressLine2: (data) => {
    dispatch({ type: actionTypes.SET_LVD_ADDRESS_LINE2, payload: data })
  },
  setLVDAddressLine3: (data) => {
    dispatch({ type: actionTypes.SET_LVD_ADDRESS_LINE3, payload: data })
  },
  setLVDPostalCode: (data) => {
    dispatch({ type: actionTypes.SET_LVD_POSTAL_CODE, payload: data })
  },
  setLVDCity: (data) => {
    dispatch({ type: actionTypes.SET_LVD_CITY, payload: data })
  },
  setLVDState: (data) => {
    dispatch({ type: actionTypes.SET_LVD_STATE, payload: data })
  },
  setLVDCountry: (data) => {
    dispatch({ type: actionTypes.SET_LVD_COUNTRY, payload: data })
  },
  setLVDDeviceActiveAccountLock: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_ACTIVE_ACCOUNT_LOCK, payload: data })
  },
  setLVDDevicePinLock: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_PIN_LOCK, payload: data })
  },
  setLVDDeviceReportLostOrStolen: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_REPORT_LOST_OR_STOLEN, payload: data })
  },
  setLVDDevicePoweredOff: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_POWERED_OFF, payload: data })
  },
  setLVDDeviceFactoryReset: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_FACTORY_RESET, payload: data })
  },
  setLVDCustomerInfoCheck: (data) => {
    dispatch({
      type: actionTypes.SET_LVD_CUSTOMER_INFO_CHECK,
      data,
    })
  },
  createQuoteLVDRequest: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.CREATE_QUOTE_LVD_REQUEST, data: data })
  },
  getAMTACheckLVDRequest: (data) => {
    dispatch({ type: actionTypes.SET_LOADING_INDICATOR })
    dispatch({ type: actionTypes.GET_AMTA_CHECK_LVD_REQUEST, data: data })
  },
  setLVDDeviceIMEI: (data) => {
    dispatch({ type: actionTypes.SET_LVD_DEVICE_IMEI, payload: data })
  },
  setLVDQuestions: (data) => {
    dispatch({ type: actionTypes.SET_LVD_QUESTIONS, payload: data })
  },
  AMTACall: (data) => {
    dispatch({ type: actionTypes.SET_LVD_AMTA_CALL, payload: data })
  },
  setOpenLVDPopUp: (data) => {
    dispatch({
      type: actionTypes.SET_OPEN_LVD_POPUP,
      payload: data,
    })
  },
  setCloseLVDPopUp: (data) => {
    dispatch({
      type: actionTypes.CLOSE_LVD_BAR_BARCODE,
      data,
    })
  },
  setTradeDetailsTab: (data) => {
    dispatch({ type: actionTypes.SET_TRADEDETAILS_TAB, data })
  },
  // Low Value Device -- end
})
