import React from 'react'
import footerLogo from '../../images/img-asurion.png'

const Footer = () => (
  <div className="footer h-60 bg-gray-1">
    <div>
        <img className="ml-50" src={footerLogo} alt="asurion" />
      {/* <img className="large-desktop:w-auto w-58 large-desktop:h-auto h-15 inline-block ml-24 large-desktop:mt-22 large-desktop:mb-20 mb-21 mt-4 " src={footerLogo} alt="asurion" /> */}
      {/* <span className="text-sm float-right large-desktop:mt-22 large-desktop:mb-21 mr-24 mb-3 mt-4">© 2020 All rights reserved</span> */}
    </div>
  </div>
)
export default Footer
