import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField } from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginate from '../pagination/index'
const moment = require('moment')

const INVOKE_ONCE = true;

const exceptionDetails = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })
    let { selectedPage, exceptionResponse, roles, exceptionList, exceptionSuccess, daxImagesResponse, requestedRedemptionOrder, masterdata} = state
    // const ExceptionRoles = roles && roles.filter(r => r.menu === 'Exceptions' && r.section === "Tabs") &
    // roles && roles.filter(r => r.menu === 'Exceptions' && r.section === "Tabs")[0];
    const TotalRecords = exceptionResponse && exceptionResponse.TotalRecords
    // const TotalRecords = 100
    const pageLimit = 10
    let pageCount = Math.ceil(TotalRecords / pageLimit)
    if (exceptionList && exceptionList.length > 0 && daxImagesResponse && daxImagesResponse.length > 0) {
        exceptionList.map((data) => {
            if (data.RedemptionOrderId === requestedRedemptionOrder) {
                data.daxImages = daxImagesResponse.map((image) => {
                    return image
                })
                // actions.setExceptionAction(exceptionList)
            }
        })
    }

    const getDaxImages = (RedemptionOrderId) => {
        actions.getDAXImage(RedemptionOrderId)
    }

    const setSelectedPage = (index) => {
        actions.setSelectedPage(index)
    }

    useEffect(() => {
        // let GetExceptionRequest = {
        //     pageNumber : "1",
        //     pageSize : "10",
        //     tradeId : "",
        //     startDate : "",
        //     endDate : "",
        //     escalationCode : ""
        // }
        // actions.getExceptionDetails({ GetExceptionRequest })
    }, [INVOKE_ONCE])

    const setExceptionAction = (e, tradeId) => {
        exceptionList && exceptionList.map((data) => {
            if (data.TradeId === tradeId) {
                data.actionException = e
            }
            return data
        })
        actions.setExceptionAction(exceptionList)
        actions.openActionPopUp(tradeId)
    }

    const options = []
    const actionsExcptn = masterdata && masterdata.TradeEscalationResolution
    actionsExcptn &&
        actionsExcptn.map((data) => {
            options.push({
                label: data.EscalationResolutionName,
                value: data.EscalationResolution
            })
        })

    const baseURLReturn = 'https://msto.startrack.com.au/track-trace/?id='
    const starTrackURL = 'https://msto.startrack.com.au/track-trace/?id='
    const auspostBaseURL = 'https://auspost.com.au/mypost/track/#/details/'

    return (
        <div>
            <table className="mt-15 text-sm w-full h-50 border border-gray-2">
                <thead className=" text-transform: uppercase border-white bg-grey-4 text-white h-50 text-left font-VodafoneRgBd border ">
                    <tr>
                        <th className="h-34 pl-8 text-sm leading-17"> <span className="columnTitle">TRADE ID</span></th>
                        <th className="leading-17 pl-13 h-34 ">DATE/TIME</th>
                        <th className="pl-13 leading-17">DEVICE IMEI </th>
                        <th className="pl-13">EXCEPTION STATUS</th>
                        <th className="pl-13 ">APP CONDITION</th>
                        <th className="pl-13"> GRADED CONDITION</th>
                        <th className="pl-13"><span className="columnTitle"> RAISED LOCATION</span></th>
                        <th className="pl-13"><span className="columnTitle">PHOTO</span></th>
                        <th className="pl-13"><span className="columnTitle">SHIPPING TO ASURION</span></th>
                        <th className="pl-13"><span className="columnTitle">SHIPPING FOR RETURN</span></th>
                    </tr>
                </thead>
                {exceptionList && exceptionList.length > 0 ? (
                    exceptionList.map((table_Detail, index) => (
                      <tbody>
                        <tr className="text-sm text-#000000 border border-gray-2">
                            <td className="pl-8 pr-2">{table_Detail.TradeId}</td>
                            <td className="pl-13">
                            {table_Detail.ExceptionCreatedDate ? <div>
                            {moment(table_Detail.ExceptionCreatedDate).format('DD/MM/YYYY hh:mm:ss').split(' ')[0]}&nbsp;
                            {moment(table_Detail.ExceptionCreatedDate).format('DD/MM/YYYY hh:mm:ss').split(' ')[1]} </div> : '-'}
                            </td>
                            <td className="pl-13"><span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.PrimaryIMEI}<br />{table_Detail.SecondaryIMEI ? <div>
                                <span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondaryIMEI} </div> : ''}</td>
                            <td className="h-34 pl-13 text-sm leading-17 text-left">{table_Detail.CurrentStatus}</td>
                           <td className="pl-13">{table_Detail.AppCondition === 'PASSED' ? <div>GWO</div> : <div>NGWO</div>}</td>
                            <td className="pl-13">{table_Detail.GradedCondition}</td>
                            <td className="pl-13">{table_Detail.RaisedCondition}</td>
                            <td className="pl-13 text-decoration: underline text-green-1 text-17 text-left font-VodafoneRg">
                                    {table_Detail.daxImages ?
                                        table_Detail.daxImages.map((i) => {
                                            return <span><a rel="noopener noreferrer" href={i.Url} target='_blank'>{i.FileName}</a><br /></span>
                                        }) : <span className="cursor-pointer" onClick={() => getDaxImages(table_Detail.RedemptionOrderId)}>Click to View</span>}</td>

                            <td className="pl-13 text-decoration: underline text-green-1 text-17 text-left font-VodafoneRg">
                                {table_Detail.TrackingNumber ? <a rel="noopener noreferrer" href={table_Detail.RaisedCondition && table_Detail.RaisedCondition.toUpperCase() === 'ONLINE' ? auspostBaseURL + table_Detail.TrackingNumber : starTrackURL + table_Detail.TrackingNumber} target='_blank'>{table_Detail.TrackingNumber}</a> : null}</td>

                            <td className="pl-13 text-decoration: underline text-green-1 text-17 text-left font-VodafoneRg">
                                {table_Detail.DeviceReturnTrackingNumber ? <a rel="noopener noreferrer" href={baseURLReturn + table_Detail.DeviceReturnTrackingNumber} target='_blank'>{table_Detail.DeviceReturnTrackingNumber}</a> : null}</td>
                       </tr>
                      </tbody>
                    ))) : <tbody><tr className="text-sm text-#000000 border border-gray-2">
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td className=" font-bold text-center">No Records Found</td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       </tr></tbody>}
                </table> 
            <div className="flex justify-between mt-17">
                <div>Page {selectedPage + 1} of {pageCount}</div>
                <Paginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={<a>...</a>}
                    breakClassName={'break-me'}
                    pageCount= {pageCount}
                    selectedPage={selectedPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={(page) => setSelectedPage(page.selected)}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div> 
            
                </div>

    )
}

export default exceptionDetails
