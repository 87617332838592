import React, { useContext, useEffect, useState } from 'react'

import { MemoryRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom'
import { useActions } from '../../actions'
import { store } from '../../store'
import ReportSummary from './reportsummary'
import ReportingCareCreditReport from './carecreditreports'
import ReportDetails from './reportdetails'
import ReportEmailAudit from './emailauditreport'

const INVOKE_ONCE = true

const Reporting = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { Login, roles, ReportingDetailsInputFields } = state
  const { StoreAgentRole } = Login
  const ReportingRoles = roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs') && roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs')[0]
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase()
  const EmailAuditRole = roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs') && roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs')[0]
  const loginId = Login && Login.email && Login.email.toLowerCase()
  const history = useHistory()

  const careCreditReportTab = () => {
    actions.setReportDetailsTab(false)
    actions.setReportCareCreditTab(true)
    actions.setReportSummaryTab(false)
    actions.setReportEmailAuditTab(false)
  }

  const reportSummaryTab = () => {
    actions.setReportDetailsTab(false)
    actions.setReportCareCreditTab(false)
    actions.setReportSummaryTab(true)
    actions.setReportEmailAuditTab(false)
  }

  const reportEmailAuditTab = () => {
    actions.setReportDetailsTab(false)
    actions.setReportCareCreditTab(false)
    actions.setReportSummaryTab(false)
    actions.setReportEmailAuditTab(true)
    actions.setEmailReportSelectedPage(0)
  }

  const reportDetailsTab = () => {
    actions.setReportDetailsTab(true)
    actions.setReportCareCreditTab(false)
    actions.setReportSummaryTab(false)
    actions.setReportEmailAuditTab(false)

    const GetReportSummaryRequest = {
      // PartnerStoreId: (state && state.Login && state.Login.StoreAgentRole && state.Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
      PartnerStoreId: '',
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,
      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.setReportSelectedPage(0)
    actions.getReportingDetails({ GetReportSummaryRequest })
  }

  const { reportingDetailsTab, reportingCareCreditTab, reportingSummaryTab, reportingEmailAuditTab } = state

  return (
    <Router>
      <div className="ml-100 mr-50 min-h-718 mb-100 SamsungOne-400">
        <div className="flex items-center pt-30">
          <h2 className="text-4xl leading-37 p-1 font-Samsung-Sharp-Sans-Bold">Reporting</h2>

          {
            // ReportingRoles['ReportSummary'][agentRole] === 'SHOW' && (
            <div className={`${reportingSummaryTab ? 'text-blue-1' : 'text-grey-3'} text-17 text-center ml-8 pl-4 py-2 m-8 font-Samsung-Sharp-Sans-Bold`}>
              <Link
                to="/reportsummary"
                onClick={() => {
                  reportSummaryTab()
                }}
                className="cursor-pointer"
              >
                Summary
              </Link>
            </div>
            // )
          }
          {
            // ReportingRoles['ReportDetails'][agentRole] === 'SHOW' && (
            <div className={`${reportingDetailsTab ? 'text-blue-1' : 'text-grey-3'} text-17 text-center ml-m5 py-2 m-8 font-Samsung-Sharp-Sans-Bold`}>
              <Link
                to="/reportdetails"
                onClick={() => {
                  reportDetailsTab()
                }}
                className="cursor-pointer"
              >
                Details
              </Link>
            </div>
            // )
          }
          {/* {//ReportingRoles['CareCreditReport'][agentRole] === 'SHOW' && (
            <div className={`${reportingCareCreditTab ? 'text-blue-1' : 'text-grey-3'} text-17 leading-22 text-center ml-m5 py-2 m-8 font-Samsung-Sharp-Sans-Bold`}>
              <Link
                to="/carecreditreport"
                onClick={() => {
                  careCreditReportTab()
                }}
                className="cursor-pointer"
              >
                Credit Report
              </Link>
            </div>
          //)
          } */}
          {EmailAuditRole.EmailAudit[loginId] === 'SHOW' && (
            <div className={`${reportingEmailAuditTab ? 'text-blue-1' : 'text-grey-3'} text-17 text-center ml-m5 py-2 m-8 font-Samsung-Sharp-Sans-Bold`}>
              <Link
                to="/emailauditreport"
                onClick={() => {
                  reportEmailAuditTab()
                }}
                className="cursor-pointer"
              >
                Email Audit
              </Link>
            </div>
          )}
        </div>
        <Switch>
          <Route path="/reportdetails">
            <ReportDetails />
          </Route>
          <Route path="/reportsummary">
            <ReportSummary />
          </Route>
          <Route path="/carecreditreport">
            <ReportingCareCreditReport />
          </Route>
          <Route path="/emailauditreport">
            <ReportEmailAudit />
          </Route>
          <Route exact path="/">
            <ReportSummary />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default Reporting
