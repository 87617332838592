import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useActions } from '../../actions'
import { store } from '../../store'
import downIcon from '../../images/ic-chevron_down_sm.png'
import upIcon from '../../images/ic-chevron_up_sm.png'

const moment = require('moment')
const invokeOnce = true;
const ReportSummary = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory();
  const {
    reportingEmailAuditTab, masterReportSummaryData, masterReportData, reportDetailsSuccess,
  } = state
  const ReportDetails = masterReportSummaryData;
  const GetReportSummaryRequest = {
    // PartnerStoreId: (Login && Login.StoreAgentRole && Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
    PartnerStoreId: '',
    TradeQuoteNbr: undefined,
    StartDate: undefined,
    EndDate: undefined,
    State: undefined,
    Status: undefined,
    StoreName: undefined,
    PageSize: 10, // sending just buz maindatory param
    PageNumber: 1,
  }
  useEffect(() => {
    actions.getReportingDetails({ GetReportSummaryRequest })
  }, [invokeOnce])

  useEffect(() => {
    if (reportingEmailAuditTab) {
      history.push('/emailauditreport')
    }
  }, [])

  const ConfirmedStatusData = ReportDetails && ReportDetails.filter((d) => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'CONFIRMED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')
  const BatchedStatusData = ReportDetails && ReportDetails.filter((d) => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'BATCHED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')

  const formattedData = function (filteredStatusData) {
    const UniqueStore = filteredStatusData
      && [...new Set(filteredStatusData.map((item) => item.StoreName))]
    const FilteredData = [];
    UniqueStore && UniqueStore.map((d) => {
      const StoreData = filteredStatusData && filteredStatusData.filter((c) => c.StoreName === d)
      let DaysPendingInTransit = 0;
      let DaysPendingInAssessment = 0;
      let OverallAging = 0;
      StoreData && StoreData.map((data) => {
        DaysPendingInTransit += data.DaysPendingInTransit;
        DaysPendingInAssessment += data.DaysPendingInAssessment;
        OverallAging += data.OverallAging;
      })
      FilteredData.push({
        StoreName: d,
        JobInTransit: StoreData.length,
        DaysPendingInTransit: Math.round((DaysPendingInTransit / StoreData.length)),
        DaysPendingInAssessment: Math.round((DaysPendingInAssessment / StoreData.length)),
        OverallAging: Math.round((OverallAging / StoreData.length)),
      })
    });
    return FilteredData;
  }

  const sum = function (key, array) {
    return array.reduce((a, b) => Number(a) + Number(b[key] || 0), 0);
  }

  const getMasterReportData = () => {
    const confirmFilteredData = formattedData(ConfirmedStatusData);
    const batchedFilteredData = formattedData(BatchedStatusData)
    const summaryData = [
      {
        OrderType: 'Unbatched Order',
        OrderDeatis: confirmFilteredData,
        JobInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? sum('JobInTransit', confirmFilteredData) : 0,
        DaysPendingInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', confirmFilteredData)) / confirmFilteredData.length) : 0,
        DaysPendingInAssessment: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', confirmFilteredData)) / confirmFilteredData.length) : 0,
        OverallAging: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('OverallAging', confirmFilteredData)) / confirmFilteredData.length) : 0,
        isSelectedMasterView: false,
      },
      {
        OrderType: 'Ready For Pick Up Order',
        OrderDeatis: batchedFilteredData,
        JobInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? sum('JobInTransit', batchedFilteredData) : 0,
        DaysPendingInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', batchedFilteredData)) / batchedFilteredData.length) : 0,
        DaysPendingInAssessment: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', batchedFilteredData)) / batchedFilteredData.length) : 0,
        OverallAging: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('OverallAging', batchedFilteredData)) / batchedFilteredData.length) : 0,
        isSelectedMasterView: false,
      },
    ];
    actions.setMasterViewData(summaryData);
  }

  const setSelectedMasterView = (OrderType) => {
    masterReportData && masterReportData.map((s, i) => {
      if (s.OrderType === OrderType) {
        s.isSelectedMasterView = !s.isSelectedMasterView;
      }
    })
    actions.setMasterViewData(masterReportData)
  }

  if (reportDetailsSuccess && ReportDetails && ReportDetails.length > 0 && masterReportData.length == 0) {
    getMasterReportData();
  }
  return (
    <div className="h-900">
      {ReportDetails && ReportDetails.length > 0
        && masterReportData && masterReportData.length > 0 && (
          <div>
            <table className="mt-5 text-sm font-Samsung-Sharp-Sans-Bold w-full">
              <thead className=" bg-grey-4 text-white h-60 text-left w-full">
                <tr className="w-full h-60">
                  <th className="w-250" />
                  <th className="w-250 h-34  SamsungOne-400 text-14 leading-16 text-center">
                    Jobs In Transit
                  </th>
                  <th className="w-250 h-34   SamsungOne-400 text-14 text-center leading-16">
                    Days Pending in Transit
                  </th>
                  <th className=" h-34 SamsungOne-400 text-14 text-center leading-16">
                    Days Pending Assessment
                  </th>
                  <th className=" h-34  SamsungOne-400 text-14  text-center leading-16">
                    Overall Aging
                  </th>
                  <th className=" h-34  SamsungOne-400 text-14 text-center leading-16" />
                </tr>
              </thead>
              {masterReportData
                && masterReportData.map((s, index) => (
                  <tbody key={index} className="border border-gray-100">
                    <tr className=" text-sm h-50 bg-gray-1 border border-gray-1 ">
                      <td className="w-250 inline-flex SamsungOne-400 font-bold text-14 leading-16 m-15">
                        <span className="mr-10 ">{s.OrderType}</span>
                        <img className="inline-flex" onClick={() => setSelectedMasterView(s.OrderType)} src={s.isSelectedMasterView ? upIcon : downIcon} />
                      </td>
                      <td className="w-250 h-10   text-sm font-bold text-center">{s.JobInTransit}</td>
                      <td className="w-250 h-10   text-sm font-bold text-center">{s.DaysPendingInTransit}</td>
                      <td className="w-250 h-10   text-sm font-bold text-center">{s.DaysPendingInAssessment}</td>
                      <td className="w-250 h-10   text-sm font-bold text-center">{s.OverallAging}</td>
                      <td className=" h-34 pl-67  SamsungOne-400 text-14 leading-16" />
                    </tr>
                    {s && s.isSelectedMasterView
                      && s.OrderDeatis.map((d, idx) => (
                        <tr key={idx} className="text-sm  bg-white h-50 border border-gray-1">
                          <td className="w-280 SamsungOne-400 text-14 leading-16 text-right font-bold">{d.StoreName}</td>
                          <td className="w-250 h-10   text-sm  text-center">{d.JobInTransit}</td>
                          <td className="w-250 h-10   text-sm  text-center">{d.DaysPendingInTransit}</td>
                          <td className="w-250 h-10   text-sm  text-center">{d.DaysPendingInAssessment}</td>
                          <td className="w-250 h-10   text-sm  text-center">{d.OverallAging}</td>
                          <td className=" h-30 " />
                        </tr>
                      ))}
                  </tbody>
                ))}
            </table>
          </div>

      )
        //  : (
        //   <div className="mt-15">No Records Found</div>
        // )
      }
    </div>
  )
}

export default ReportSummary
