
import React from 'react'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, SortSelection, DateInputField } from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginate from '../pagination'
const moment = require('moment')

const ReportEmailAudit = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { EmailAuditInputFields, getEmailAuditReportSuccess, emailReportSelectedPage, masterData } = state
  const { register, errors, setValue } = useForm({ mode: 'onChange' })
  let emailReportResponse =  getEmailAuditReportSuccess && getEmailAuditReportSuccess.GetEmailAuditReportResponse
  useEffect(() => {
    return () => {
      actions.setEmailAuditStatus(undefined)
      actions.setEmailAuditTemplate(undefined)
      actions.setEmailAuditTradeId(undefined)
      actions.setEmailAuditStartDate(undefined)
      actions.setEmailAuditEndDate(undefined)
      actions.setEmailAuditEmailSearch(undefined)
    }
  }, [])

  useEffect(() => {
    searchReport()
  }, [emailReportSelectedPage])

  const pageLimit = 10;
  let TotalRecords = emailReportResponse && emailReportResponse.TotalRecords;
  let pageCount = Math.ceil(TotalRecords / pageLimit)

  const resetFields = () => {
    actions.setEmailAuditStatus(undefined)
    actions.setEmailAuditTemplate(undefined)
    actions.setEmailAuditTradeId(undefined)
    actions.setEmailAuditStartDate(undefined)
    actions.setEmailAuditEndDate(undefined)
    actions.setEmailAuditEmailSearch(undefined)

    if (emailReportSelectedPage == 0) {
      let GetEmailAuditReportRequest = {
        PageSize: 10,
        PageNumber: 1,
      }
      actions.getEmailAuditReport({ GetEmailAuditReportRequest })
    }
    actions.setEmailReportSelectedPage(0)
    setValue('emailStatus', 'Email Status')
    setValue('TemplateName', 'Select Template Name')
    setValue('tradeID', '')
    setValue('emailSendDate', '')
    setValue('email', '')
  }

  let templates =
      masterData && masterData.TemplateNames.map((item) => ({
      key: item,
      value: item,
    }))
 let defaultTemplate = [{key: "Select Template Name", value: "Select Template Name"}, ...templates]
 let defaulStatus = [{key: "Email Status", value: "Email Status"},
                      {key: "New", value: "NEW"},
                      {key: "Sent", value: "SENT"},
                     {key: "Failed", value: "FAILED"}]
  const searchReport = (isSearch = false) => {
    if (isSearch) {
      actions.setEmailReportSelectedPage(0)
    }
    let GetEmailAuditReportRequest = {
        TradeQuoteNbr: EmailAuditInputFields && EmailAuditInputFields.TradeId,
        StartDate: EmailAuditInputFields && EmailAuditInputFields.StartDate,
        EndDate: EmailAuditInputFields && EmailAuditInputFields.EndDate,
        TemplateName: EmailAuditInputFields && EmailAuditInputFields.TemplateName,
        Status: EmailAuditInputFields && EmailAuditInputFields.Status,
        Email: EmailAuditInputFields && EmailAuditInputFields.searchEmail,
        PageSize: 10,
        PageNumber: isSearch ? 1 : emailReportSelectedPage + 1,
      }
      actions.getEmailAuditReport({ GetEmailAuditReportRequest })
  }

  const exportServiceCall = () => {
    let GetEmailAuditReportRequest = {
        TradeQuoteNbr: EmailAuditInputFields && EmailAuditInputFields.TradeId,
        StartDate: EmailAuditInputFields && EmailAuditInputFields.StartDate,
        EndDate: EmailAuditInputFields && EmailAuditInputFields.EndDate,
        TemplateName: EmailAuditInputFields && EmailAuditInputFields.TemplateName,
        Status: EmailAuditInputFields && EmailAuditInputFields.Status,
        Email: EmailAuditInputFields && EmailAuditInputFields.searchEmail,
        PageSize: TotalRecords,
        PageNumber: 1,
      }
      actions.getEmailAuditReport({ GetEmailAuditReportRequest }, true)
  }
  return (
    <div className="h-900">
        <div className="flex w-full items-center">
        <h2 className="text-17 font-Samsung-Sharp-Sans-Bold mt-31 ml-4 w-138">Search</h2>
      </div>
      <div className="flex align-items-center">
        <RenderSelectField
            name="emailStatus"
            label="Email Status"
            className="order-no-input report-search-font"
            register={register}
            //placeholder="Template Name"
            onChange={(e) => actions.setEmailAuditStatus(e)}
            options={defaulStatus}
            optionsKey="key"
            optionsValue="value"
            errors={errors}
            schema="reporting"
            value={EmailAuditInputFields ? EmailAuditInputFields.Status : ''}
            hideLabel
            removeDefaultOption={true}          />
        <RenderSelectField
            name="TemplateName"
            label="TemplateName"
            className="consignment-no-input report-search-font"
            register={register}
            //placeholder="Template Name"
            onChange={(e) => actions.setEmailAuditTemplate(e)}
            options={defaultTemplate}
            optionsKey="key"
            optionsValue="value"
            errors={errors}
            schema="reporting"
            value={EmailAuditInputFields ? EmailAuditInputFields.TemplateName : ''}
            hideLabel
            removeDefaultOption={true}          />
        <LabeledInputField
          className="order-no-input report-search-font"
          type="text"
          name="email"
          schema="reporting"
          placeholder="Email"
          onChange={actions.setEmailAuditEmailSearch}
          register={register}
          errors={errors}
          hideLabel
        />
        <LabeledInputField
          className="report-input report-search-font"
          type="text"
          name="tradeID"
          schema="reporting"
          placeholder="Trade ID"
          value={EmailAuditInputFields ? EmailAuditInputFields.TradeId : ''}
          onChange={actions.setEmailAuditTradeId}
          register={register}
          errors={errors}
          hideLabel
        />
        <DateInputField
          className="email-audit-date-input report-search-font"
          onChange={actions.setEmailAuditStartDate}
          placeholder="Start Date"
          value={EmailAuditInputFields && EmailAuditInputFields.StartDate ? EmailAuditInputFields.StartDate : ''}
          format="dd-MM-yyyy"
        />
        <DateInputField
          className="email-audit-date-input report-search-font"
          onChange={actions.setEmailAuditEndDate}
          placeholder="End Date"
          value={EmailAuditInputFields && EmailAuditInputFields.EndDate ? EmailAuditInputFields.EndDate : ''}
          format="dd-MM-yyyy"
        />
        <button className="purple-button w-80 h-8 report-search-font mr-5 pl-5 pr-5" onClick={() => searchReport(true)}>
          {/* resources.VODAFONE_SEARCH_BUTTON */}Search
        </button>

        <button className="black-button w-80 h-8 report-search-font ml-10" onClick={() => resetFields()}>
          {/* resources.VODAFONE_RESET_BUTTON */}Reset
        </button>
        <button className={'purple-button w-80 h-8 ml-auto text-xs'} onClick={() => (exportServiceCall())}>
            {'Export'}
          </button>
        </div>
        {
            TotalRecords && TotalRecords > 0 ?
            (
                <table className="mt-20 text-sm border-white w-full h-50 border border-gray-2">
                  <thead className=" text-transform: uppercase border-white bg-grey-4 text-white h-50 text-left font-Samsung-Sharp-Sans-Bold border border-gray-2 ">
                    <tr>
                      <th className="leading-17 pl-13 pr-13 w-80 h-34 pl-13" colSpan="2">
                        Message Subject
                    </th>
                      <th className="w-150 pl-13 " colSpan="3">
                        Reference Type
                    </th>
                      <th className="w-150" colSpan="4">
                        Reference Number
                    </th>
                      <th className=" w-220 pr-15" colSpan="5">
                        Sent To
                      </th>
                      <th className="w-250 pl-13 pr-16" colSpan="6">
                        Created Date
                    </th>
                    <th className=" w-120" colSpan="7">
                        Email Status
                    </th>
                    </tr>
                  </thead>
                  {emailReportResponse &&
                    emailReportResponse.ReportsData && emailReportResponse.ReportsData.map((table_Detail, index) => (
                      <tbody key={index}>
                        <tr className="text-left h-50 text-sm border border-gray-2 bg-white">
                          <td className="pl-13 pr-13" colSpan="4">
                            {table_Detail.TemplateName}
                          </td>
                          <td>
                            <td className="w-130 pl-13 h-50" colSpan="3">
                            {table_Detail.ReferenceType}
                            </td>
                          </td>
                          <td className="w-50 h-50" colSpan="4">
                              {table_Detail.ReferenceNbr}
                          </td>
                          <td className="h-50 w-50 pr-13" colSpan="5">
                            {table_Detail.SentTo}
                          </td>
                          <td className="w-20 h-50 pl-13" colSpan="6">
                            {table_Detail.CreatedDate && moment(table_Detail.CreatedDate).format('DD/MM/YYYY')}
                          </td>
                          <td className="h-50 w-120 pl-25" colSpan="7">
                            {table_Detail.EmailSentStatus}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              )
              : (
                <div className="mt-15">No Records Found</div>
              )
        }
        {TotalRecords > 0 && (
        <div className={`flex justify-between mt-17`}>
          <div>
            Page {emailReportSelectedPage + 1} of {pageCount}
          </div>
          <Paginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={<a>...</a>}
            breakClassName={'break-me'}
            pageCount={pageCount}
            selectedPage={emailReportSelectedPage}
            onPageChange={(page) => actions.setEmailReportSelectedPage(page.selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div>
  )
}

export default ReportEmailAudit
