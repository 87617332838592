export const Config = {
  APP: {
    applicationType: process.env.REACT_APP_TYPE,
    client: process.env.REACT_APP_CLIENT,
    isLocal: process.env.REACT_APP_IS_LOCAL === "true",
    isProd: process.env.REACT_APP_IS_PROD === "true"
  },
  SLS: {
    hmacKey: process.env.SECURITY_KEY,
  },
}
