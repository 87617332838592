/* eslint-disable arrow-body-style */
import React, { useContext, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useActions } from '../../actions'
import { store } from '../../store'
import LabeledInputField, { RenderSelectField } from '../../../utils/custom-fields'
import logo from '../../images/img-Samsung.png'
import mobileImage1 from '../../images/img-tradeup1.png'
import mobileImage from '../../images/img-tradeup.png'
// import footerLogo from '../../images/Combined Shape.png'
import ReCAPTCHA from 'react-google-recaptcha'

const Login = () => {
  const { state, dispatch } = useContext(store)
  const { selectedLocation, email, password, setCaptcha } = state.Login
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { isForgotPswdClicked } = state
  const captcha = useRef(null)
  let isFormValid = false

  if (state && state.Login && state.Login.Status === 'change_pass') {
    history.replace('/changepass')
  }

  if (state && state.Login && state.Login.Status === 'success') {
    history.replace('/confirmorder')
  }

  if (isForgotPswdClicked) {
    history.replace('/forgotpass')
  }

  let Locations = [
    { key: 'SamMelbourne', value: 'Samsung Melbourne' },
    { key: 'SamSydney', value: 'Samsung Sydney' },
  ]
  Locations = state.stores || Locations
  const { register, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedLocation,
    },
  })
  const confirmOrder = () => {
    // var CryptoJS = require("crypto-js");
    // var encryptedPassword = CryptoJS.AES.encrypt(password, process.env.SECURITY_KEY).toString();

    actions.login({
      username: email,
      password: password,
      captchatoken: setCaptcha,
    })
  }
  let defaultSelect = {
    PartnerStoreId: '',
    PartnerStoreName: 'Select Store Location',
    PartnerStoreNumber: 0,
  }
  Locations = [defaultSelect, ...Locations]
  const errorLength = Object.keys(errors).length
  // isFormValid = !!(!selectedLocation || !email || !password || !setCaptcha || errorLength > 0)
  isFormValid = selectedLocation && email && password && setCaptcha && errorLength === 0 ? true : false
  useEffect(() => {
    if (state && state.Login && (state.Login.Status === 'account_lock' || state.Login.Status === 'failure') && !setCaptcha) {
      captcha.current.reset()
      isFormValid = false
    }
  }, [state])  

  const navigateToForgotPassword = () => {
    if (state && state.Login && state.Login.selectedLocation) actions.navigateToForgotPassword()
    else {
      return false
    }
  }
  return (
    <>
      <div className="bg-white">
        <div className="inline-block  large-desktop:min-h-1020 laptop:h-668 large-desktop:max-w-w-content w-4/6 tablet:w-70 tablet:h-740">
          <div className="large-desktop:m-mr-header m-tablet-header ">
            <img className="h-5 large-desktop:h-h-30" src={logo} alt="" />
          </div>
          <div className="content large-desktop:mb-396 mb-216">
            <div className="large-desktop:ml-100  ml-30 large-desktop:m-mr-welcome font-Samsung-Sharp-Sans-Bold large-desktop:text-font-60 text-font-30 large-desktop:h-h-70 flex items-center">
              Welcome to Samsung Trade-Up
            </div>
            <div className=" large-desktop:ml-100 ml-30 large-desktop:m-mr-text m-mr-text-tab mt:15 font-SamsungOne-400 flex items-center large-desktop::text-3xl text-2xl large-desktop:h-h-36 font-light not-italic ">
              If you have your Trade-Up Store Agent account, please login to start the journey
            </div>
            <div className="mt-10 large-desktop:ml-100 ml-30 large-desktop:m-mr-loginform flex flex-col large-desktop:flex-row">
              <div className=" inline-block">
                <RenderSelectField
                  name="selectedLocation"
                  label="Location"
                  className="p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
                  placeholder="Select Store Location"
                  value={selectedLocation}
                  options={Locations}
                  optionsKey="PartnerStoreId"
                  optionsValue="PartnerStoreName"
                  register={register}
                  errors={errors}
                  onChange={actions.setLocation}
                  schema="login"
                  defaultValue={state && state.Login && state.Login.selectedLocation}
                  hideLabel
                  removeDefaultOption={true}
                />
              </div>
              <div className="inline-block ml-0 my-9 large-desktop:m-ml-15 ">
                <LabeledInputField
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Your Samsung email address"
                  className="p-input-box w-320 large-desktop:mr-text  leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light text-font-17 leading-tight "
                  register={register}
                  errors={errors}
                  onChange={actions.setEmail}
                  schema="login"
                  hideLabel
                />
              </div>
              <div className="large-desktop:inline-block hidden large-desktop:w-190 ml-0 large-desktop:m-ml-15 ">
                <LabeledInputField
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  className=" p-input-box  leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 "
                  register={register}
                  errors={errors}
                  onChange={actions.setPassword}
                  schema="login"
                  hideLabel
                />
                <div className="w-190 ml-0 m-ml-15 ">
                  <a
                    className={`${
                      state && state.Login && state.Login.selectedLocation
                        ? 'cursor-pointer active:text-purple-1 active:underline focus:underline focus:text-purple-1 '
                        : 'opacity-50 cursor-not-allowed'
                    }text-17 leading-28 mt-10 block font-SamsungOne-400 `}
                    onClick={() => navigateToForgotPassword()}
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>
              {state && state.Login && state.Login.Status === 'failure' && (
                <div className="large-desktop:hidden">
                  <div className="mt-20 tablet:hidden large-desktop:block large-desktop:hidden ">
                    <p className="error-text">User not found.</p>
                  </div>
                </div>
              )}
              {state && state.Login && state.Login.Status === 'account_lock' && (
                <div className="large-desktop:hidden">
                  <div className="mt-20 tablet:hidden large-desktop:block large-desktop:hidden ">
                    <p className="error-text">Too many failed attempts. Your account has been locked, please try again in 10 minutes.</p>
                  </div>
                </div>
              )}
               
              <div className="inline-block large-desktop:inline-block hidden">
                <button
                  disabled={isFormValid}
                  onClick={() => confirmOrder()}
                  type="submit"
                  className={`${
                    !isFormValid ? 'disabled-btn' : ''
                  } btn px-20 w-108 large-desktop:h-h-50 h-h-36 bg-blue-500 h-h-36 justify-center font-SamsungOne-400 not-italic font-bold text-xl leading-tight flex items-center text-center uppercase text-white my-8 m-0 large-desktop:m-ml-15`}
                >
                  Login
                </button>
              </div>
            </div>
            {state && state.Login && state.Login.Status === 'failure' && (
              <div className="mt-20 block ml-100 hidden large-desktop:block">
                <p className="error-text text-center">User not found.</p>
              </div>
            )}
            {state && state.Login && state.Login.Status === 'account_lock' && (
              <div className="mt-20 block ml-100 hidden large-desktop:block">
                <p className="error-text text-center">Too many failed attempts. Your account has been locked, please try again in 10 minutes.</p>
              </div>
            )}
            <div className=" large-desktop:hidden ml-30 flex flex-row ">
              <div className="inline-block tablet:w-210 w-320 large-desktop:w-180 ml-0 large-desktop:m-ml-15">
                <LabeledInputField
                  type="password"
                  name="passwordd"
                  label="Password"
                  placeholder="Password"
                  className="p-input-box  leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight "
                  register={register}
                  errors={errors}
                  onChange={actions.setPassword}
                  schema="login"
                  hideLabel
                />
              </div>
              <div className="inline-block">
                <button
                  disabled={!isFormValid}
                  onClick={() => confirmOrder()}
                  type="submit"
                  className={`${
                    isFormValid ? '' : 'disabled-btn'
                  } btn tablet:px-12 px-20 tablet:w-0 w-108 large-desktop:h-h-50 h-h-36 bg-blue-500 h-h-36 justify-center font-SamsungOne-400 not-italic font-bold text-xl leading-tight flex items-center text-center uppercase text-white my-8 m-0 tablet:my-0 tablet:m-ml-30 large-desktop:m-ml-15`}
                >
                  Login
                </button>
              </div>
            </div>
            <div className="large-desktop:hidden inline-block ml-30  large-desktop:w-190 ml-0 large-desktop:m-ml-15 flex flex-col">
              <a
                className="cursor-pointer text-17 leading-28 mt-10 block font-SamsungOne-400 active:text-purple-1 active:underline focus:underline focus:text-purple-1"
                onClick={() => {
                  actions.navigateToForgotPassword()
                }}
              >
                Forgot Password?
              </a>
            </div>
            {state && state.Login && state.Login.Status === 'failure' && (
              <div className="mt-20 block ml-30 block large-desktop:hidden">
                <p className="error-text">User not found.</p>
              </div>
            )}
            {state && state.Login && state.Login.Status === 'account_lock' && (
              <div className="mt-20 block ml-30 block large-desktop:hidden">
                <p className="error-text">Too many failed attempts. Your account has been locked, please try again in 10 minutes.</p>
              </div>
            )}
             <div className="large-desktop:ml-100 ml-30 ml-30 mt-30 flex flex-row">
              <ReCAPTCHA ref={captcha} sitekey={process.env.RECAPTCHA_SITE_KEY} onChange={actions.setCaptcha} onExpired={actions.expireCaptcha} />
              <hr />
            </div>
          </div>
          {/* <div className="fixed bottom-0 large-desktop:mb-50 mb-20">
            <div className="inline-block large-desktop:ml-100 ml-30">
              <img className="h-5 large-desktop:h-h-50" src={footerLogo} alt="" />
            </div>
          </div> */}
        </div>
        <div className="large-desktop:inline-block large-desktop:max-w-w-image float-right large-desktop:m-mr-image-right m:0 tablet:w-30 hidden">
          <img className="tablet:mr-0 mr-100" src={mobileImage1} alt="" />
        </div>
        <div className="inline-block float-right m:0 tablet:w-30 mr-30 large-desktop:hidden ">
          <img className="laptop:h-668 tablet:mr-0  tablet:h-800 " src={mobileImage} alt="" />
        </div>
      </div>
    </>
  )
}
export default Login
