export default function errorMessageGenerator(validatorId, label, opts, resources) {
  const _loadDefault = !window.culture || window.culture === 'en-US'
  const _label = (resources && resources[label]) || label
  const _resources =
    !resources || Object.keys(resources).length === 0
      ? {
          VALIDATION_ERROR_REQUIRED_DEFAULT: '{label} is Required',
          VALIDATION_ERROR_EMAIL_DEFAULT: '{label} should be a valid Email Address',
          VALIDATION_ERROR_BETWEEN_DEFAULT: '{label} should be one of {range}',
          VALIDATION_ERROR_NUMERIC_DEFAULT: '{label} should only contain numbers',
          VALIDATION_ERROR_INT_MIN_MAX_DEFAULT: '{label} should be between {min} and {max}',
          VALIDATION_ERROR_INT_MIN_DEFAULT: '{label} should be at least {min}',
          VALIDATION_ERROR_INT_MAX_DEFAULT: '{label} should be at most {max}',
          VALIDATION_ERROR_INT_REQ_DEFAULT: '{label} should be an Number',
          VALIDATION_ERROR_DATE_DEFAULT: '{label} should be a Date',
          VALIDATION_ERROR_AFTER_OPTS_DEFAULT: '{label} should be {validatorId} {opts}',
          VALIDATION_ERROR_AFTER_DEFAULT: '{label} should be {validatorId} Current Time',
          VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT: '{label} should be a minimum of {min} and a maximum of {max} characters',
          VALIDATION_ERROR_LENGTH_MIN_DEFAULT: '{label} should be a minimum of {min} characters',
          VALIDATION_ERROR_LENGTH_MAX_DEFAULT: '{label} should be a maximum of {max} characters',
          VALIDATION_ERROR_LENGTH_DEFAULT: '{label} should be a of {min} characters',
          VALIDATION_ERROR_LENGTH_INVALID_DEFAULT: '{label} is an Invalid length',
          VALIDATION_ERROR_URL_DEFAULT: '{label} should be a valid URL',
          VALIDATION_ERROR_DEFAULT_DEFAULT: '{label} is Invalid',
          VALIDATION_ERROR_ALPHA_DEFAULT: '{label} should only contain characters',
          VALIDATION_ERROR_ALPHANUM_DEFAULT: '{label} should only contain numbers and characters',
        }
      : {
          VALIDATION_ERROR_REQUIRED_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_REQUIRED ? resources.VALIDATION_ERROR_REQUIRED_DEFAULT : resources.VALIDATION_ERROR_REQUIRED,
          VALIDATION_ERROR_EMAIL_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_EMAIL ? resources.VALIDATION_ERROR_EMAIL_DEFAULT : resources.VALIDATION_ERROR_EMAIL,
          VALIDATION_ERROR_BETWEEN_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_BETWEEN ? resources.VALIDATION_ERROR_BETWEEN_DEFAULT : resources.VALIDATION_ERROR_BETWEEN,
          VALIDATION_ERROR_NUMERIC_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_NUMERIC ? resources.VALIDATION_ERROR_NUMERIC_DEFAULT : resources.VALIDATION_ERROR_NUMERIC,
          VALIDATION_ERROR_INT_MIN_MAX_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_INT_MIN_MAX ? resources.VALIDATION_ERROR_INT_MIN_MAX_DEFAULT : resources.VALIDATION_ERROR_INT_MIN_MAX,
          VALIDATION_ERROR_INT_MIN_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_INT_MIN ? resources.VALIDATION_ERROR_INT_MIN_DEFAULT : resources.VALIDATION_ERROR_INT_MIN,
          VALIDATION_ERROR_INT_MAX_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_INT_MAX ? resources.VALIDATION_ERROR_INT_MAX_DEFAULT : resources.VALIDATION_ERROR_INT_MAX,
          VALIDATION_ERROR_INT_REQ_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_INT_REQ ? resources.VALIDATION_ERROR_INT_REQ_DEFAULT : resources.VALIDATION_ERROR_INT_REQ,
          VALIDATION_ERROR_DATE_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_DATE ? resources.VALIDATION_ERROR_DATE_DEFAULT : resources.VALIDATION_ERROR_DATE,
          VALIDATION_ERROR_AFTER_OPTS_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_AFTER_OPTS ? resources.VALIDATION_ERROR_AFTER_OPTS_DEFAULT : resources.VALIDATION_ERROR_AFTER_OPTS,
          VALIDATION_ERROR_AFTER_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_AFTER ? resources.VALIDATION_ERROR_AFTER_DEFAULT : resources.VALIDATION_ERROR_AFTER,
          VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT:
            _loadDefault || !resources.VALIDATION_ERROR_LENGTH_MIN_MAX ? resources.VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT : resources.VALIDATION_ERROR_LENGTH_MIN_MAX,
          VALIDATION_ERROR_LENGTH_MIN_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_LENGTH_MIN ? resources.VALIDATION_ERROR_LENGTH_MIN_DEFAULT : resources.VALIDATION_ERROR_LENGTH_MIN,
          VALIDATION_ERROR_LENGTH_MAX_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_LENGTH_MAX ? resources.VALIDATION_ERROR_LENGTH_MAX_DEFAULT : resources.VALIDATION_ERROR_LENGTH_MAX,
          VALIDATION_ERROR_LENGTH_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_LENGTH ? resources.VALIDATION_ERROR_LENGTH_DEFAULT : resources.VALIDATION_ERROR_LENGTH,
          VALIDATION_ERROR_LENGTH_INVALID_DEFAULT:
            _loadDefault || !resources.VALIDATION_ERROR_LENGTH_INVALID ? resources.VALIDATION_ERROR_LENGTH_INVALID_DEFAULT : resources.VALIDATION_ERROR_LENGTH_INVALID,
          VALIDATION_ERROR_URL_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_URL ? resources.VALIDATION_ERROR_UR_DEFAULTL : resources.VALIDATION_ERROR_URL,
          VALIDATION_ERROR_DEFAULT_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_DEFAULT ? resources.VALIDATION_ERROR_DEFAULT_DEFAULT : resources.VALIDATION_ERROR_DEFAULT,
          VALIDATION_ERROR_ALPHA_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_ALPHA ? resources.VALIDATION_ERROR_ALPHA_DEFAULT : resources.VALIDATION_ERROR_ALPHA,
          VALIDATION_ERROR_ALPHANUM_DEFAULT: _loadDefault || !resources.VALIDATION_ERROR_ALPHANUM ? resources.VALIDATION_ERROR_ALPHANUM_DEFAULT : resources.VALIDATION_ERROR_ALPHANUM,
        }
  switch (validatorId) {
    case 'required':
      return _resources.VALIDATION_ERROR_REQUIRED_DEFAULT.replace('{label}', _label)

    case 'email':
      return _resources.VALIDATION_ERROR_EMAIL_DEFAULT.replace('{label}', _label)

    case 'in':
      return _resources.VALIDATION_ERROR_BETWEEN_DEFAULT.replace('{label}', _label).replace('{opts}', opts.join(', '))

    case 'numeric':
      return _resources.VALIDATION_ERROR_NUMERIC_DEFAULT.replace('{label}', _label)

    case 'minLength':
      opts = opts || {}
      if (opts.minLength && opts.maxLength) {
        if (opts.minLength === opts.maxLength) {
          return _resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
        }
        return _resources.VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength).replace('{max}', opts.maxLength)
      }
      if (opts.minLength) {
        return _resources.VALIDATION_ERROR_INT_MIN_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
      }

    case 'maxLength':
      opts = opts || {}
      if (opts.minLength && opts.maxLength) {
        if (opts.minLength === opts.maxLength) {
          return _resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
        }
        return _resources.VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength).replace('{max}', opts.maxLength)
      }
      if (opts.maxLength) {
        return _resources.VALIDATION_ERROR_INT_MAX_DEFAULT.replace('{label}', _label).replace('{max}', opts.maxLength)
      }

    case 'int':
      opts = opts || {}

      if (opts.min && opts.max) {
        return _resources.VALIDATION_ERROR_INT_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.min).replace('{max}', opts.max)
      }

      if (opts.min) {
        return _resources.VALIDATION_ERROR_INT_MIN_DEFAULT.replace('{label}', _label).replace('{min}', opts.min)
      }

      if (opts.max) {
        return _resources.VALIDATION_ERROR_INT_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.max)
      }

      // otherwise message should just require integer value
      return _resources.VALIDATION_ERROR_INT_REQ_DEFAULT.replace('{label}', _label)

    case 'date':
      return _resources.VALIDATION_ERROR_DATE_DEFAULT.replace('{label}', _label)

    case 'before':
    case 'after':
      if (opts) {
        return _resources.VALIDATION_ERROR_AFTER_OPTS_DEFAULT.replace('{label}', _label).replace('{validatorId}', validatorId).replace('{opts}', opts.toString())
      }

      return _resources.VALIDATION_ERROR_AFTER_DEFAULT.replace('{label}', _label).replace('{validatorId}', validatorId)

    case 'URL':
      return _resources.VALIDATION_ERROR_URL_DEFAULT.replace('{label}', _label)

    case 'alpha':
      return _resources.VALIDATION_ERROR_ALPHA_DEFAULT.replace('{label}', _label)

    case 'alphanumeric':
      return _resources.VALIDATION_ERROR_ALPHANUM_DEFAULT.replace('{label}', _label)

    default:
      return _resources.VALIDATION_ERROR_DEFAULT_DEFAULT.replace('{label}', _label)
  }
}
