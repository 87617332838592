import actionTypes from './actionTypes'

const initialState = {
  isLoading: false,
  Login: {},
  SearchId: {},
  CustomerInfo: {},
  LogisticsInputField: {},
  Login: {},
  LogisticsBatchDetailsInputField: {},
  selectedPage: 0,
  LogisticsOrderDetailsTab: true,
  logisticsBatchData: {},
  batchSelectedPage: 0,
  osotReportData: {},
  ReportingDetailsInputFields: {},
  reportSelectedPage: 0,
  masterReportData: [],
  reportDetailsSuccess: false,
  LVDCustomerInfo: {},
  tradeDetails: {},
  viewDetails: false,
}

const reducer = (state = initialState, action) => {
  //console.log("state ----->", action, state)
  if (action.type === actionTypes.SET_LOADING_INDICATOR) {
    return {
      ...state,
      ...{
        isLoading: true,
      },
    }
  }
  if (action.type === actionTypes.UNSET_LOADING_INDICATOR) {
    return {
      ...state,
      ...{
        isLoading: false,
      },
    }
  }
  if (action.type === actionTypes.API_ERROR) {
    let response =
      action.data && action.data.response && action.data.response.data && action.data.response.data.LoginAPiResponse
        ? action.data.response.data.LoginAPiResponse
        : action.data && action.data.response && action.data.response.data && action.data.response.data.ChangeTempPasswordResponse
        ? action.data.response.data.ChangeTempPasswordResponse
        : undefined
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          Status: response ? response.Status : state.Login.Status,
          AccessToken: response ? response.AccessToken : state.Login.AccessToken,
          IdToken: response ? response.IdToken : state.Login.IdToken,
          RefreshToken: response ? response.RefreshToken : state.Login.RefreshToken,
          CognitoError: response && response.Error,
          setCaptcha: null
        },
        isError: response ? false : true,
        ErrorDetails: action.data,
      },
    }
  }
  if (action.type === actionTypes.INITIALIZE_SUCCESS) {
    return {
      ...state,
      initializeData: action.data,
      masterData: action.data.data.Masterdata,
      stores: action.data.data.Masterdata.Stores,
    }
  }

  if (action.type === actionTypes.SET_EMAIL) {
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          email: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_PASSWORD) {
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          password: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_CAPTCHA) {
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          setCaptcha: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.EXPIRE_CAPTCHA) {
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          setCaptcha: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LOCATION) {
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          selectedLocation: action.data,
          selectedLocationErrMsg: false,
        },
      },
    }
  }
  if (action.type === actionTypes.LOGIN_REQUEST) {
    return {
      ...state,
      ...{
        path: 'confirmorder',
      },
    }
  }
  if (action.type === actionTypes.LOGIN_SUCCESS) {
    let LoginRes = action.data && action.data.data && action.data.data.LoginAPiResponse
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          Status: LoginRes && LoginRes.Status,
          AccessToken: LoginRes && LoginRes.AccessToken,
          IdToken: LoginRes && LoginRes.IdToken,
          RefreshToken: LoginRes && LoginRes.RefreshToken,
          StoreAgentName: LoginRes && LoginRes.StoreAgentName,
          PartnerStoreId: LoginRes && LoginRes.PartnerStoreId,
          StoreAgentRole: LoginRes && LoginRes.StoreAgentRole,
          PartnerParentStoreCode: LoginRes && LoginRes.PartnerParentStoreCode,
          PartnerStoreCode: LoginRes && LoginRes.PartnerStoreCode,
          AgentId: LoginRes && LoginRes.StoreId,
        },
        loginsuccess: LoginRes && LoginRes.Status === 'change_pass' ? false : true,
      },
    }
  }
  if (action.type === actionTypes.CHANGE_PASSWORD_SUCCESS) {
    let passRes = action.data && action.data.data && action.data.data.ChangeTempPasswordResponse
    return {
      ...state,
      ...{
        Login: {
          ...state.Login,
          Status: passRes && passRes.Status,
          AccessToken: passRes && passRes.AccessToken,
          IdToken: passRes && passRes.IdToken,
          RefreshToken: passRes && passRes.RefreshToken,
          PartnerStoreCode: action.data && action.data.PartnerStoreCode,
          StoreAgentName: action.data && action.data.StoreAgentName,
          PartnerStoreId: action.data && action.data.PartnerStoreId,
          StoreAgentRole: action.data && action.data.StoreAgentRole,
          PartnerParentStoreCode: action.data && action.data.PartnerParentStoreCode,
          AgentId: action.data && action.data.StoreId,
        },
        loginsuccess: true,
      },
    }
  }
  if (action.type === actionTypes.SET_TRADEUPID) {
    return {
      ...state,
      ...{
        SearchId: {
          ...state.SearchId,
          tradeupid: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_IMEI) {
    return {
      ...state,
      ...{
        SearchId: {
          ...state.SearchId,
          imei: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.GET_TRADE_INFO_REQUEST) {
    return {
      ...state,
      tradeinfo: {},
      sendLabelCheck: undefined,
      EmailLabelSuccess: undefined,
    }
  }

  if (action.type === actionTypes.GET_TRADE_DETAILS_SUCCESS) {
    return {
      ...state,
      tradeDetails: action.data.TradeDetails,
      viewDetails: true,
    }
  }

  if (action.type === actionTypes.GET_TRADE_INFO_SUCCESS) {
    const res = action.data && action.data.data && action.data.data.FindTradeInfoResponse && action.data.data.FindTradeInfoResponse
    return {
      ...state,
      tradeinfo: action.data,
      ...{
        CustomerInfo: {
          editable: false,
          FirstName: res && res.CustomerInformation && res.CustomerInformation.FirstName,
          LastName: res && res.CustomerInformation && res.CustomerInformation.LastName,
          EmailAddress: res && res.EmailAddress,
          MobileNo: res && res.PhoneNbr,
          AddressLine1: res && res.CustomerInformation && res.CustomerInformation.AddressLine1,
          AddressLine2: res && res.CustomerInformation && res.CustomerInformation.AddressLine2,
          AddressLine3: res && res.CustomerInformation && res.CustomerInformation.AddressLine3,
          ZipCode: res && res.CustomerInformation && res.CustomerInformation.PostalCode,
          City: res && res.CustomerInformation && res.CustomerInformation.CityName,
          State: res && res.CustomerInformation && res.CustomerInformation.StateProvinceCode,
        },
      },
    }
  }
  if (action.type === actionTypes.GET_FMIP_STATUS_REQUEST) {
    return {
      ...state,
      verificationStatus: {},
    }
  }
  if (action.type === actionTypes.GET_FMIP_STATUS_SUCCESS) {
    return {
      ...state,
      verificationStatus: action.data,
    }
  }
  if (action.type === actionTypes.SET_EDIT) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          editable: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_FIRSTNAME) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          FirstName: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LASTNAME) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          LastName: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_MOBILENO) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          MobileNo: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_ADDLINE1) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          AddressLine1: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_ADDLINE2) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          AddressLine2: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_ADDLINE3) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          AddressLine3: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_ZIPCODE) {
    return {
      ...state,
      ...{
        isError: false,
        CustomerInfo: {
          ...state.CustomerInfo,
          ZipCode: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_CITY) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          City: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_STATE) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          State: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.GET_CITY_STATE_REQUEST) {
    return {
      ...state,
      ...{
        isError: false,
        CustomerInfo: {
          ...state.CustomerInfo,
          zipcode: action.data,
          states: {},
        },
      },
    }
  }
  if (action.type === actionTypes.GET_CITY_STATE_FAILURE) {
    return {
      ...state,
      ...{
        isError: true,
        ErrorDetails: action.data,
      },
    }
  }
  if (action.type === actionTypes.GET_CITY_STATE_SUCCESS) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          states: action.data && action.data.data,
        },
        isError: false,
      },
    }
  }

  if (action.type === actionTypes.SET_COUNTRY) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          Country: action.data,
        },
      },
    }
  }

  if (action.type === actionTypes.SET_DEVICE_POPULAR_NAME) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          DevicePopularName: action.data && action.data.value,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_DEVICE_IMEI_CHECK) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          DeviceImeiCheck: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_DEVICE_MODEL_CHECK) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          DeviceModelCheck: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_CUSTOMER_INFO_CHECK) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          CustomerInfoCheck: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_PHYSICAL_RESULTS_CHECK) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          SetPhysicalResultsCheck: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_OPEN_POPUP) {
    return {
      ...state,
      ...{
        CustomerInfo: {
          ...state.CustomerInfo,
          openPopUp: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.UPDATE_TRADE_INFO_SUCCESS) {
    return {
      ...state,
      updateTradeResponse: action.data,
    }
  }
  if (action.type === actionTypes.CLOSE_BAR_BARCODE) {
    return {
      ...state,
      updateTradeResponse: {},
      CustomerInfo: {},
      ErrorDetails: {},
      tradeinfo: {},
      ...{
        SearchId: {
          tradeupid: '',
        },
      },
      isError: false,
    }
  }
  if (action.type === actionTypes.CHANGE_PASSWORD_REQUEST) {
    return { ...state, ...{ isSubmitted: true } }
  }
  if (action.type === actionTypes.SET_CURRENT_PASSWORD) {
    return {
      ...state,
      ...{
        Login: { ...state.Login, currentPassword: action.data, invalidPassword: false },
        isSubmitted: false,
      },
    }
  }
  if (action.type === actionTypes.SET_CONFIRM_NEW_PASSWORD) {
    return {
      ...state,
      ...{
        Login: { ...state.Login, confirmNewPassword: action.data, invalidPassword: false },
        isSubmitted: false,
      },
    }
  }
  if (action.type === actionTypes.SET_NEW_PASSWORD) {
    return {
      ...state,
      ...{
        Login: { ...state.Login, newPassword: action.data, invalidPassword: false },
        isSubmitted: false,
      },
    }
  }
  if (action.type === actionTypes.SET_INVALID_PASSWORD) {
    return {
      ...state,
      ...{ Login: { ...state.Login, invalidPassword: true } },
      isSubmitted: true,
    }
  }

  if (action.type === actionTypes.GET_LOGISTICES_REQUEST) {
    return { ...state, BatchingItems: null }
  }

  if (action.type === actionTypes.GET_LOGISTICES_SUCCESS) {
    let deviceList = action.data.DeviceList.map((item) => {
      item.isSelected = false
      return item
    })
    let data = { DeviceList: deviceList, ToBeBatched: action.data.ToBeBatched, TotalRecords: action.data.TotalRecords }

    return { ...state, ...{ logisticsData: data } }
  }
  if (action.type === actionTypes.SET_LOGISTICS_DATA) {
    return { ...state }
  }

  if (action.type === actionTypes.SET_LOGISTICS_DATA_ALL) {
    return { ...state }
  }

  if (action.type === actionTypes.SET_ORDER_NUMBER) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, OrderNumber: action.data } }
  }

  if (action.type === actionTypes.SET_START_DATE) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, StartDate: action.data } }
  }

  if (action.type === actionTypes.SET_END_DATE) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, EndDate: action.data } }
  }

  if (action.type === actionTypes.SET_MAKE) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Make: action.data } }
  }

  if (action.type === actionTypes.SET_MODEL_VARRIANT) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Model_Varriant: action.data } }
  }
  if (action.type === actionTypes.LOGISTICS_LBL_CONDITION) {
    return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Condition: action.data } }
  }

  if (action.type === actionTypes.CREATE_BATCH_REQUEST) {
    return { ...state, batchRequest: true, AusPost: null, BatchingItems: null, isError: false }
  }
  if (action.type === actionTypes.CREATE_BATCH_SUCCESS) {
    let batchingItems = state.logisticsData.DeviceList.filter((item) => {
      return item.isSelected === true
    })
    let newBatchingItems = batchingItems.map((item) => {
      item.BatchId = action.data.BatchId
      return item
    })
    return { ...state, ...{ createBatchResponse: action.data }, BatchingItems: newBatchingItems, redirectToLogistics: false }
  }

  if (action.type === actionTypes.SET_SELECTED_PAGE) {
    return { ...state, selectedPage: action.data }
  }

  if (action.type === actionTypes.SET_ORDER_DETAILS_TAB) {
    return {
      ...state,
      LogisticsOrderDetailsTab: action.data,
      UpdateStoreSuccess: null,
      creatItemsArray: null,
      logisticsStoreId: null,
      createBatchResponse: null,
      BatchingItems: null,
      AusPost: null,
    }
  }

  if (action.type === actionTypes.GET_LOGISTICES_BATCH_SUCCESS) {
    return { ...state, ...{ logisticsBatchData: action.data } }
  }

  if (action.type === actionTypes.SET_LOGISTICS_BATCH_DATA) {
    return { ...state, logisticsBatchData: { ...state.logisticsBatchData, BatchItems: action.data } }
  }

  if (action.type === actionTypes.CREATE_PARCEL_REQUEST) {
    return { ...state, isError: false }
  }

  if (action.type === actionTypes.SET_BATCH_NUMBER) {
    return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, batchNumber: action.data } }
  }

  if (action.type === actionTypes.SET_BATCH_CONSIGNMENT_NUMBER) {
    return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, consignmentNumber: action.data } }
  }

  if (action.type === actionTypes.SET_LOGISTICS_TAB) {
    return { ...state, LogisticsTab: action.data }
  }

  if (action.type === actionTypes.SET_ORDER_TAB) {
    return { ...state, OrderTab: action.data }
  }

  if (action.type === actionTypes.SET_TRADEDETAILS_TAB) {
    return { ...state, TradeDetailsTab: action.data }
  }

  if (action.type === actionTypes.SET_LVD_TAB) {
    return { ...state, LowValueDeviceTab: action.data }
  }

  if (action.type === actionTypes.SET_REPORTING_TAB) {
    return { ...state, ReportsTab: action.data }
  }

  if (action.type === actionTypes.SET_REPORT_DETAILS_TAB) {
    return { ...state, ...{ reportingDetailsTab: action.payload, reportDetailsSuccess: false } }
  }
  if (action.type === actionTypes.SET_REPORT_SUMMARY_TAB) {
    return { ...state, ...{ reportingSummaryTab: action.payload, masterReportData: [], reportDetails: [], shoeReportingDetails: false } }
  }
  if (action.type === actionTypes.SET_REPORT_CARE_CREDIT_TAB) {
    return { ...state, ...{ reportingCareCreditTab: action.payload, reportDetailsSuccess: false, shoeReportingDetails: false } }
  }

  if (action.type === actionTypes.SET_BATCH_SELECTED_PAGE) {
    return { ...state, batchSelectedPage: action.data }
  }

  if (action.type === actionTypes.SHOW_CANVAS_REPRINT) {
    return { ...state, showCanvasReprint: true }
  }

  if (action.type === actionTypes.HIDE_CANVAS_REPRINT) {
    return { ...state, showCanvasReprint: false }
  }
  if (action.type === actionTypes.GET_LOGISTICES_BATCH_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.SET_BATCH_START_DATE) {
    return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, batchStartDate: action.payload } }
  }

  if (action.type === actionTypes.SET_TRADE_ID_NUMBER) {
    return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, tradeId: action.payload } }
  }

  if (action.type === actionTypes.SET_EMAIL_ID) {
    return {
      ...state,
      ...{ isForgotPswdClicked: false, isForgotPassFailure: false },
      Login: { ...state.Login, emailId: action.payload, email: action.payload },
    }
  }
  if (action.type === actionTypes.NAVIGATE_TO_FORGOT_PASSWORD) {
    return { ...state, ...{ isForgotPswdClicked: true, selectedLocationErrMsg: false } }
  }
  if (action.type === actionTypes.FORGORT_PASSWORD_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.FORGORT_PASSWORD_SUCCESS) {
    return { ...state, ...{ forgotPasswordSuccessResponse: action.data } }
  }
  if (action.type === actionTypes.FORGORT_PASSWORD_FAILURE) {
    return { ...state, ...{ forgotPasswordError: action.data, isForgotPassFailure: true } }
  }
  if (action.type === actionTypes.SET_VERIFICATION_CODE) {
    return { ...state, Login: { ...state.Login, verificationCode: action.payload } }
  }
  if (action.type === actionTypes.CONFIRM_PASSWORD_REQUEST) {
    return { ...state, ...{ isSubmitted: true } }
  }
  if (action.type === actionTypes.CONFIRM_PASSWORD_SUCCESS) {
    const confirmPassSuccess = action.data && action.data.Status === 'success' ? true : false
    return {
      ...state,
      Login: {
        ...state.Login,
        Status: action.data && action.data.Status,
        AccessToken: action.data && action.data.AccessToken,
        IdToken: action.data && action.data.IdToken,
        RefreshToken: action.data && action.data.RefreshToken,
        StoreAgentName: action.data && action.data.StoreAgentName,
        PartnerStoreCode: action.data && action.data.PartnerStoreCode,
        PartnerStoreId: action.data && action.data.PartnerStoreId,
        PartnerParentStoreCode: action.data && action.data.PartnerParentStoreCode,
        StoreAgentRole: action.data && action.data.StoreAgentRole,
        PartnerStoreChannel: action.data && action.data.PartnerStoreChannel,
      },
      loginsuccess: confirmPassSuccess,
    }
  }
  if (action.type === actionTypes.CONFIRM_PASSWORD_FAILURE) {
    return { ...state, confirmPasswordError: action.data, isConfirmPassFailure: true }
  }

  if (action.type === actionTypes.CREATE_PARCEL_SUCCESS) {
    let createShipmentResponse = action.data.ReprintDetails ? undefined : action.data.responses && action.data.responses.createShipmentResponse
    let CreateLabelResponse =
      action.data.ReprintDetails && action.data.ReprintDetails.OrderLabelData ? action.data.ReprintDetails.OrderLabelData : action.data.responses && action.data.responses.CreateLabelResponse
    let ConsignmentNo = action.data.ReprintDetails ? undefined : createShipmentResponse && createShipmentResponse.shipments[0].items[0].tracking_details.consignment_id
    let base64Summary =
      action.data.ReprintDetails && action.data.ReprintDetails.OrderSummaryPdfData
        ? action.data.ReprintDetails.OrderSummaryPdfData
        : action.data.responses && action.data.responses.OrderSummaryExpResponse
    let base64Label = action.data.ReprintDetails && action.data.ReprintDetails.OrderLabelData ? action.data.ReprintDetails.OrderLabelData : action.data.responses && action.data.responses.File
    return {
      ...state,
      AusPost: {
        base64Summary: base64Summary,
        base64Label: base64Label,
        ConsignmentNo: ConsignmentNo,
      },
    }
  }

  if (action.type === actionTypes.SHOW_CANVAS) {
    return { ...state, showCanvas: true }
  }
  if (action.type === actionTypes.HIDE_CANVAS) {
    return { ...state, showCanvas: false }
  }
  if (action.type === actionTypes.REDIRECT_TO_LOGISTICS) {
    return { ...state, redirectToLogistics: true, BatchingItems: null, creatItemsArray: null }
  }
  if (action.type === actionTypes.DOWNLOAD_FILE_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.DOWNLOAD_FILE_SUCCESS) {
    return { ...state, DownloadedReports: action.data }
  }
  if (action.type === actionTypes.GET_FILE_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.GET_FILE_SUCCESS) {
    return { ...state, Reports: action.data }
  }
  if (action.type === actionTypes.SET_RECURRING) {
    return { ...state, ...{ recerringCredit: action.payload, isUpFront: false } }
  }
  if (action.type === actionTypes.CREATE_BATCH_ONSUBMIT) {
    return { ...state }
  }
  if (action.type === actionTypes.CREATE_BATCH_ONSUBMIT_SUCCESS) {
    return { ...state, ...{ BatchId: action.data.BatchId } }
  }
  if (action.type === actionTypes.CREATE_PARCEL_ONSUBMIT_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.CREATE_PARCEL_ONSUBMIT_SUCCESS) {
    return { ...state }
  }
  if (action.type === actionTypes.GET_REPORTING_DETAILS_REQUEST) {
    return { ...state, ...{ isError: false, reportDetailsSuccess: false, exportSuccessFlag: false } }
  }
  if (action.type === actionTypes.GET_REPORTING_DETAILS_SUCCESS) {
    let reportDetails = action.data.ReportsData.map((item) => {
      item.isSelected = false
      return item
    })
    let details = { ReportDetails: reportDetails, TotalRecords: action.data.TotalRecords }
    return {
      ...state,
      ...{
        reportingData: details,
        shoeReportingDetails: false,
        reportDetailsSuccess: true,
        masterReportSummaryData: action.data.masterReportData,
        exportSuccessFlag: true,
      },
    }
  }
  if (action.type === actionTypes.SET_MASTER_VIEW_DATA) {
    return { ...state, ...{ masterReportData: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_TRADE_ID_NUMBER) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, Tradeid: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_START_DATE) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, StartDate: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_END_DATE) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, EndDate: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_STATE) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, State: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_STATUS) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, Status: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_STORE_NAME) {
    return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, StoreName: action.payload } }
  }
  if (action.type === actionTypes.SET_REPORT_SELECTED_PAGE) {
    return { ...state, ...{ reportSelectedPage: action.payload } }
  }
  if (action.type === actionTypes.SET_OSOT_REPORT_DATA) {
    return { ...state, osotReportData: { ...state.osotReportData, GetOSOTReportDetails: action.payload } }
  }
  if (action.type === actionTypes.SET_SHOW_OSOT_REPORT_DATA) {
    return { ...state, ...{ shoeReportingDetails: action.payload } }
  }
  if (action.type === actionTypes.GET_ROLE_ACCESS_REQUEST) {
    return { ...state }
  }
  if (action.type === actionTypes.GET_ROLE_ACCESS_SUCCESS) {
    return { ...state, ...{ roles: action.data } }
  }
  if (action.type === actionTypes.SET_SEND_LABEL_CHECK) {
    return { ...state, ...{ sendLabelCheck: action.payload, EmailLabelSuccess: undefined } }
  }
  if (action.type === actionTypes.SEND_EMAIL_LABEL_REQUEST) {
    return { ...state, ...{ EmailLabelSuccess: false } }
  }
  if (action.type === actionTypes.SEND_EMAIL_LABEL_SUCCESS) {
    return { ...state, ...{ EmailLabelSuccess: true } }
  }
  if (action.type === actionTypes.SEND_EMAIL_LABEL_FAILURE) {
    return { ...state, ...{ EmailLabelSuccess: false } }
  }

  if (action.type === actionTypes.GET_EXCEPTION_DETAILS_REQUEST) {
    return { ...state, ...{ daxImagesResponse: '', requestedRedemptionOrder: '' } }
  }

  if (action.type === actionTypes.GET_EXCEPTION_DETAILS_SUCCESS) {
    return { ...state, ...{ exceptionSuccess: true, exceptionResponse: action.data.EscalationsResponse, exceptionList: action.data.EscalationsResponse.EscalationItems } }
  }

  if (action.type === actionTypes.SET_ESCALATION_CODE) {
    return { ...state, SearchException: { ...state.SearchException, escalationCode: action.payload } }
  }

  if (action.type === actionTypes.SET_EXCEPTION_TRADEID) {
    return { ...state, SearchException: { ...state.SearchException, tradeId: action.payload } }
  }

  if (action.type === actionTypes.SET_EXCEPTION_STARTDATE) {
    return { ...state, SearchException: { ...state.SearchException, startDate: action.payload } }
  }

  if (action.type === actionTypes.SET_EXCEPTION_ENDDATE) {
    return { ...state, SearchException: { ...state.SearchException, endDate: action.payload } }
  }

  if (action.type === actionTypes.GET_DAX_IMAGE_REQUEST) {
    return { ...state, ...{ requestedRedemptionOrder: action.data, daxImagesResponse: '' } }
  }

  if (action.type === actionTypes.GET_DAX_IMAGE_SUCCESS) {
    return { ...state, ...{ daxImagesResponse: action.data.GetDAXImageResponse, GetDAXImageResponse: '' } }
  }

  if (action.type === actionTypes.UDPATE_EXCEPTION_ACTION_REQUEST) {
    return { ...state }
  }

  if (action.type === actionTypes.UDPATE_EXCEPTION_ACTION_SUCCESS) {
    return { ...state }
  }

  if (action.type === actionTypes.SET_EXCEPTION_TAB) {
    return { ...state, ExceptionsTab: action.payload }
  }
  /** email audit report */
  if (action.type === actionTypes.SET_REPORT_EMAIL_AUDIT_TAB) {
    return { ...state, ...{ reportingEmailAuditTab: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_STATUS) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, Status: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_TEMPLATE) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, TemplateName: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_TRADE_ID) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, TradeId: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_START_DATE) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, StartDate: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_END_DATE) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, EndDate: action.payload } }
  }
  if (action.type === actionTypes.GET_EMAIL_AUDIT_REPORT_SUCCESS) {
    return { ...state, ...{ getEmailAuditReportSuccess: action.data } }
  }
  if (action.type === actionTypes.GET_EMAIL_AUDIT_REPORT_FAILURE) {
    return { ...state, ...{ getEmailAuditReportError: action.data } }
  }
  if (action.type === actionTypes.SET_EMAIL_REPORT_SELECTED_PAGE) {
    return { ...state, ...{ emailReportSelectedPage: action.payload } }
  }
  if (action.type === actionTypes.SET_EMAIL_AUDIT_EMAIL_SEARCH) {
    return { ...state, EmailAuditInputFields: { ...state.EmailAuditInputFields, searchEmail: action.payload } }
  }
  //Low Value Device Start
  if (action.type === actionTypes.SET_LVD_DEVICE_TYPE) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DeviceType: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_OFFERED) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DeviceOffered: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_FIRST_NAME) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          FirstName: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_LAST_NAME) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          LastName: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_MOBILE_NO) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          MobileNo: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_EMAIL_ADDRESS) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          EmailAddress: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_ADDRESS_LINE1) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          AddressLine1: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_ADDRESS_LINE2) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          AddressLine2: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_ADDRESS_LINE3) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          AddressLine3: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_POSTAL_CODE) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          PostalCode: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_CITY) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          City: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_STATE) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          State: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_COUNTRY) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          Country: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_ACTIVE_ACCOUNT_LOCK) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DeviceActiveAccountLock: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_PIN_LOCK) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DevicePinLock: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_REPORT_LOST_OR_STOLEN) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DeviceReportLostOrStolen: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_POWERED_OFF) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DevicePoweredOff: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_DEVICE_FACTORY_RESET) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          DeviceFactoryReset: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_LVD_CUSTOMER_INFO_CHECK) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          LVDCustomerInfoCheck: action.data,
        },
      },
    }
  }
  if (action.type === actionTypes.CREATE_QUOTE_LVD_REQUEST) {
    return {
      ...state,
      quoteStatus: {},
    }
  }
  if (action.type === actionTypes.CREATE_QUOTE_LVD_SUCCESS) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          CreateQuoteResponse: action.data,
        },
      },
    }
  }

  if (action.type === actionTypes.GET_AMTA_CHECK_LVD_SUCCESS) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          AMTAResponse: action.data,
        },
      },
    }
  }

  if (action.type === actionTypes.SET_LVD_DEVICE_IMEI) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          IMEI: action.payload,
        },
      },
    }
  }

  if (action.type === actionTypes.SET_LVD_QUESTIONS) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          LvdQuestions: action.payload,
        },
      },
    }
  }

  if (action.type === actionTypes.SET_LVD_AMTA_CALL) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          AmtaCall: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.SET_OPEN_LVD_POPUP) {
    return {
      ...state,
      ...{
        LVDCustomerInfo: {
          ...state.LVDCustomerInfo,
          openLVDPopUp: action.payload,
        },
      },
    }
  }
  if (action.type === actionTypes.CLOSE_LVD_BAR_BARCODE) {
    return {
      ...state,
      updateTradeResponse: {},
      LVDCustomerInfo: {},
      ErrorDetails: {},
      isError: false,
    }
  }
  //Low Value Device End
  return state
}

export { initialState, reducer }
