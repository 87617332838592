import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import LabeledInputField from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import shape from '../../images/Shape.png'

const Search = () => {
  const { state, dispatch } = useContext(store)
  const { tradeupid } = state.SearchId
  const actions = useActions(state, dispatch)
  const { AccessToken, IdToken, RefreshToken } = state.Login

  let tokenHeders = { AccessToken: AccessToken, IdToken: IdToken, RefreshToken: RefreshToken }
  const { register, errors } = useForm({
    mode: 'onChange',
  })

  const getTradeInfo = (state) => {
    actions.getTradeInfo(tradeupid, tokenHeders)
  }
  const errorLength = Object.keys(errors).length

  const isFormValid = !!(!tradeupid || errorLength > 0)

  return (
    <div className="flex  justify-between items-center ">
      <div className=" large-desktop:text-4xl text-30  p-1 font-Samsung-Sharp-Sans-Bold">Confirm Order</div>
      <div className="flex flex-row h-36 large-desktop:h-h-50 large-desktop:mt-42 mt-15">
        {/* <ul className="inline-flex"> */}
        {/* <li className="mx-5 max-w-w-tradeid"> */}
        {/* <div className="inline-block max-w-w-tradeid"> */}
        <LabeledInputField
          type="text"
          name="tradeid"
          label="Trade Id"
          placeholder="Samsung Trade-up ID"
          className="shadow border flex rounded max-w-w-tradeid py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-20"
          register={register}
          errors={errors}
          onChange={actions.setTradeId}
          schema="searchid"
          hideLabel
          value={tradeupid}
        />
        <div className="example-jsx flex absolute large-desktop:m-mr-181 m-mr-172 float-right">
          <div>
            <div data-tip data-for="sadFace" className="flex image-hover" ></div>
            <ReactTooltip className="tooltip-contact w-180 h-h-70 orange" place="bottom" type="dark" effect="float" id="sadFace" type="error" clickable style="background-color:black">
              <p className = 'text-13 leading-4 font-SamsungOne-400 mx-10 my-10 '>
                Customer should provide <b>Trade-Up ID No.</b> from the Trade-Up app.
              </p>
            </ReactTooltip>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="inline-block"> */}
        <button
          className={`${
            isFormValid ? 'disabled-btn' : ''
          } items-center h-36 large-desktop:h-h-50 hover:bg-blue-700 large-desktop:w-170 w-140 focus:shadow-outline focus:outline-none text-white large-desktop:text-font-20 text-font-15`}
          type="submit"
          disabled={isFormValid}
          onClick={() => getTradeInfo(state)}
        >
          {' '}
          VIEW DETAILS
        </button>
        {/* </div> */}
      </div>
    </div>
  )
}
export default Search
