import React, { useContext } from 'react'
import Validator from './schemas'

import { store } from '../src/store'
import errorMessages from './error-messages'
import DatePicker from "react-datepicker";
const moment = require('moment')
//import '../src/react-datepicker.css';


import sortActiveUP from '../src/images/sort_up_active.png'
import sortInActiveDown from '../src/images/sort_down_active.png'
import sortInActiveUP from '../src/images/sort_up_inactive.png'
import sortActiveDown from '../src/images/sort_down_inactive.png'


const LabeledInputField = ({ type, name, label, hideLabel, placeholder, disabled, defaultValue, className, onChange, onClick, register, errors, schema, value, required, labelClassName }) => {
  const { state } = useContext(store);
  let resources = state.Resources;
  let validator = Validator(schema, name);
  return (
    <>
      {!hideLabel &&
        (required ? (
          <label htmlFor={name} className={`text-tiny leading-none font-SamsungOne-400 ml-12 ${labelClassName}`}>
            {resources && resources[label] ? resources[label] : label}
            <span className="text-red-600">*</span>
          </label>
        ) : (
          <label htmlFor={name} className={`text-tiny leading-none font-SamsungOne-400 ml-12 ${labelClassName}`}>
            {resources && resources[label] ? resources[label] : label}
          </label>
        ))}
      <input
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className={`input_box ${className} ${errors[name] ? 'input_box_error' : ''}`}
        onChange={(e) => onChange(e.target.value)}
        ref={register(validator)}
        value={value}
        defaultValue={defaultValue}
      />
      {errors[name] && errors[name].type == 'required' && <p className="error-text">{errorMessages('required', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'minLength' && <p className="error-text">{errorMessages('minLength', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'maxLength' && <p className="error-text">{errorMessages('maxLength', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'numeric' && <p className="error-text">{errorMessages('numeric', label, validator, resources)}</p>}
      {errors[name] && errors[name].type == 'email' && <p className="error-text">{errorMessages('email', label, validator, resources)}</p>}
    </>
  )
}

export const RenderSelectField = ({
  options,
  removeDefaultOption,
  optionsKey,
  optionsValue,
  register,
  onChange,
  label,
  className,
  name,
  required,
  disabled,
  multiple,
  value,
  errors,
  schema,
  hideLabel,
}) => {
  const { state } = useContext(store)
  const resources = state.Resources
  const validator = Validator(schema, name)

  return (
    <>
      {
        !hideLabel && (required ?
          <label className="text-tiny leading-none font-SamsungOne-400 ml-3">{label} <span className="text-red-600">*</span></label>
          : <label className="text-tiny leading-none font-SamsungOne-400 ml-3">{label}</label>)

      }
      <select value={value} name={name} ref={register(validator)} className={className} disabled={disabled} onChange={(e) => onChange(e.target.value)} ref={register(validator)}  >
        {
          !removeDefaultOption && <option value=''>Select</option>
        }
        {
          options.map((option, i) => <option key={i} value={option[optionsKey]}>{option[optionsValue]}</option>)
        }
      </select>
      {errors[name] && errors[name].type == 'required' && <p className="text-error-red error-text pt-2">{errorMessages('required', label, validator, resources)}</p>}
    </>
  )
}


export const SortSelection = ({
  fieldName, sortOrder, onClick, className
}) => {
  return (
    <>
      <span className={`sortArrow ${className}`}>
        <span>
          <img className="pos-abs cursor-pointer" src={sortOrder == "ASC" ? sortActiveUP : sortInActiveUP} onClick={() => onClick('ASC', fieldName)} />
        </span>
        <span className="pt-11">
          <img className="pos-abs cursor-pointer" src={sortOrder == "DESC" ? sortActiveDown : sortInActiveDown} onClick={() => onClick('DESC', fieldName)} />
        </span>
      </span>

    </>
  )
}


export const DateInputField = ({
  value, onChange, placeholder, format, className
}) => {
  const { state } = useContext(store)
  const { resources } = state
  const range = (start, end, step = 1) => {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill().map((_, idx) => start + (idx * step))
  }

  const years = range(1900, new Date().getFullYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const setDate = (date,) => {
    let pDate;
    if (date)
      pDate = moment(date).format('YYYY-MM-DD')
    onChange(pDate)
  }

  return (
    <>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date && date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date && date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        onChange={date => setDate(date)}
        placeholderText={placeholder}
        selected={value ? moment(value, 'YYYY-MM-DD').toDate() : ''}
        dateFormat={format}
        className={className}
        popperPlacement="bottom-end"
      />

    </>
  )
}

export default LabeledInputField
