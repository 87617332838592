import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Popup from 'reactjs-popup'
import htmlToImage from 'html-to-image'
import LabeledInputField, { RenderSelectField } from '../../../utils/custom-fields'
import doneImg from '../../images/ic-done.png'
import failImg from '../../images/ic-close.png'
import editImg from '../../images/ic-edit_default.png'
import crossImg from '../../images/ic-cross.png'
import { useActions } from '../../actions'
import { store } from '../../store'
import JsBarcode from 'jsbarcode'
import Select from 'react-select'

const moment = require('moment-timezone')

const TradeInfo = () => {
  const { state, dispatch } = useContext(store)

  const actions = useActions(state, dispatch)
  const deviceDetails = state && state.initializeData && state.initializeData.data && state.initializeData.data.Masterdata && state.initializeData.data.Masterdata.DeviceDetails
  const devices =
    deviceDetails &&
    deviceDetails
      .filter((item) => item.AssetCatalogName !== 'UNKNOWN')
      .map((item) => ({
        label: item.AssetCatalogName,
        value: item.AssetCatalogId,
      }))
  const tradeInfoResponse = state && state.tradeinfo && state.tradeinfo.data && state.tradeinfo.data.FindTradeInfoResponse
  const tradeInStatus = state && state.tradeinfo && state.tradeinfo.data && state.tradeinfo.data.FindTradeInfoResponse && state.tradeinfo.data.FindTradeInfoResponse.TradeInStatus
  const enableRetriggerEmail = state && state.sendLabelCheck ? true : false
  const noDataMessage =
    state &&
    state.isError &&
    state.ErrorDetails &&
    state.ErrorDetails.response &&
    state.ErrorDetails.response.data &&
    state.ErrorDetails.response.data.message &&
    state.ErrorDetails.response.data.message === 'TradeId not valid'

  const errorMessage =
    state &&
    state.isError &&
    state.ErrorDetails &&
    state.ErrorDetails.response &&
    state.ErrorDetails.response.data &&
    state.ErrorDetails.response.data.message &&
    state.ErrorDetails.response.data.message === 'Resource No Found'

  const customerInfo = state && state.CustomerInfo
  let selectedDevice
  if (customerInfo && customerInfo.DevicePopularName) {
    selectedDevice = devices.find((item) => item.value === customerInfo.DevicePopularName)
  }
  const testsResult = tradeInfoResponse && tradeInfoResponse.DeviceDiagnosticsResult && tradeInfoResponse.DeviceDiagnosticsResult.toUpperCase() === 'PASSED'

  const statesArray = state && state.initializeData && state.initializeData.data && state.initializeData.data.Masterdata && state.initializeData.data.Masterdata.State
  let StatesOptions =
    statesArray &&
    statesArray[0].map((item) => ({
      key: item.StateProvince,
      value: item.StateProvince,
    }))
  const Country = [{ key: 'Australia', value: 'Australia' }]

  const FirstName = customerInfo && customerInfo.FirstName
  const LastName = customerInfo && customerInfo.LastName
  const EmailAddress = customerInfo && customerInfo.EmailAddress
  const MobileNo = customerInfo && customerInfo.MobileNo
  const AddressLine1 = customerInfo && customerInfo.AddressLine1
  const AddressLine2 = customerInfo && customerInfo.AddressLine2
  const AddressLine3 = customerInfo && customerInfo.AddressLine3
  const ZipCode = customerInfo && customerInfo.ZipCode
  let City = customerInfo && customerInfo.City && customerInfo.City.trim()
  let State = customerInfo && customerInfo.State && customerInfo.State.trim()
  const AssetCatalogId = customerInfo && customerInfo.DevicePopularName
  const cityStateCountryData = (customerInfo.states && customerInfo.states && customerInfo.states.GetCityStatebyZipCodeResponse) || []

  const result =
    cityStateCountryData &&
    cityStateCountryData.reduce(
      (agr, item) => {
        item.City && !agr.Cities.includes(item.City) && agr.Cities.push(item.City)
        item.State && !agr.States.includes(item.State) && agr.States.push(item.State)
        !agr.Country ? (agr.Country = item.Country) : ''
        return agr
      },
      { Cities: [], States: [], Country: '' }
    )

  const CitiesOptions = result && result.Cities && result.Cities.map((city) => ({ key: city, value: city }))
  State = result && result.States[0]
  let newStatesOptions =
    result &&
    result.States &&
    result.States.map((state) => {
      return {
        key: state,
        value: state,
      }
    })
  StatesOptions = newStatesOptions && newStatesOptions.length > 0 ? newStatesOptions : StatesOptions

  let isWatch = tradeInfoResponse && tradeInfoResponse.DeviceType && ['W-SINGLE','W-DUAL','W-NONE'].includes(tradeInfoResponse.DeviceType.toUpperCase())
  const { register, errors } = useForm({
    mode: 'onChange',
  })

  const disable = !(state && state.CustomerInfo && state.CustomerInfo.editable)
  const setEditable = (data) => {
    actions.setEdit(data)
  }

  const openPopup = !!(state && state.CustomerInfo && state.CustomerInfo.openPopUp)

  const isMobileView = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const contentStyleForMobileView = {
    padding: '0px',
    alignSelf: 'center',
    width: 'auto',
  }
  const contentStyleForDesktopView = {
    padding: '0px',
    alignSelf: 'center',
    width: 'auto',
  }

  const updateTradeInfo = (state) => {
    const SubmitTradeInfoRequest = {
      CustomerId: tradeInfoResponse.CustomerId,
      FirstName,
      LastName,
      AddressId: tradeInfoResponse.CustomerInformation.AddressId,
      AddressLine1,
      AddressLine2,
      AddressLine3,
      CityName: City,
      StateProvinceCode: State,
      PostalCode: ZipCode,
      PhoneContactPointId: tradeInfoResponse.PhoneContactPointId,
      PhoneNbr: MobileNo,
      TradeInfoId: tradeInfoResponse.TradeInfoId,
      TradeInStatus: 'CONFIRMED',
      PrimaryAssetId: tradeInfoResponse.DeviceList.PrimaryAssetId,
      AssetCatalogId,
      TradeQuoteNbr: tradeInfoResponse.TradeQuoteNbr,
      StoreId: state && state.Login && state.Login.selectedLocation,
      LoginEmailAddress: state && state.Login && state.Login.email,
      PrimaryIMEI: tradeInfoResponse.DeviceList.PrimarySerialNbr,
      UserEmail: EmailAddress,
      TradeInMethod: 'IN-STORE',
    }
    actions.updateTradeInfo({ SubmitTradeInfoRequest })
  }
  const offeredAssetName = tradeInfoResponse && tradeInfoResponse.OfferedAssetName
  const offeredAssetId = tradeInfoResponse && tradeInfoResponse.OfferedAssetId
  const openStatus = tradeInfoResponse && tradeInfoResponse.TradeInStatus && tradeInfoResponse.TradeInStatus.toUpperCase() === 'OPEN'
  const verificationResponse = state && state.verificationStatus && state.verificationStatus.data && state.verificationStatus.data.IMEIVerificationResponse
  let Make = verificationResponse && Object.values(verificationResponse).map((ele) => ele.Make)[0]
  const tradeId = state && state.SearchId && state.SearchId.tradeupid
  const fmipStatusCall = (tradeId) => {
    if (verificationResponse && Make.toUpperCase() == 'APPLE') {
      actions.getFMIPAMTARequest(tradeId)
    }
  }


  let verifiedFMIP =
    verificationResponse &&
    verificationResponse.find((obj) => {
      if (obj.FMIP == 'FAILED' || obj.FMIP == '') {
        return obj
      }
    })
  let verifiedAMTA =
    verificationResponse &&
    verificationResponse.find((obj) => {
      if (obj.AMTA == 'FAILED' || obj.AMTA == '') {
        return obj
      }
    })
  const onlyPrimaryAmta = !!(tradeInfoResponse && tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.PrimaryAmtaStatus && !tradeInfoResponse.DeviceList.SecondaryAmtaStatus)
  let AmtaStatus =
    tradeInfoResponse &&
      tradeInfoResponse.DeviceList &&
      tradeInfoResponse.DeviceList.PrimaryAmtaStatus.toUpperCase() === 'PASSED' &&
      (onlyPrimaryAmta || (tradeInfoResponse.DeviceList.SecondaryAmtaStatus && tradeInfoResponse.DeviceList.SecondaryAmtaStatus.toUpperCase() === 'PASSED'))
      ? 'Passed'
      : 'Failed'

  AmtaStatus = verificationResponse ? (verifiedAMTA ? 'Failed' : 'Passed') : AmtaStatus

  const onlyPrimaryFmip = !!(tradeInfoResponse && tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.PrimaryFmipStatus && !tradeInfoResponse.DeviceList.SecondaryFmipStatus)
  let FmipStatus =
    tradeInfoResponse &&
      tradeInfoResponse.DeviceList &&
      tradeInfoResponse.DeviceList.PrimaryFmipStatus.toUpperCase() === 'PASSED' &&
      (onlyPrimaryFmip || (tradeInfoResponse.DeviceList.SecondaryFmipStatus && tradeInfoResponse.DeviceList.SecondaryFmipStatus.toUpperCase() === 'PASSED'))
      ? 'Passed'
      : 'Failed'
  FmipStatus = verificationResponse && Make.toUpperCase() == 'APPLE' ? (verifiedFMIP ? 'Failed' : 'Passed') : FmipStatus

  const disableFMIP = verificationResponse && Make.toUpperCase() == 'APPLE' ? (verifiedFMIP && verifiedFMIP.FMIP === 'FAILED' ? true : false) : false
  const submitSuccess = state && state.updateTradeResponse && state.updateTradeResponse.data && state.updateTradeResponse.data.SubmitTradeInfoResult
  let isFormValid = false
  const errorLength = Object.keys(errors).length
  isFormValid = errorLength > 0 ? false : true
  const disableConfirm =
    state && state.CustomerInfo
      ? !(
        state.CustomerInfo.DeviceImeiCheck &&
        state.CustomerInfo.DeviceModelCheck &&
        state.CustomerInfo.CustomerInfoCheck &&
        state.CustomerInfo.SetPhysicalResultsCheck &&
        state.CustomerInfo.DevicePopularName &&
        AmtaStatus === 'Passed' && !disableFMIP &&
        isFormValid
      )
      : false

  const border = '3px solid black'
  const saveImage = () => {
    htmlToImage.toJpeg(document.getElementById('printableArea'), { backgroundColor: 'white' }, { Color: 'red' }, { borderWidth: '3px' }, { borderStyle: 'solid' }, { border }).then((dataUrl) => {
      const link = document.createElement('a')
      link.download = 'Barcode.png'
      link.href = dataUrl
      link.click()
    })
  }

  const barcodeCanvas = (text) => {
    let canvas = document.createElement('canvas')
    JsBarcode(canvas, text, {
      format: 'CODE39',
      width: 2,
      height: 40,
      textPosition: 'top',
      margin: 0,
    })
    return canvas.toDataURL('image/jpeg')
  }
  return (
    <div className="large-desktop:min-h-885 tablet:min-h-772">
      {!tradeInfoResponse && <div className="laptop:min-h-546 tablet:h-618  font-SamsungOne-400 text-red-600">{noDataMessage ? <div className="text-center text-lg">No Data Found</div> : null}</div>}
      {tradeInfoResponse && (
        <div>
          <div className='flex flex-row font-SamsungOne-400 my-30 h-772 large-desktop:min-h-772 laptop:h-600 tablet:h-630'>
            <div className={`${!openStatus ? 'opacity-50 cursor-not-allowed' : ''} flex flex-col w-530 large-desktop:w-550`}>
              <div className=" large-desktop:mb-30  mb-15 justify-between items-center w-530  large-desktop:w-550 border box-border border-solid border-gray-500 rounded-lg font-SamsungOne-400 ">
                <p className="text-font-20 font-bold leading-6 large-desktop:mt-20 mt-10 ml-30 large-desktop:mb-15"> Device IMEI Summary </p>
                <div className="flex flex-row  text-base mb-20">
                  <div className="ml-30 mt-4">
                    <label className={`${openStatus ? '' : 'cursor-not-allowed'} checkbox-container`}>
                      <input type="checkbox" name="deviceimeicheck" disabled={!openStatus} onClick={(e) => actions.setDeviceImeiCheck(e.target.checked)} />
                      <span className="checkbox-checkmark" />
                    </label>
                    <span className="text-font-15 leading-4">Device IMEI/Serial No.</span>
                    <p className="text-font-17 font-bold ml-30 mt-2 leading-5">{(tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.PrimarySerialNbr) || '-'}</p>
                  </div>
                  <div className="ml-60 mt-4 ">
                    <label className={`${openStatus ? '' : 'cursor-not-allowed'} checkbox-container`}>
                      <input type="checkbox" name="devicemodelcheck" disabled={!openStatus} onClick={(e) => actions.setDeviceModelCheck(e.target.checked)} />
                      <span className="checkbox-checkmark" />
                    </label>
                    <span className="text-font-15 leading-4">Device Model </span>
                    <p className="text-font-17 font-bold ml-30 mt-2 leading-5">{tradeInfoResponse.AssetCatalogName || '-'}</p>
                  </div>
                </div>
                {tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.SecondarySerialNbr ? (
                  <div className="flex flex-row  text-base mb-20">
                    <div className="ml-30 mt-4">
                      <label className={`${openStatus ? '' : 'cursor-not-allowed'} checkbox-container`}>
                        <input type="checkbox" name="deviceimeicheck" disabled={!openStatus} onClick={(e) => actions.setDeviceImeiCheck(e.target.checked)} />
                        <span className="checkbox-checkmark" />
                      </label>
                      <span className="text-font-15 leading-4">Device IMEI/Serial No. </span>
                      <p className="text-font-17 font-bold ml-30 mt-2 leading-5">{(tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.SecondarySerialNbr) || '-'}</p>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="border border-solid border-gray-500 rounded-lg large-desktop:w-550 w-530 large-desktop:h-610 h-490 ">
                <div className="ml-30 mr-30">
                  <p className="text-font-17  large-desktop:text-xl font-bold leading-6 large-desktop:mt-30 mt-15 large-desktop:mb-20 mb-10">
                    Overall Assessment Summary
                    {testsResult ? (
                      <span className="ml-5 text-blue-400 float-right">Good Working Order</span>
                    ) : (
                        <span className="ml-5 text-font-17 large-desktop:text-lg text-blue-400 float-right">Non Good Working Order</span>
                      )}
                  </p>
                  <div className="flex flex-row justify-between items-center mx-auto mt-5">
                    <div className="border border-solid border-blue-400 rounded text-center w-150 h-16">
                      <p className="text-sm font-bold mt-8 ">Trade ID</p>
                      <p className="text-lg font-bold text-orange-1">{tradeInfoResponse.TradeQuoteNbr}</p>
                    </div>
                    <div className="border border-solid border-blue-400 rounded text-center  w-150 h-16  ml-5">
                      <p className="text-sm font-bold mt-8 ">AMTA Results</p>
                      <p className="text-lg font-bold text-orange-1">{AmtaStatus}</p>
                    </div>
                    <div className="border border-solid border-blue-400 rounded text-center mr-5  w-150 h-16 ml-5">
                      <p className="text-sm font-bold mt-8 ">{verificationResponse && Make.toUpperCase() === 'APPLE' ? 'FMIP Lock Results' : 'O/S Lock Results'}</p>
                      <p className="text-lg font-bold text-orange-1 cursor-pointer" onClick={() => fmipStatusCall(tradeId)}>{FmipStatus}</p>
                    </div>
                  </div>
                  <div className="flex flex-row text-sm font-SamsungOne-400 large-desktop:my-30 my-20 ">
                   { !isWatch && <div className="w-1/2" name="functional">
                      <p className="text-base font-bold leading-5 block">Functional Results</p>
                      {tradeInfoResponse.DiagnosticTest.filter((test) => test.DiagnosticTestType.toUpperCase() === 'FUNCTIONALITY').map((test, i) => (
                        <div key={i} className="flex flex-row large-desktop:mt-10 mt-5">
                          <img className="h-5 object-none object-top large-desktop:mr-3 mr-1" alt="" src={test.DiagnosticTestResult.toUpperCase() === 'PASSED' ? doneImg : failImg} my-auto />
                          <div className="flex">
                            <p className="w-210 inline-block SamsungOne-400 text-sm leading-17">{test.DiagnosticTestName}</p>
                          </div>
                        </div>
                      ))}
                    </div>}
                    <div className={`${isWatch ?'':'w-1/2'}  mb-4`} name="physical">
                      <label className={`${openStatus ? '' : 'cursor-not-allowed'} checkbox-container`}>
                        <input type="checkbox" name="physicalresultscheck" disabled={!openStatus} onClick={(e) => actions.setPhysicalResultsCheck(e.target.checked)} />
                        <span className="checkbox-checkmark" />
                      </label>
                      <span className="font-bold text-base leading-5 mt-1 ">Physical Results</span>
                      {isWatch &&
                      <div className = 'grid grid-cols-2 gap-2'>
                        { tradeInfoResponse.DiagnosticTest.filter((test) => test.DiagnosticTestType.toUpperCase() === 'PHYSICAL').map((test, i) => (
                        <div key={i} className="flex flex-row large-desktop:mt-10 mt-5">
                          <img className="h-5 object-none object-top large-desktop:mr-3 mr-1" alt="" src={test.DiagnosticTestResult.toUpperCase() === 'PASSED' ? doneImg : failImg} />
                          <div className="flex">
                            <p className="w-210 inline-block">{test.DiagnosticTestName}</p>
                          </div>
                        </div>
                      ))}
                      </div>}
                      {!isWatch && tradeInfoResponse.DiagnosticTest.filter((test) => test.DiagnosticTestType.toUpperCase() === 'PHYSICAL').map((test, i) => (
                        <div key={i} className="flex flex-row large-desktop:mt-10 mt-5">
                          <img className="h-5 object-none object-top large-desktop:mr-3 mr-1" alt="" src={test.DiagnosticTestResult.toUpperCase() === 'PASSED' ? doneImg : failImg} />
                          <div className="flex">
                            <p className="w-210 inline-block">{test.DiagnosticTestName}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={`${!openStatus ? 'opacity-50 cursor-not-allowed' : ''} flex flex-row ml-20 large-desktop:ml-30`}>
                <div className="w-340 large-desktop:w-450 large-desktop:h-h-140 h-h-100 border border-solid border-gray-500 rounded-lg mr-20 ">
                  <p className="text-font-17  large-desktop:text-2xl font-bold large-desktop:ml-30 ml-20 large-desktop:mt-20 mt-10">Device Offered</p>
                  <div className="flex flex-row text-sm leading-4 large-desktop:ml-30 ml-20 mr-30 mt-2">
                    <div className="flex flex-col w-full">
                      {/* <label className="text-tiny leading-none font-SamsungOne-400 ml-3">Device Make <span className="text-red-600">*</span></label> */}
                      {openStatus ? (
                        <Select
                          className={`${openStatus ? '' : 'cursor-not-allowed'} input mt-8 text-gray-700 leading-tight`}
                          classNamePrefix="select"
                          isDisabled={!openStatus}
                          isSearchable={true}
                          name="devicepopularname"
                          placeholder="Select Device Name"
                          options={devices}
                          onChange={actions.setDevicePopularName}
                        />
                      ) : (
                          <Select
                            className={`${openStatus ? '' : 'cursor-not-allowed'} input mt-8 text-gray-700 leading-tight`}
                            classNamePrefix="select"
                            isDisabled={!openStatus}
                            isSearchable={true}
                            name="devicepopularname"
                            value={{ label: offeredAssetName, value: offeredAssetId }}
                            placeholder="Select Device Name"
                            options={devices}
                            onChange={actions.setDevicePopularName}
                          />
                        )}
                    </div>
                  </div>
                </div>
                <div className="border border-solid border-gray-500 rounded-lg large-desktop:h-h-140 h-h-100 w-310 large-desktop:w-370">
                  <p className="text-font-17  large-desktop:text-2xl font-bold leading-7 large-desktop:ml-30 ml-20 large-desktop:mt-27 mt-20"> Order Value </p>
                  <div className="large-desktop:block large-desktop:ml-30 ml-20 large-desktop:mb-27 mb-21 large-desktop:mt-18">
                    <span className="font-bold  text-font-15  large-desktop:text-base">{testsResult ? 'Good Working Order Value' : 'Non Good Working Order Value'}</span>
                    <span className="font-bold text-orange-1 ml-10 text-2xl ">{`$ ${tradeInfoResponse.FinalPrice}`}</span>
                  </div>
                </div>
              </div>
              <div className={`${!openStatus ? 'opacity-50 cursor-not-allowed' : ''} flex flex-row customer-info ml-20 w-670 large-desktop:ml-30 large-desktop:w-840 large-desktop:mt-30 mt-15`} >
                <div className="border border-solid border-gray-500 rounded-lg  w-670 large-desktop:w-840 ">
                  <div className="large-desktop:mt-22 mt-22 large-desktop:ml-39 large-desktop:mr-41 mx-20">
                    <label className={`${openStatus ? '' : 'cursor-not-allowed'} checkbox-container`}>
                      <input type="checkbox" name="customerinfocheck" disabled={!openStatus} onClick={(e) => actions.setCustomerInfoCheck(e.target.checked)} />
                      <span className="checkbox-checkmark" />
                    </label>
                    <span className="text-font-17  large-desktop:text-2xl font-bold text-bold leading-7">Customer Information</span>
                    {openStatus ? <img className="inline-block float-right visible " alt="" src={editImg} onClick={() => setEditable(true)} /> : null}
                  </div>
                  <form className="text-sm leading-4 ml-20 mr-20 large-desktop:ml-39 large-desktop:mr-41 large-desktop:mt-20 mt-10">
                    <div className="flex flex-row ">
                      <div className="flex flex-col  w-1/2 mr-15 large-desktop:mr-20">
                        <LabeledInputField
                          type="text"
                          name="FirstName"
                          labelClass="mr-3 "
                          label="First Name"
                          placeholder="John"
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5  border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setFirstName}
                          schema="customerinfo"
                          required
                          disabled={disable}
                          defaultValue={FirstName}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 ">
                        <LabeledInputField
                          type="text"
                          name="lastname"
                          label="Last Name"
                          labelClass="mr-3"
                          placeholder="Doe"
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setLastName}
                          schema="customerinfo"
                          disabled={disable}
                          required={true}
                          defaultValue={LastName}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row mt-22">
                      <div className="flex flex-col w-1/2  mr-15 large-desktop:mr-20">
                        <LabeledInputField
                          type="text"
                          name="mobileno"
                          label="Mobile No."
                          labelClass="mr-3"
                          placeholder="+6169009887"
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setMobileNo}
                          schema="customerinfo"
                          disabled={disable}
                          required={true}
                          defaultValue={MobileNo}
                        />
                      </div>
                      <div className="flex flex-col w-1/2  ">
                        <LabeledInputField
                          type="text"
                          name="emailaddress"
                          label="Email Address"
                          labelClass="mr-3"
                          placeholder="JohnDoe@gmail.com"
                          className="mt-5 border rounded border-solid border-gray-400 bg-gray-300 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          register={register}
                          errors={errors}
                          schema="customerinfo"
                          disabled={true}
                          required={true}
                          defaultValue={EmailAddress}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row mt-22">
                      <div className="flex flex-col w-1/2 ">
                        <LabeledInputField
                          type="text"
                          name="addressline1"
                          label="Address Line 1"
                          labelClass="mr-3"
                          placeholder="33 Fairview Street"
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5  border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setAddLine1}
                          schema="customerinfo"
                          disabled={disable}
                          required={true}
                          defaultValue={AddressLine1}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 mx-15 large-desktop:ml-20 large-desktop:mr-20 ">
                        <LabeledInputField
                          type="text"
                          name="addressline2"
                          label="Address Line 2"
                          labelClass="mr-3"
                          placeholder=""
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setAddLine2}
                          schema="customerinfo"
                          disabled={disable}
                          defaultValue={AddressLine2}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 ">
                        <LabeledInputField
                          type="text"
                          name="addressline3"
                          label="Address Line 3"
                          labelClass="mr-3"
                          placeholder=""
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.setAddLine3}
                          schema="customerinfo"
                          disabled={disable}
                          defaultValue={AddressLine3}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row mt-22">
                      <div className="flex flex-col w-1/2  ">
                        <LabeledInputField
                          type="text"
                          name="zipcode"
                          label="Zip/Postal Code"
                          labelClass="mr-3"
                          placeholder="3260"
                          className={`${disable ? 'bg-gray-300' : ''
                            } mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          register={register}
                          errors={errors}
                          onChange={actions.getCityStateByZipcode}
                          schema="customerinfo"
                          disabled={disable}
                          required={true}
                          defaultValue={ZipCode}
                        />
                        {errorMessage ? <div className="error-text">Invalid ZipCode</div> : null}
                      </div>
                      {cityStateCountryData && cityStateCountryData.length >= 1 ? (
                        <div className="flex flex-col w-1/2  mx-15 large-desktop:mx-20 ">
                          <RenderSelectField
                            name="city"
                            label="City"
                            labelClass="mr-3"
                            className={`${disable || errorMessage ? 'bg-gray-300' : ''
                              } mt-5  border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                            value={errorMessage ? ' ' : City}
                            options={CitiesOptions}
                            optionsKey="key"
                            optionsValue="value"
                            register={register}
                            errors={errors}
                            onChange={actions.setCity}
                            schema="customerinfo"
                            disabled={disable}
                            required={true}
                            defaultValue={City}
                          />
                        </div>
                      ) : (
                          <div className="flex flex-col w-1/2  mx:15 large-desktop:mx-20 ">
                            <LabeledInputField
                              type="text"
                              name="city"
                              label="City"
                              labelClass="mr-3"
                              className="bg-gray-300 mt-2  border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              register={register}
                              errors={errors}
                              onChange={actions.setCity}
                              schema="customerinfo"
                              disabled={true}
                              required={true}
                              defaultValue={errorMessage ? ' ' : City}
                            />
                          </div>
                        )}
                      <div className="flex flex-col w-1/2 ">
                        <RenderSelectField
                          name="state"
                          label="State/Provision/Region"
                          labelClass="mr-3"
                          className={`${disable || errorMessage ? 'bg-gray-300' : ''
                            } mt-5  border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          // placeholder="Victoria"
                          value={errorMessage ? ' ' : State}
                          options={StatesOptions}
                          optionsKey="key"
                          optionsValue="value"
                          register={register}
                          errors={errors}
                          onChange={actions.setState}
                          schema="customerinfo"
                          disabled={errorMessage || disable}
                          required
                          defaultValue={State}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row mt-22 large-desktop:mb-39 mb-15">
                      <div className="flex flex-col w-1/2 mr-0">
                        <RenderSelectField
                          name="country"
                          label="Country"
                          labelClass="mr-3"
                          className="bg-gray-300 mt-5 border rounded border-solid border-gray-400 shadow appearance-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          // placeholder="Australia"
                          value="Australia"
                          options={Country}
                          optionsKey="key"
                          optionsValue="value"
                          register={register}
                          errors={errors}
                          onChange={actions.setCountry}
                          schema="customerinfo"
                          disabled={true}
                          required={true}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 ml-0 ml:15 mr:15 large-desktop:ml-20 large-desktop:mr-20 " />
                      <div className="flex flex-col w-1/2  mr-2 large-desktop:mr-0" />
                    </div>
                  </form>
                </div>
              </div>
              {tradeInStatus !== 'OPEN' &&
                <div className="flex flex-col w-340 large-desktop:w-450 large-desktop:h-h-100 h-h-100 border border-solid border-gray-500 rounded-lg large-desktop:mt-20 mt-15 ml-20 large-desktop:ml-30 opacity-100">
                  <div className="flex flex-col ">
                    <div className="mt-10 ml-30">
                      <label className="checkbox-container">
                        <input type="checkbox" id="retrigger-email" name="sendLabelCheck"
                          onChange={(e) => actions.setSendLabelCheck(e.target.checked)}
                        />
                        <span className={`checkbox-checkmark`} />
                      </label>
                      <span className="text-font-17  large-desktop:text-2xl font-bold text-bold leading-7 text-span">
                        Retrigger Confirmation Email
                    </span>
                    </div>
                  </div>
                  <div className="mt-12">
                    <button
                      disabled={!enableRetriggerEmail}
                      className={`${!enableRetriggerEmail ? 'cursonr-not-allowed opacity-25' : ''} items-center h-36 large-desktop:h-36 hover:bg-blue-700 large-desktop:w-328 ml-50 w-250 focus:shadow-outline focus:outline-none text-white large-desktop:text-font-20 text-font-15 text-transform: capitalize`}
                      onClick={() => actions.sendEmailLabel(tradeInfoResponse && tradeInfoResponse.TradeQuoteNbr)}
                    >Retrigger Confirmation Email</button>
                  </div>
                  {state && state.EmailLabelSuccess && <p className="text-17 leading-28 p-4 text-green-600">Confirmation email sent successfully.</p>}
                </div>
              }
              {openStatus ? (
                <div className="flex flex-row float-right large-desktop:mt-30 mt-14">
                  <button
                    className={`${disableConfirm ? 'disabled-btn' : ''} hover:bg-blue-700 float-right focus:shadow-outline focus:outline-none text-white py-2 px-4 large-desktop:mb-0 mb-41`}
                    type="submit"
                    disabled={disableConfirm}
                    onClick={() => actions.setOpenPopUp(true)}
                  >
                    CONFIRM
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          {openPopup && (
            <Popup
              contentStyle={isMobileView ? contentStyleForMobileView : contentStyleForDesktopView}
              open={openPopup}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              repositionOnResize={false}
              onClose={() => actions.setOpenPopUp(false)}
              modal
            >
              <div className={`${submitSuccess ? 'print-popup' : ''} modal-pop-up large-desktop:w-450 w-400 large-desktop:h-530`}>
                <div className="header">
                  <div id="cross-img" className="flex flex-row-reverse">
                    <img
                      className=" mt-10 mb-10 mr-10"
                      src={crossImg}
                      onClick={() => {
                        submitSuccess ? actions.setClosePopUp(true) : actions.setOpenPopUp(false)
                      }}
                      alt=""
                    />
                  </div>
                  {!submitSuccess ? <div className="my-15">Confirm Order</div> : ''}
                </div>

                {!submitSuccess ? (
                  <div className="content large-desktop:mt-30 ">
                    <div className="flex flex-row mt-20 large-desktop:m-m-56 mx-31 ">
                      <div className="flex flex-col  w-120 mr-18  my-15">Device IMEI No.</div>
                      <div className="flex flex-col w-120 my-15 font-bold">{tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.PrimarySerialNbr}</div>
                    </div>
                    <div className="flex flex-row mt-20 large-desktop:m-m-56 mx-31">
                      <div className="flex flex-col  w-120 mr-18 ">Device Name</div>
                      <div className="flex flex-col  font-bold">{selectedDevice.label}</div>
                    </div>
                    <div className="flex flex-row mt-20 large-desktop:m-m-56 mx-31">
                      <div className="flex flex-col  w-120 mr-18 mt-15">Order Value</div>
                      <div className="flex flex-col w-120 mt-15 font-bold">{`$ ${tradeInfoResponse.FinalPrice}`}</div>
                    </div>
                  </div>
                ) : (
                    <div className="content flex" id="printableArea">
                      <div className="flex flex-col ml-30">
                        <div className="mx-8">
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Date</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">{moment().format('D MMMM YYYY')}</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Time</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">{moment().format('h:mm:ss a')}</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Vendor</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">Asurion</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Consumer Type</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">Trade Up</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Trade ID</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">{tradeInfoResponse.TradeQuoteNbr}</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">IMEI</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">{(tradeInfoResponse.DeviceList && tradeInfoResponse.DeviceList.PrimarySerialNbr) || '-'}</div>
                          </div>
                          <div className="flex flex-row mt-20">
                            <div className="flex flex-col  w-1/2 mt-5">Make/Model/Capacity</div>
                            <div className="flex flex-col w-1/2 mt-5 font-bold">{tradeInfoResponse.AssetCatalogName || '-'}</div>
                          </div>
                        </div>
                        <div>
                          <img id="barcode" className="flex flex-row mt-20 mb-10 w-340" src={barcodeCanvas(submitSuccess.RedemptionOrderNbr)} alt="" />
                        </div>
                      </div>
                    </div>
                  )}

                {!submitSuccess ? (
                  <div className="actions mt-20 large-desktop:mx-85  mx-60">
                    <button
                      className="bg-grey-1 focus:shadow-outline focus:outline-none text-white large-desktop:mt-30 large-desktop:mb-49 mb-47 mt-20 mr-28 py-2 px-4"
                      onClick={() => actions.setOpenPopUp(false)}
                    >
                      cancel{' '}
                    </button>
                    <button
                      className="hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white large-desktop:mt-30 large-desktop:mb-49 mb-47 mt-20 py-2 px-4"
                      onClick={() => updateTradeInfo(state)}
                    >
                      submit{' '}
                    </button>
                  </div>
                ) : (
                    <div className="actions mt-20 ">
                      <button id="savebtn" onClick={() => saveImage()} className="hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white mr-20 py-2 px-4">
                        save{' '}
                      </button>
                      <button id="printbtn" className="hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white py-2 px-4" onClick={() => window.print()}>
                        print{' '}
                      </button>
                    </div>
                  )}
              </div>
            </Popup>
          )}
        </div>
      )}
    </div>
  )
}
export default TradeInfo
