import React, { useContext } from 'react'
import LabeledInputField, { RenderSelectField } from '../../../utils/custom-fields'
import { useForm } from 'react-hook-form'
import { useActions } from '../../actions'
import { store } from '../../store'
import logo from '../../images/img-Samsung.png'
import mobileImage1 from '../../images/img-tradeup1.png'
import mobileImage from '../../images/img-tradeup.png'
// import footerLogo from '../../images/Combined Shape.png'
import { useHistory } from 'react-router-dom'

const ChangePass = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {},
  })
  const { isValid } = formState
  const { Login,forgotPasswordSuccessResponse,confirmPasswordError } = state
  const status = forgotPasswordSuccessResponse && forgotPasswordSuccessResponse.Status
  const confirmPassStatus = confirmPasswordError && confirmPasswordError.Status
  const confirmPassFailureMsg = confirmPassStatus ? confirmPasswordError && confirmPasswordError.Error : ''
  const invalidPassword = Login && Login.invalidPassword
  const newPassword = Login && Login.newPassword
  const currentPassword = Login && Login.currentPassword
  const confirmNewPassword = Login && Login.confirmNewPassword
  const passwordStatus = Login && Login.Status
  const CognitoError = Login && Login.CognitoError
  const isSubmitted = state && state.isSubmitted
  const verificationCode = Login && Login.verificationCode

  let ErrorMessage = ''

  if (CognitoError && CognitoError.includes('Unable to find agent with Mail id', 0)) {
    ErrorMessage = 'Please contact your IT Administrator'
  } else {
    ErrorMessage = CognitoError
  }
  if (passwordStatus === 'success') {
    history.replace('/confirmorder')
  }
  const changePassword = () => {

    // var CryptoJS = require("crypto-js");
    // var encryptedNewPassword = CryptoJS.AES.encrypt(Login && Login.newPassword, process.env.SECURITY_KEY).toString();

    // var encryptedCurrentPassword = CryptoJS.AES.encrypt(Login && Login.currentPassword, process.env.SECURITY_KEY).toString();

    if (status && status === 'Success') {
      const data = {
        username: Login && Login.emailId,
        verificationcode: Login && Login.verificationCode,
        newpassword: Login && Login?.newPassword,
      }
      if (newPassword === confirmNewPassword && verificationCode !== undefined) {
        actions.confirmPassword(data)
      } else {
        actions.setInvalidPassword(true)
      }
    }else{
      const data = {
        username: Login && Login?.email,
        newpassword: Login && Login?.newPassword,
        oldpassword: Login && Login?.currentPassword,
      }
      if (newPassword === confirmNewPassword && newPassword !== currentPassword) {
        actions.changePassword(data)
      } else {
        actions.setInvalidPassword()
      }
    }
  }
  return (
    <div className="bg-white">
      <div className="inline-block large-desktop:min-h-960 laptop:h-608 tablet:h-680 mt-60 large-desktop:max-w-w-content w-4/6 tablet:w-70">
        <div className="m-mr-header my-10">
          <img className="h-5 large-desktop:h-h-30" src={logo} alt="" />
        </div>
        <div className="flex flex-row justify-center mt-80 mx-100 my-30 large-desktop:mr-485 mt-45">
         <div className="mr-100">
            <p className="text-24 leading-28 font-Samsung-Sharp-Sans-Bold mb-70">How to reset your password</p>
            <p className="text-26 leading-28 font-Samsung-Sharp-Sans-Bold mt-20">Password requirements</p>
            <p className="text-24 leading-28 font-SamsungOne-400 mt-20">At least one lower case letter [a-z]</p>
            <p className="text-24 leading-28 font-SamsungOne-400 ">At least one upper case letter [A-Z]</p>
            <p className="text-24 leading-28 font-SamsungOne-400 ">At least one numeral [0-9]</p>
            <p className="text-24 leading-28 font-SamsungOne-400 ">At least one symbol [@!#$%&]</p>
            <p className="text-24 leading-28 font-SamsungOne-400 ">Minimum of 8 characters</p>
          </div>
          < div className = "" >
          < p className = "text-24 leading-28 font-Samsung-Sharp-Sans-Bold mb-30" > Change your password </p>
          <div className="flex flex-col justify-center mb-20">
          { status && status === 'Success' ?
                <LabeledInputField
                name="verificationCode"
                type="text"
                labelClassName = "w-170 my-auto text-right"
                className = "inline-block p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
                //label = "Verification Code"
                placeholder = "Verification Code"
                //className="mt-10 w-300 h-50"
                onChange={actions.setVerificationCode}
                register={register}
                errors={errors}
                schema="verification"
                defaultValue={Login && Login.verificationCode}
              /> :
            <LabeledInputField
              name="currentPassword"
              label="Current Password"
              hideLabel={true}
              labelClassName="w-170 my-auto text-right"
              className="inline-block p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
              placeholder="Current Password"
              register={register}
              errors={errors}
              type="password"
              onChange={actions.setCurrentPassword}
              schema="login"
              defaultValue={Login && Login.currentPassword}
            />}
          </div>
          <div className="flex flex-col justify-center mb-20">
            <LabeledInputField
              name="newPassword"
              label="New Password"
              type="password"
              hideLabel={true}
              labelClassName="w-170 my-auto text-right"
              className="inline-block p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
              placeholder="New Password"
              register={register}
              errors={errors}
              onChange={actions.setNewPassword}
              schema="login"
              defaultValue={Login && Login.newPassword}
            />
          </div>
          <div className="flex flex-col justify-center mb-20">
            <LabeledInputField
              name="confirmNewPassword"
              label="Re-type new Password"
              type="password"
              hideLabel={true}
              labelClassName="w-170 my-auto text-right"
              className="inline-block p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
              placeholder="Confirm New Password"
              register={register}
              errors={errors}
              onChange={actions.setConfirmNewPassword}
              schema="login"
              defaultValue={Login && Login.confirmNewPassword}
            />
          </div>
          {
            (invalidPassword || passwordStatus === 'failure' || confirmPassStatus === 'failure' || confirmPassStatus === 'account_lock') && isSubmitted && (
            <div className="flex flex-row mb-20">
              <p className="error-text">
                {newPassword !== confirmNewPassword
                  ? 'Password is not matching'
                  : newPassword === currentPassword
                  ? 'New password can not be same as current password'
                  : passwordStatus === 'failure'
                  ? ErrorMessage
                    : confirmPassFailureMsg ?
                    confirmPassStatus === 'account_lock' ? "Too many failed attempts. Your account has been locked, please try again in 10 minutes." : confirmPassFailureMsg :
                    ''
                  }
              </p>
            </div>
          )}
          <div className="flex flex-row justify-center mb-20 self-start ">
            <button
              disabled={invalidPassword || !isValid}
              onClick={() => changePassword()}
              type="submit"
              className={` ${
                invalidPassword || !isValid ? 'disabled-btn' : ''
              } btn w-170 bg-blue-500 justify-center font-SamsungOne-400 not-italic font-bold text-xl leading-tight flex items-center text-center uppercase text-white`}
            >
              Save Changes
            </button>
          </div>
          </div>
        </div>
        {/* <div className="fixed bottom">
          <div className="inline-block m-mr-footer">
            <img className="h-5 large-desktop:h-h-50" src={footerLogo} alt="" />
          </div>
        </div> */}
      </div>
      <div className="large-desktop:inline-block large-desktop:max-w-w-image float-right large-desktop:m-mr-image-right m:0 tablet:w-30 hidden">
        <img className="tablet:mr-0 mr-100" src={mobileImage1} alt="" />
      </div>
      <div className="inline-block large-desktop:max-w-w-image float-right large-desktop:m-mr-image-right m:0 tablet:w-30 mr-30 large-desktop:hidden">
        <img className="laptop:h-728 tablet:mr-0 mr-100" src={mobileImage} alt="" />
      </div>
    </div>
  )
}
export default ChangePass
