import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, SortSelection, DateInputField } from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
// import searchList from '../../dummyjson/searchException.json'
import ExceptionDetails from './exception-details'

const searhException = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })
  let { selectedPage, SearchException,exceptionList, masterData, Login } = state
  const partnerStoreAgentId = Login && Login.AgentId
  useEffect(() => {
    searchException()
  }, [selectedPage])

  const searchException = (isSearch = false) => {
    if (isSearch) {
      actions.setSelectedPage(0);
    }
    let GetExceptionRequest = {
      pageNumber: isSearch ? 1 : selectedPage + 1,
      pageSize: "10",
      tradeId: SearchException && SearchException.tradeId ? SearchException.tradeId : "",
      startDate: SearchException && SearchException.startDate ? SearchException.startDate : "",
      endDate: SearchException && SearchException.endDate ? SearchException.endDate : "",
      escalationCode: SearchException && SearchException.escalationCode ? SearchException.escalationCode : "",
      agentId : partnerStoreAgentId
    }
    actions.getExceptionDetails({ GetExceptionRequest })
  }

  const escalationCodes = []
  masterData && masterData.TradeInEscalationType && masterData.TradeInEscalationType.map((data) => {
    escalationCodes.push({
      label: data.TradeInEscalationName,
      value: data.TradeInEscalationName
    })
  })

  const setEscalationCode = (e) => {
    actions.setEscalationCode(e)
  }
  const resetFields = () => {
    actions.setExceptionTradeId(undefined);
    actions.setExceptionStartDate(undefined);
    actions.setExceptionEndDate(undefined);
    actions.setEscalationCode(undefined);
    if (selectedPage == 0) {
      let GetExceptionRequest = {
        pageNumber: "1",
        pageSize: "10",
        tradeId: "",
        startDate: "",
        endDate: "",
        escalationCode: "",
        agentId : partnerStoreAgentId
      }
      actions.getExceptionDetails({ GetExceptionRequest })
    }
    actions.setSelectedPage(0);
    setValue("TradeID", "")
    setValue("StartDate", "")
    setValue("EndDate", "")
    setValue("EscaltionCode", "")
  }
  return (
    <div>
      <div className="ml-100 mr-50 min-h-772 mb-100 font-VodafoneRg">
        <div className="flex items-center pt-30">
          <h2 className="text-4xl font-bold leading-37 p-1 font-Samsung-Sharp-Sans-Bold">Exceptions</h2>
        </div>
        <div className="flex w-full items-center">
          <h2 className="text-lg font-Samsung-Sharp-Sans-Bold pt-30 pl-3">Search</h2>
        </div>
        <div className="flex align-items-center">
          <LabeledInputField
            className="order-no-input report-search-font"
            type="text"
            name="TradeID"
            schema="logistics"
            placeholder="Trade ID"
            onChange={actions.setExceptionTradeId}
            register={register}
            errors={errors}
            hideLabel
          />
          <DateInputField
            className="email-audit-date-input report-search-font"
            onChange={actions.setExceptionStartDate}
            placeholder="Start Date"
            value={SearchException && SearchException.startDate ? SearchException.startDate : ''}
            format="dd-MM-yyyy"
          />
          <DateInputField
            className="email-audit-date-input report-search-font"
            onChange={actions.setExceptionEndDate}
            placeholder="End Date"
            value={SearchException && SearchException.endDate ? SearchException.endDate : ''}
            format="dd-MM-yyyy"
          />
          {/* <LabeledInputField
                  className="order-no-input search-font"
                  type="text"
                  placeholder="Make"
                  name="EscaltionCode"
                  schema="logistics"
                  placeholder="EXCEPTIONS_LBL_ESCALATION_CODE"
                  onChange={actions.setEscalationCode}
                  register={register}
                  errors={errors}
                  hideLabel
                /> */}
          <RenderSelectField
            name="exceptionList"
            label=""
            className="select_box_exceptions"
            register={register}
            onChange={(e) => setEscalationCode(e)}
            options={escalationCodes}
            optionsKey="value"
            optionsValue="label"
            errors={errors}
            schema="logistics"
            value={SearchException ? SearchException.escalationCode : ''}
          />

          <button className="purple-button w-80 h-8 search-font" onClick={() => searchException(true)}>
            Search
          </button>
          <button className="black-button w-80 h-8 search-font ml-10" onClick={() => resetFields()}>
            Reset
          </button>
        </div>
         { exceptionList && exceptionList.length > 0 ? 
         <ExceptionDetails /> : <div className="mt-25">No Records Found</div>
        }

        {/* <ExceptionDetails /> */}
      </div>
    </div>
  )
}

export default searhException