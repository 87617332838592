import React, { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import helpLogo from '../../images/ic-help_default.png'
import imgAsurion from '../../images/img-asurion.png'
const moment = require('moment')
import { store } from '../../store'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useActions } from '../../actions'

const Header = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { StoreAgentName } = state.Login

  const {
    storeName,
    masterdata,
    TradeInfoSearched,
    resources,
    OrderTab,
    ReportsTab,
    ExceptionsTab,
    Login,
    LogisticsTab,
    LowValueDeviceTab,
    PartnerStoreCodeNbr,
    partnerStoreName,
    roles,
    workingAs,
    disableStoreSelection,
    logisticsBatchData,
    TradeDetailsTab,
  } = state
  const { PartnerStoreCode, PartnerParentStoreCode, StoreAgentRole } = Login
  const stores = masterdata && masterdata.Stores

  const { register, errors, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      PartnerStoreCodeNbr: state && state.PartnerStoreCodeNbr,
    },
  })

  let defaultPartnerStoreCode = PartnerStoreCode
  const tabRoles = roles && roles.filter((r) => r.menu === 'Header' && r.section === 'Tabs') && roles && roles.filter((r) => r.menu === 'Header' && r.section === 'Tabs')[0]
  const storeRoles = roles && roles.filter((r) => r.menu === 'Header' && r.section === 'StoreSelection') && roles && roles.filter((r) => r.menu === 'Header' && r.section === 'StoreSelection')[0]
  const loginId = Login && Login.email && Login.email.toLowerCase()

  let show = document.querySelectorAll('.show')
  let headertooltipid = show && show[0] && show[0].id
  let tooltipActive = show && headertooltipid === 'header-tooltip' ? true : false
  // const showStoreName = (StoreAgentRole === "Store" || StoreAgentRole === 'Store Manager') ? (workingAs ? true : false) : true

  let history = useHistory()
  const [currentDate, setCurrentDate] = useState(moment().format('D MMMM YYYY h:mm:ss'))

  function displayTime() {
    setInterval(() => {
      setCurrentDate(moment().format('D MMMM YYYY h:mm:ss'))
    }, 1000)
  }

  const logisticsClick = () => {
    actions.setOrderTab(false)
    actions.setLogisticsTab(true)
    actions.setReportingTab(false)
    actions.setExceptionTab(false)
    actions.setLowValueDeviceTab(false)
    actions.setTradeDetailsTab(false)
    history.replace('/logistics')
  }

  const reportingClick = () => {
    actions.setReportSummaryTab(true)
    actions.setReportDetailsTab(false)
    actions.setReportCareCreditTab(false)
    actions.setReportEmailAuditTab(false)
    actions.setOrderTab(false)
    actions.setReportingTab(true)
    actions.setLogisticsTab(false)
    actions.setExceptionTab(false)
    actions.setTradeDetailsTab(false)
    actions.setLowValueDeviceTab(false)

    history.replace('/reports')
  }

  const exceptionClick = () => {
    actions.setOrderTab(false)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setExceptionTab(true)
    actions.setLowValueDeviceTab(false)
    actions.setTradeDetailsTab(false)
    history.replace('/exception')
  }

  const orderClick = () => {
    actions.setOrderTab(true)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setExceptionTab(false)
    actions.setLowValueDeviceTab(false)
    actions.setTradeDetailsTab(false)
    history.replace('/orderSearch')
  }

  const tradeDetailsClick = () => {
    actions.setOrderTab(false)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setExceptionTab(false)
    actions.setLowValueDeviceTab(false)
    actions.setTradeDetailsTab(true)
    history.replace('/tradeDetails')
  }

  // const lowValueDeviceClick = () => {
  //   actions.setOrderTab(false)
  //   actions.setLogisticsTab(false)
  //   actions.setReportingTab(false)
  //   actions.setExceptionTab(false)
  //   actions.setLowValueDeviceTab(true)
  //   history.replace('/lowvaluedevices')
  // }

  const logOut = () => {
    let confirmation = window.confirm('Do you want to logout?')
    if (confirmation == true) {
      window.location.replace('/')
    }
  }

  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase()
  return (
    <header>
      <div className={`flex justify-between  items-center large-desktop:mx-100 mt-20 mx-50`}>
        <h2 className=" large-desktop:text-44 text-36 leading-10 font-Samsung-Sharp-Sans-Bold p-1 text-black">Samsung Trade-Up</h2>
        <div className=" lg:block">
          <div className=" lg:block">
            <div id="ct" className="px-3 text-right" />
            <p className="text-right h-3 large-desktop:mt-28 mt-1.5 mb-10 ">{currentDate}</p>
            {displayTime()}
          </div>
          <div className=" lg:block">
            <ul className="inline-flex">
              {/* <li className={`${LowValueDeviceTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-30 text-17 leading-20 text-black mr-15 cursor-pointer`}>
                <a
                  onClick={() => {
                    lowValueDeviceClick()
                  }}
                >
                  Low Value Device
                </a>
              </li> */}

              {tabRoles['Order'][agentRole] === 'SHOW' && (
                <li className={`${OrderTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-55 text-17 leading-20 text-black mr-20 cursor-pointer`}>
                  <a
                    onClick={() => {
                      orderClick()
                    }}
                  >
                    Confirm Order
                  </a>
                </li>
              )}

              {tabRoles['Logistics'][agentRole] === 'SHOW' && (
                <li className={`${LogisticsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-black mr-20 cursor-pointer`}>
                  <a
                    onClick={() => {
                      logisticsClick()
                    }}
                  >
                    Logistics
                  </a>
                </li>
              )}

              {tabRoles['Exceptions'][agentRole] === 'SHOW' && (
                <li className={`${ExceptionsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-black mr-20 cursor-pointer`}>
                  <a
                    onClick={() => {
                      exceptionClick()
                    }}
                  >
                    Exceptions
                  </a>
                </li>
              )}

              {tabRoles['Reporting'][agentRole] === 'SHOW' && (
                <li className={`${ReportsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-black mr-20 cursor-pointer`}>
                  <a
                    onClick={() => {
                      reportingClick()
                    }}
                  >
                    Reporting
                  </a>
                </li>
              )}

              {tabRoles.TradeDetailsDetails[loginId] === 'SHOW' && (
                <li className={`${TradeDetailsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-55 text-17 leading-20 text-black mr-20 cursor-pointer`}>
                  <a
                    onClick={() => {
                      tradeDetailsClick()
                    }}
                  >
                    Trade Details
                  </a>
                </li>
              )}
            </ul>
            <ul className="inline-flex">
              <li className="px-3 h-5 ml-2">{StoreAgentName}</li>
              <div className="example-jsx">
                <div className="">
                  <img className="cursor-pointer inline-block" data-tip data-for="happyFace" src={helpLogo} data-event="click focus" alt="" />
                  <div className="">
                    <ReactTooltip
                      className="tooltip  w-340 h-145 blue"
                      globalEventOff="click"
                      place="left"
                      type="dark"
                      effect="float"
                      id="happyFace"
                      type="error"
                      clickable
                      style="background-color:black"
                      arrowColor="transparent"
                    >
                      <div className="text-13 leading-18 font-SamsungOne-400 mx-10 my-10 ">
                        For customer related queries, please reach out to
                        <br />{' '}
                        <div className="mb-10">
                          <a className="text-blue-500 " href="mailto:Samsung-tradein_inquiries@asurion.com">
                            Samsung-tradeup_inquiries@asurion.com
                          </a>
                        </div>
                        <div className="">
                          For store related queries, please reach out to <br />
                          <a className="text-blue-500" href="mailto:Samsung-Store_enquiries@asurion.com">
                            Samsung-Store_enquiries@asurion.com
                          </a>
                        </div>
                      </div>
                      <div className="mb-15 mr-10">
                        <img className="float-right inline-block mb-5" src={imgAsurion} alt="asurion-img" alt="" />{' '}
                      </div>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <span className=" ml-2 h-5">|</span>
              <a className="pl-3 cursor-pointer h-5" onClick={() => logOut()}>
                Logout
              </a>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
