/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, useReducer } from 'react'
import { reducer, initialState } from './reducer'
import { applyMiddleware } from './middleware'

const store = createContext(initialState)
const { Provider } = store

const customReducer = (_reducer, _initialState) => {
  const [state, dispatch] = useReducer(_reducer, _initialState)

  const dispatchWithMiddleWare = (action) => {
    applyMiddleware(dispatchWithMiddleWare)(action)
    return dispatch(action)
  }

  return [state, dispatchWithMiddleWare]
}

const StateProvider = ({ children }) => {
  const [state, dispatch] = customReducer(reducer, initialState)
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }
