import React, { useContext, useRef } from 'react'
import LabeledInputField from '../../../utils/custom-fields'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../../actions'
import { store } from '../../store'
import { useHistory } from 'react-router-dom'
import logo from '../../images/img-Samsung.png'
import mobileImage from '../../images/img-tradeup.png'
// import footerLogo from '../../images/Combined Shape.png'
import mobileImage1 from '../../images/img-tradeup1.png'
import ReCAPTCHA from 'react-google-recaptcha'

const forgotPassword = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {},
  })
  const { isValid } = formState
  const { Login, isForgotPswdClicked, isForgotPassFailure, forgotPasswordSuccessResponse, forgotPasswordError } = state
  const status = forgotPasswordSuccessResponse && forgotPasswordSuccessResponse.Status
  const { emailId, setCaptcha } = Login
  const resources = state && state.resources
  const forgotPasswordErr = forgotPasswordError && forgotPasswordError.Error
  const forgotErrorStatus = forgotPasswordError && forgotPasswordError.Status
  let isFormValid = false;
  if (status && status === 'Success') {
    history.replace('/changepass')
  }
  const captcha = useRef(null)

  let ErrorMessage = ''
  isFormValid = isValid && setCaptcha ? true : false
  const forgotPassword = () => {
    actions.forgotPasswordRequest({
      username: emailId,
      captchatoken: setCaptcha
    })
  }
  return (
    <div className="bg-white">
      <div className="inline-block large-desktop:min-h-960 laptop:h-608 tablet:h-680 large-desktop:max-w-w-content w-4/6 tablet:w-70 mt-60">
        <div className="m-mr-header my-10">
          <img className="h-5 large-desktop:h-h-30" src={logo} alt="" />
        </div>
        <div className="flex flex-col ml-100 justify-center">
          <div className="inline-block mt-20 mb-20 login-container">
            <LabeledInputField
              name="forgotEmail"
              label="Samsung EmailId"
              type="text"
              labelClassName="w-170 my-auto text-right"
              className="inline-block p-input-box w-320 leading-5 border-solid border box-border large-desktop:h-h-50 h-h-36 font-SamsungOne-400 rounded not-italic flex font-light font-17 leading-tight"
              placeholder="EmailId"
              register={register}
              errors={errors}
              onChange={actions.setEmailId}
              schema="login"
              defaultValue={Login && Login.emailId}
            />
            <div className="inline-block mt-20 mb-20 login-container">
              <ReCAPTCHA ref={captcha} sitekey={process.env.RECAPTCHA_SITE_KEY} onChange={actions.setCaptcha} onExpired={actions.expireCaptcha} />
            </div>
            {isForgotPassFailure ? <div className="error-text pl-130 ">{forgotErrorStatus === 'failure' ? forgotPasswordErr : forgotErrorStatus === 'account_lock' ? "Too many failed attempts. Your account has been locked, please try again in 10 minutes." : "" }</div> : null}
          </div>
          <div className="flex flex-row justify-center mb-20 self-start">
            <button
              disabled={!isFormValid}
              onClick={() => forgotPassword()}
              type="submit"
              className={`${
                !isFormValid ? 'disabled-btn' : ''
              } btn w-170 bg-blue-500 justify-center font-SamsungOne-400 not-italic font-bold text-xl leading-tight flex items-center text-center uppercase text-white`}
            >
              Submit
            </button>
          </div>
        </div>
        {/* <div className="fixed bottom">
          <div className="inline-block m-mr-footer">
            <img className="h-5 large-desktop:h-h-50" src={footerLogo} alt="" />
          </div>
        </div> */}
      </div>
      <div className="large-desktop:inline-block large-desktop:max-w-w-image float-right large-desktop:m-mr-image-right m:0 tablet:w-30 hidden">
        <img className="tablet:mr-0 mr-100" src={mobileImage1} alt="" />
      </div>
      <div className="inline-block large-desktop:max-w-w-image float-right large-desktop:m-mr-image-right m:0 tablet:w-30 mr-30 large-desktop:hidden">
        <img className="laptop:h-728 tablet:h-800 tablet:mr-0 mr-100" src={mobileImage} alt="" />
      </div>
    </div>
  )
}
export default forgotPassword
