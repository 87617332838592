/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
import types from './actionTypes'
import API from './api'
import FileSaver from 'file-saver'
import { PDFDocument } from 'pdf-lib'
import html2canvas from 'html2canvas'
import XLSX from 'xlsx'
import masterreportheaderformat from './components/reports/masterreportheaderformat.json'
import emailAuditReportHeaderFormat from './components/reports/emailauditreportheaderformat.json'
import roleAccess from './components/role-access.json'
import exceptionList from './dummyjson/searchException.json'
const moment = require('moment')

const middlewareErrorHanlder = (dispatch) => (error) => {
  console.log('error', error)
  dispatch({ type: types.API_ERROR, data: error })
  dispatch({ type: types.UNSET_LOADING_INDICATOR })
}
const middleware = {}

// eslint-disable-next-line no-unused-varsnpm start

middleware[types.INITIALIZE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.INITIALIZE_SUCCESS, data })
      dispatch({ type: types.GET_ROLE_ACCESS_REQUEST })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.GET_ROLE_ACCESS_REQUEST] = (dispatch) => (action) =>
  // API[action.type](action.payload)
  Promise.resolve(roleAccess)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_ROLE_ACCESS_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.SET_COGNITO_HEADER] = (dispatch) => (action) => {
  API[action.type](action.data)
}

middleware[types.LOGIN_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let dataa = data.data && data.data.LoginAPiResponse
      if (dataa.Status == 'success') {
        console.log('====>', dataa.Status)
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.LOGIN_SUCCESS, data })
      dispatch({ type: types.SET_COGNITO_HEADER, data: dataa })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.GET_TRADE_INFO_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data, action.tokenHeders)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_TRADE_INFO_SUCCESS, data })
      const zipcode = data && data.data && data.data.FindTradeInfoResponse && data.data.FindTradeInfoResponse.CustomerInformation && data.data.FindTradeInfoResponse.CustomerInformation.PostalCode
      if (zipcode) {
        dispatch({ type: types.GET_CITY_STATE_REQUEST, data: zipcode })
      }
      if (data && data.data && data.data.FindTradeInfoResponse) {
        dispatch({ type: types.SET_LOADING_INDICATOR })
        dispatch({ type: types.GET_FMIP_STATUS_REQUEST, data: action.data })
      }
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_TRADE_DETAILS] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_TRADE_DETAILS_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_FMIP_STATUS_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_FMIP_STATUS_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_AMTA_CHECK_LVD_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_AMTA_CHECK_LVD_SUCCESS, data: data.data && data.data.IMEIVerificationResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.CREATE_QUOTE_LVD_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_QUOTE_LVD_SUCCESS, data: data.data && data.data.CreateQuoteResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.UPDATE_TRADE_INFO_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UPDATE_TRADE_INFO_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_CITY_STATE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_CITY_STATE_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.CHANGE_PASSWORD_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let res = data.data && data.data.ChangeTempPasswordResponse
      if (res.Status == 'success') {
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, data })
      dispatch({ type: types.SET_COGNITO_HEADER, data: res })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_LOGISTICES_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_LOGISTICES_SUCCESS, data: data.data.GetDeviceListResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.GET_LOGISTICES_BATCH_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_LOGISTICES_BATCH_SUCCESS, data: data.data.GetBatchDetailsResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
middleware[types.CREATE_BATCH_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_BATCH_SUCCESS, data: data.data.CreateBatchResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.FORGORT_PASSWORD_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.FORGORT_PASSWORD_SUCCESS, data: data.data.ForgotPasswordResponse })
    })
    .catch((error) => {
      dispatch({ type: types.FORGORT_PASSWORD_FAILURE, data: error && error.response && error.response.data && error.response.data.ForgotPasswordResponse }),
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
}
middleware[types.CONFIRM_PASSWORD_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let confirmData = data.data && data.data.ConfirmPasswordResponse
      let tokenHeaders = { AccessToken: confirmData.AccessToken, IdToken: confirmData.IdToken, RefreshToken: confirmData.RefreshToken }
      if (confirmData.Status == 'success') {
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.CONFIRM_PASSWORD_SUCCESS, data: data.data.ConfirmPasswordResponse })
      dispatch({ type: types.SET_COGNITO_HEADER, data: confirmData })
      //dispatch({ type: types.GET_MASTERDATA_REQUEST, tokenHeaders })
    })
    .catch((error) => {
      dispatch({ type: types.CONFIRM_PASSWORD_FAILURE, data: error && error.response && error.response.data && error.response.data.ConfirmPasswordResponse }),
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.CREATE_PARCEL_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data, action.GetBatchDetailsRequest)
    .then((data) => {
      let res = (data && data.data && data.data.response && data.data.response.AusPostApiCallDetails) || (data && data.data)
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_PARCEL_SUCCESS, data: res })

      let ReprintDetails = res && res.ReprintDetails
      let createShipmentResponse =
        data &&
        data.data &&
        data.data.response &&
        data.data.response.AusPostApiCallDetails &&
        data.data.response.AusPostApiCallDetails.responses &&
        data.data.response.AusPostApiCallDetails.responses.createShipmentResponse
      let ConsignmentNo = createShipmentResponse && createShipmentResponse.shipments[0].items[0].tracking_details.consignment_id
      let base64Summary = ReprintDetails
        ? ReprintDetails.OrderSummaryPdfData
        : data &&
          data.data &&
          data.data.response &&
          data.data.response.AusPostApiCallDetails &&
          data.data.response.AusPostApiCallDetails.responses &&
          data.data.response.AusPostApiCallDetails.responses.OrderSummaryExpResponse
      let base64Label = ReprintDetails
        ? ReprintDetails.OrderLabelData
        : data &&
          data.data &&
          data.data.response &&
          data.data.response.AusPostApiCallDetails &&
          data.data.response.AusPostApiCallDetails.responses &&
          data.data.response.AusPostApiCallDetails.responses.File

      let AusPost = {
        base64Summary: base64Summary,
        base64Label: base64Label,
        ConsignmentNo: ConsignmentNo,
      }
      return new Promise((resolve) => resolve()).then(async (data) => {
        const base64Summary = AusPost && AusPost.base64Summary
        const base64label = AusPost && AusPost.base64Label

        const dataUriSummary = 'data:application/pdf;base64,' + base64Summary
        const dataUriLabel = 'data:application/pdf;base64,' + base64label
        const firstDonorPdfDoc = await PDFDocument.load(dataUriSummary)
        const secondDonorPdfDoc = await PDFDocument.load(dataUriLabel)

        const pdfDoc = await PDFDocument.create()
        const [firstPage, secondPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [0, 1])
        const [thirdPage] = await pdfDoc.copyPages(secondDonorPdfDoc, [0])

        pdfDoc.addPage(firstPage)
        pdfDoc.addPage(secondPage)
        pdfDoc.addPage(thirdPage)

        const page = pdfDoc.addPage([842, 595])
        //dispatch({ type: types.SHOW_CANVAS })
        let canvas
        if (!ReprintDetails) {
          if (action.GetBatchDetailsRequest) {
            dispatch({ type: types.SHOW_CANVAS_REPRINT })
            document.getElementById('reprintme').style.visibility = 'visible'
            const input = document.getElementById('reprintme')
            canvas = await html2canvas(input, { scale: 4 })
            document.getElementById('reprintme').style.visibility = 'hidden'
            dispatch({ type: types.HIDE_CANVAS_REPRINT })
          } else {
            dispatch({ type: types.SHOW_CANVAS })
            document.getElementById('printme').style.visibility = 'visible'
            const input = document.getElementById('printme')
            canvas = await html2canvas(input, { scale: 4 })
            document.getElementById('printme').style.visibility = 'hidden'
            dispatch({ type: types.HIDE_CANVAS })
          }
        } else {
          dispatch({ type: types.SHOW_CANVAS_REPRINT })
          document.getElementById('reprintme').style.visibility = 'visible'
          const input = document.getElementById('reprintme')
          canvas = await html2canvas(input, { scale: 4 })
          document.getElementById('reprintme').style.visibility = 'hidden'
          dispatch({ type: types.HIDE_CANVAS_REPRINT })
        }

        const imgData = canvas.toDataURL('image/png')
        const pngImage = await pdfDoc.embedPng(imgData)

        page.drawImage(pngImage, {
          x: 15,
          y: 20,
          width: 812,
          height: 555,
        })
        const pdfBytes = await pdfDoc.saveAsBase64()

        let linkSource = `data:application/pdf;base64,${pdfBytes}`
        const downloadLink = document.createElement('a')
        const fileName = 'Logistics.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        if (action.GetBatchDetailsRequest) {
          let GetBatchDetailsRequest = {
            GetBatchDetailsRequest: action.GetBatchDetailsRequest,
          }
          dispatch({ type: types.GET_LOGISTICES_BATCH_REQUEST, data: GetBatchDetailsRequest })
        }
      })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_FILE_REQUEST] = (dispatch) => (action) => {
  API[action.type]()
    .then((data) => {
      let res = {
        GetTradeInCareCreditReportResponse: [
          {
            FileName: 'TradeInCareCreditReport_A_2020-10-06.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_B_2020-10-06.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_A_2020-10-01.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_B_2020-10-01.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_A_2020-09-20.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_B_2020-09-20.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_B_2020-09-17.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_A_2020-09-17.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-17.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-10.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-09.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-04.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-03.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-09-01.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-08-25.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-08-24.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-08-23.xlsx',
          },
          {
            FileName: 'TradeInCareCreditReport_2020-08-18.xlsx',
          },
        ],
      }
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_FILE_SUCCESS, data: res.GetTradeInCareCreditReportResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.DOWNLOAD_FILE_REQUEST] = (dispatch) => (action) => {
  /*  let body = { Files: action.data };
   let data = {FileDownloadRequest: body} */
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      var byteCharacters = atob(data.data.FileDownloadResponse)
      var byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      var byteArray = new Uint8Array(byteNumbers)
      var blob = new Blob([byteArray], {
        type: 'application/octet-stream',
      })
      FileSaver.saveAs(blob, action.payload)
      dispatch({ type: types.DOWNLOAD_FILE_SUCCESS, data: data.data })
      return data
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.CREATE_BATCH_ONSUBMIT] = (dispatch) => (action) => {
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_BATCH_ONSUBMIT_SUCCESS, data: data.data.CreateBatchResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.CREATE_PARCEL_ONSUBMIT_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_PARCEL_ONSUBMIT_SUCCESS, data: data.data.response.AusPostApiCallDetails })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_REPORTING_DETAILS_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data, action.Exportflag)
    //Promise.resolve(reportDetails)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let ReportDetails = data && data.data && data.data.GetReportSummaryResponse && data.data.GetReportSummaryResponse.ReportsData
      let newReportDetails =
        ReportDetails && ReportDetails.map(({ ValidationRequired, ServiceOrderID, PaymentVariance, ParentAccountName, SubChannelName, ParentCode, CustomerBAN, BonusCredit, ...item }) => item)

      if (action.Exportflag) {
        let colWidth = []
        const ws = XLSX.utils.json_to_sheet(newReportDetails)
        var range = XLSX.utils.decode_range(ws['!ref'])
        for (var C = range.s.r; C <= range.e.c; ++C) {
          var address = XLSX.utils.encode_col(C) + '1'
          ws[address].v = masterreportheaderformat[ws[address].v] ? masterreportheaderformat[ws[address].v] : ws[address].v
          if (ws[address].v === 'Current Location' || ws[address].v === 'IMEI In' || ws[address].v === 'IMEI Out' || ws[address].v === 'Store Name' || ws[address].v === 'Trading Name') {
            colWidth.push({ width: ws[address].v.length + 19 })
          } else if (ws[address].v === 'Submitted At' || ws[address].v === 'IMEI IN Model / Variant' || ws[address].v === 'IMEI Out Model / Variant') {
            colWidth.push({ width: ws[address].v.length + 17 })
          } else if (ws[address].v === 'Model') {
            colWidth.push({ width: ws[address].v.length + 28 })
          } else if (ws[address].v === 'Team Member Name' || ws[address].v === 'Team Member ID') {
            colWidth.push({ width: ws[address].v.length + 8 })
          } else {
            colWidth.push({ width: ws[address].v.length + 2 })
          }
        }
        ws['!cols'] = colWidth
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        FileSaver.saveAs(blob, `MasterViewReport.xlsx`)
        return true
      }

      dispatch({ type: types.GET_REPORTING_DETAILS_SUCCESS, data: data.data.GetReportSummaryResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.SEND_EMAIL_LABEL_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.SEND_EMAIL_LABEL_SUCCESS, data: data.data })
    })
    .catch((error) => {
      dispatch({ type: types.SEND_EMAIL_LABEL_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.GET_EXCEPTION_DETAILS_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    //  Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_EXCEPTION_DETAILS_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_DAX_IMAGE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    // Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_DAX_IMAGE_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.UDPATE_EXCEPTION_ACTION_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    // Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UDPATE_EXCEPTION_ACTION_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_EMAIL_AUDIT_REPORT_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data, action.EmailAuditExportFlag)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let emailAuditReport = data && data.data && data.data.GetEmailAuditReportResponse && data.data.GetEmailAuditReportResponse.ReportsData
      let newEmailAuditReport =
        emailAuditReport &&
        emailAuditReport.map((item) => ({
          TemplateName: item.TemplateName,
          ReferenceType: item.ReferenceType,
          ReferenceNbr: item.ReferenceNbr,
          SentTo: item.SentTo,
          CreatedDate: moment(item.CreatedDate).format('DD-MM-YYYY'),
          EmailStatus: item.EmailSentStatus,
        }))
      if (action.EmailAuditExportFlag) {
        let colWidth = []
        const ws = XLSX.utils.json_to_sheet(newEmailAuditReport)
        var range = XLSX.utils.decode_range(ws['!ref'])
        for (var C = range.s.r; C <= range.e.c; ++C) {
          var address = XLSX.utils.encode_col(C) + '1'
          ws[address].v = emailAuditReportHeaderFormat[ws[address].v] ? emailAuditReportHeaderFormat[ws[address].v] : ws[address].v
          if (ws[address].v === 'MESSAGE_SUBJECT') {
            colWidth.push({ width: ws[address].v.length + 35 })
          } else if (ws[address].v === 'SENT_TO') {
            colWidth.push({ width: ws[address].v.length + 30 })
          } else if (ws[address].v === 'CREATED_DATE' || ws[address].v === 'REFERENCE_NUMBER') {
            colWidth.push({ width: ws[address].v.length + 4 })
          } else {
            colWidth.push({ width: ws[address].v.length + 2 })
          }
        }
        ws['!cols'] = colWidth
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        FileSaver.saveAs(blob, `EmailAuditReport.xlsx`)
        return true
      }
      dispatch({ type: types.GET_EMAIL_AUDIT_REPORT_SUCCESS, data: data.data })
    })
    .catch((error) => {
      dispatch({ type: types.GET_EMAIL_AUDIT_REPORT_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
}

export const applyMiddleware = (dispatch) => (action) => middleware[action.type] && middleware[action.type](dispatch)(action)
