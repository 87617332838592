import React from 'react'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, SortSelection, DateInputField } from '../../../utils/custom-fields'
import { useActions } from '../../actions'
import { store } from '../../store'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginate from '../pagination'
import ReportingDetails from './details';
import StatusNames from '../../dummyjson/reportstatus.json';
const moment = require('moment')

const ReportDetails = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { ReportingDetailsInputFields, resources, reportSelectedPage, reportingData, Login, shoeReportingDetails, roles } = state
  const ReportDetails = reportingData && reportingData.ReportDetails
  const TotalRecords = reportingData && reportingData.TotalRecords

  const { register, errors, setValue } = useForm({ mode: 'onChange' })
  const baseURL = 'https://msto.startrack.com.au/track-trace/?id='
  const exportServiceCall = () => {
    let GetReportSummaryRequest = {
      //PartnerStoreId: (state && state.Login && state.Login.StoreAgentRole && state.Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
      PartnerStoreId: "",
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,

      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: TotalRecords,
      PageNumber: 1,
    }
    actions.getReportingDetails({ GetReportSummaryRequest }, true)
  }

  let enableExportButton = ReportDetails && ReportDetails.length > 0 ? true : false;

  useEffect(() => {
    searchReport()
  }, [reportSelectedPage])

  const pageLimit = 10
  const totalPages = Math.ceil(TotalRecords / pageLimit)
  const range = (from, to, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
      range.push(i)
      i += step
    }
    return range
  }
  let pages = range(1, totalPages)
  const [offset, setOffset] = useState(0)
  let [details, setData] = ReportDetails ? useState(ReportDetails) : useState([])
  const [reportCurrentData, setReportCurrentData] = useState([])
  //let selectedBatch = reportCurrentData && reportCurrentData.filter(details => details.isSelected === true)[0]
  useEffect(() => {
    setReportCurrentData(details.slice(offset, offset + pageLimit))
  }, [offset, details])

  let status = StatusNames && StatusNames.map(i => ({key: i.key, value: i.value}));

  useEffect(() => {
    return () => {
      actions.setReportTradeIDNumber(undefined)
      actions.setReportStartDate(undefined)
      actions.setReportEndDate(undefined)
      actions.setReportState(undefined)
      actions.setReportStatus(undefined)
      actions.setReportStoreName(undefined)
      actions.setReportSelectedPage(0)
      actions.setOsotReportData({})
    }
  }, [])

  const searchItem = (tradeid, flag) => {
    ReportDetails &&
      ReportDetails.map((item) => {
        if (item.TradeId === tradeid && !flag) {
          item.isSelected = true
          actions.setShowReportingDetails(true)
        }
        if (flag) {
          actions.setShowReportingDetails(false)
          item.isSelected = false
        }
        return item
      })
    actions.setOsotReportData(ReportDetails)
  }

  const searchReport = (isSearch = false) => {

    if (isSearch) {
      actions.setReportSelectedPage(0)
    }
    let GetReportSummaryRequest = {
      //PartnerStoreId: (state && state.Login && state.Login.StoreAgentRole && state.Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
      PartnerStoreId: "",
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,
      // TradeQuoteNbr: "VH96A474",
      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: 10,
      PageNumber: isSearch ? 1 : reportSelectedPage + 1,
    }
    actions.getReportingDetails({ GetReportSummaryRequest })
  }

  const setReportSelectedPage = (index) => {
    actions.setReportSelectedPage(index)
  }

  const resetFields = () => {
    actions.setReportTradeIDNumber(undefined)
    actions.setReportStartDate(undefined)
    actions.setReportEndDate(undefined)
    actions.setReportState(undefined)
    actions.setReportStatus(undefined)
    actions.setReportStoreName(undefined)
    if (reportSelectedPage == 0) {
      let GetReportSummaryRequest = {
        //PartnerStoreId: (state && state.Login && state.Login.StoreAgentRole && state.Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
        PartnerStoreId: "",
        PageSize: 10,
        PageNumber: 1,
      }
      actions.getReportingDetails({ GetReportSummaryRequest })
    }
    actions.setReportSelectedPage(0)
    setValue('tradeID', '')
    setValue('date', '')
    setValue('date', '')
    setValue('reportState', '')
    setValue('reportStatus', '')
    setValue('storeName', '')
  }

  let pageCount = Math.ceil(TotalRecords / pageLimit)

  const ExportClickDetails = roles && roles.filter(r => r.menu === 'Reporting' && r.section === "ExportClickDetails") &&
    roles && roles.filter(r => r.menu === 'Reporting' && r.section === "ExportClickDetails")[0];
  const { StoreAgentRole } = Login;
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();

  return (
    <div className="h-900">
      <div className="flex w-full items-center">
        <h2 className="text-17 font-Samsung-Sharp-Sans-Bold mt-31 ml-4 w-138">Search</h2>
      </div>
      <div className="flex align-items-center">
        <LabeledInputField
          className="report-input report-search-font"
          type="text"
          name="tradeID"
          schema="reporting"
          placeholder="Trade ID"
          onChange={actions.setReportTradeIDNumber}
          register={register}
          errors={errors}
          hideLabel
        />
        <DateInputField
          className="email-audit-date-input report-search-font"
          onChange={actions.setReportStartDate}
          placeholder="Start Date"
          value={ReportingDetailsInputFields && ReportingDetailsInputFields.StartDate ? ReportingDetailsInputFields.StartDate : ''}
          format="dd-MM-yyyy"
        />
        <DateInputField
          className="email-audit-date-input report-search-font"
          onChange={actions.setReportEndDate}
          placeholder="End Date"
          value={ReportingDetailsInputFields && ReportingDetailsInputFields.EndDate ? ReportingDetailsInputFields.EndDate : ''}
          format="dd-MM-yyyy"
        />

        {/* <LabeledInputField
            className="report-input report-search-font"
            type="text"
            name="reportState"
            schema="reporting"
            placeholder="REPORTING_LBL_STATE"
            onChange={actions.setReportState}
            register={register}
            errors={errors}
            hideLabel
          />  */}

        <RenderSelectField
          name="reportStatus"
          label=""
          className="report-input report-search-font"
          register={register}
          onChange={(e) => actions.setReportStatus(e)}
          options={status}
          optionsKey="key"
          optionsValue="value"
          errors={errors}
          schema="reporting"
          value={ReportingDetailsInputFields ? ReportingDetailsInputFields.Status : ''}
        />

        <LabeledInputField
          className="report-input report-search-font"
          type="text"
          name="storeName"
          schema="reporting"
          placeholder="Store Name"
          onChange={actions.setReportStoreName}
          register={register}
          errors={errors}
          hideLabel
        />

        <button className="purple-button w-80 h-8 report-search-font ml-5 mr-10 pl-5 pr-5" onClick={() => searchReport(true)}>
          {/* resources.VODAFONE_SEARCH_BUTTON */}Search
        </button>

        <button className="black-button w-80 h-8 report-search-font ml-10" onClick={() => resetFields()}>
          {/* resources.VODAFONE_RESET_BUTTON */}Reset
        </button>
        {!shoeReportingDetails ? //ExportClickDetails['Export'][agentRole] === 'SHOW' &&
          <button className={`${!enableExportButton //&&  ExportClickDetails['Export'][agentRole] === 'SHOW'
            ? 'disable-button' : 'purple-button'} w-80 h-8 ml-auto text-xs`} onClick={() => (exportServiceCall())} disabled={!enableExportButton}>
            {'Export'}
          </button> :
          <button className="purple-button w-140 h-8 ml-auto text-xs" onClick={() => (searchItem('', true))}>
            {'Summary Page'}
          </button>
        }
      </div>

      {ReportDetails && ReportDetails.length > 0 ? (
        shoeReportingDetails ? (
          <div>
            <ReportingDetails />
          </div>
        ) : (
            <table className="mt-15 text-sm border-white w-full h-50 border border-gray-2">
              <thead className=" text-transform: uppercase border-white bg-grey-4 text-white h-50 text-left font-Samsung-Sharp-Sans-Bold border border-gray-2 ">
                <tr>
                  <th className="leading-17 pl-13 w-80 h-34 pl-13" colSpan="1">
                    Trade id
                </th>
                  <th className="w-150 pl-13 " colSpan="1">
                    Device IMEI
                </th>
                  <th className="w-150 pr-13" colSpan="1.5">
                    Make & Model
                </th>
                  <th className=" w-250 pr-15" colSpan="3">
                    Submitted Date{' '}
                  </th>
                  <th className="w-300 pl-15" colSpan="3">
                    Latest Update
                </th>
                  <th className="w-250" colSpan="7">
                    Current Status
                </th>
                  <th className="w-250" colSpan="7">
                    Submitted Location
                </th>
                  <th className=" w-370 pr-13" colSpan="8">
                    Submitted By
                </th>
                  <th className=" w-370 pr-13" colSpan="9">
                    Submitted At
                </th>
                  <th className=" w-370 pl-13 pr-13" colSpan="10">
                    Consignment Details
                </th>
                  <th className="w-400 pl-13 pr-13" colSpan="11">
                    Return Consignment Details
                </th>
                </tr>
              </thead>
              {ReportDetails &&
                ReportDetails.map((table_Detail, index) => (
                  <tbody key={index}>
                    <tr className="text-left h-50 text-sm font-Samsung-Sharp-Sans-Bold border border-gray-2 bg-white" className={`${table_Detail.isSelected ? 'batch-active-row-bg ' : ''}`}>
                      <td className="cursor-pointer underline pl-13" colSpan="1" onClick={() => searchItem(table_Detail.TradeId, false)}>
                        {table_Detail.TradeId}
                      </td>
                      <td>
                        <td className="w-130 pl-13 h-50" colSpan="2">
                          <span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.PrimaryDeviceIMEI}<br />{table_Detail.SecondaryDeviceIMEI ? <div>
                            <span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondaryDeviceIMEI} </div> : ''}
                        </td>
                      </td>
                      <td className="w-20 h-50" colSpan="1.5">
                        <div><span>
                          {/* {table_Detail.Make}<br /> */}{table_Detail.Model}
                        </span></div>
                      </td>
                      <td className="h-50 w-50" colSpan="3">
                        {table_Detail.SubmittedDate && moment(table_Detail.SubmittedDate).format('DD/MM/YYYY')}
                      </td>
                      <td className="w-20 h-50 pl-13" colSpan="3">
                        {table_Detail.LastUpdated && moment(table_Detail.LastUpdated).format('DD/MM/YYYY')}
                      </td>
                      <td className="w-50 h-50" colSpan="7">
                        {table_Detail.CurrentStatus}
                      </td>
                      <td className="h-50 w-160" colSpan="7">
                        {table_Detail.RaisedLocation}
                      </td>
                      <td className="h-50 w-75" colSpan="8">
                        {table_Detail.TeamMemberName}
                      </td>
                      <td className="h-50 w-120" colSpan="9">
                        {table_Detail.SubmmitedAt}
                      </td>
                      <td className=" underline h-50 w-20 pl-13" colSpan="10">
                        {table_Detail.ConsignmentDetails ? <a rel="noopener noreferrer" href={baseURL + table_Detail.ConsignmentDetails} target='_blank'>{table_Detail.ConsignmentDetails}</a> : null}
                      </td>
                      <td className=" underline h-50 w-120 pl-13" colSpan="11">
                        {table_Detail.ReturnConsignmentDetails ? <a rel="noopener noreferrer" href={baseURL + table_Detail.ReturnConsignmentDetails} target='_blank'>{table_Detail.ReturnConsignmentDetails}</a> : null}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          )
      ) : (
          <div className="mt-15">No Records Found</div>
        )}
      {ReportDetails && ReportDetails.length > 0 && (
        <div className={` ${shoeReportingDetails ? 'hidden' : ''} flex justify-between mt-17`}>
          <div>
            Page {reportSelectedPage + 1} of {pageCount}
          </div>
          <Paginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={<a>...</a>}
            breakClassName={'break-me'}
            pageCount={pageCount}
            selectedPage={reportSelectedPage}
            onPageChange={(page) => setReportSelectedPage(page.selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div>
  )
}

export default ReportDetails
