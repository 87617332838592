const schemas = {
  login: {
    selectedLocation: {
      label: 'Location',
      required: true,
    },
    email: {
      required: true,
      validate: {
        email: (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null,
      },
    },
    forgotEmail: {
      required: true,
    },
    password: {
      required: true,
      minLength: 8,
    },
    passwordd: {
      required: true,
      minLength: 8,
    },
    currentPassword: {
      required: true,
      minLength: 8,
    },
    newPassword: {
      required: true,
      minLength: 8,
    },
    confirmNewPassword: {
      required: true,
      minLength: 8,
    },
  },
  searchid: {
    tradeid: {},
  },
  customerinfo: {
    imeinumber: {
      minLength: 0,
      maxLength: 15,
    },
    FirstName: {
      required: true,
      minLength: 0,
      maxLength: 50,
    },
    lastname: {
      label: 'Last Name',
      required: true,
      minLength: 0,
      maxLength: 50,
    },
    mobileno: {
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
      minLength: 0,
      maxLength: 10,
    },
    emailaddress: {
      required: true,
      validate: {
        email: (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null,
      },
    },
    addressline1: {
      required: true,
      minLength: 0,
      maxLength: 40,
    },
    addressline2: {
      minLength: 0,
      maxLength: 40,
    },
    addressline3: {
      minLength: 0,
      maxLength: 40,
    },
    zipcode: {
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
      minLength: 4,
      maxLength: 4,
    },
    city: {
      required: true,
      minLength: 1,
      maxLength: 50,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
  },
  lvdcustomerinfo: {
    FirstName: {
      required: true,
      minLength: 0,
      maxLength: 50,
    },
    LastName: {
      label: 'Last Name',
      required: true,
      minLength: 0,
      maxLength: 50,
    },
    MobileNo: {
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
      minLength: 0,
      maxLength: 10,
    },
    EmailAddress: {
      required: true,
      validate: {
        email: (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null,
      },
    },
    AddressLine1: {
      required: true,
      minLength: 0,
      maxLength: 40,
    },
    AddressLine2: {
      minLength: 0,
      maxLength: 40,
    },
    AddressLine3: {
      minLength: 0,
      maxLength: 40,
    },
    ZipCode: {
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
      minLength: 4,
      maxLength: 4,
    },
    City: {
      required: true,
      minLength: 1,
      maxLength: 50,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
  },
}

const validator = (formName, fieldName) => {
  if (schemas[formName] && schemas[formName][fieldName]) {
    return schemas[formName][fieldName]
  }
}

export default validator
