import React, { useContext } from 'react';
import { store } from '../../store';
import { useActions } from '../../actions';
import { useForm } from 'react-hook-form';
import LabeledInputField, { RenderSelectField, DateInputField, SortSelection } from '../../../utils/custom-fields';
import { useEffect, useState } from 'react';
import BatchedOrder from './batched-order'
// import Footer from '../confirmorder/footer'
import Paginate from '../pagination'

const moment = require('moment');

const OrderDetails = () => {
	const { state, dispatch } = useContext(store);
	const actions = useActions(state, dispatch);

	// const resources = state && state.resources

	let isSelected = false;
	let {
		LogisticsInputField,
		resources,
		selectedPage,
		BatchingItems,
		logisticsData,
		Login,
		openCreateItemPopUp,
		creatItemsArray,
		UpdateStoreSuccess,
		redirectToLogistics
	} = state;
	const DeviceList = logisticsData && logisticsData.DeviceList;
	const TotalRecords = logisticsData && logisticsData.TotalRecords;
	const ToBeBatched = logisticsData && logisticsData.ToBeBatched;
	useEffect(
		() => {
			let GetDeviceListRequest = {
				PartnerStoreId: Login && Login.PartnerStoreId,
				PartnerParentCode: Login && Login.PartnerParentStoreCode,
				PageSize: 10,
				PageNumber: 1
			};
			actions.getLogistics({ GetDeviceListRequest });
		},
		[true]
	);
	const toggleSelection = (e, id) => {
		DeviceList &&
			DeviceList.map((el) => {
				if (el.ReferenceNo === id) {
					el.isSelected = e.target.checked;
				}

				return el;
			});
		actions.setLogisticsData(DeviceList);
	};

	const toggleSelectionAll = (e) => {
		DeviceList &&
			DeviceList.map((el) => {
				el.isSelected = e.target.checked;
				return el;
			});
		actions.setLogisticsDataAll(DeviceList);
	};
	const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' });


	let selectedList =
		DeviceList &&
		DeviceList.filter((item) => {
			return item.isSelected === true;
		}).length;

	let enableCreateBatch = selectedList > 0 && selectedList <= 10;
	const searchLogistics = (isSearch = false) => {
		if (isSearch) {
			actions.setSelectedPage(0);
		}
		let GetDeviceListRequest = {
			PartnerStoreId: Login && Login.PartnerStoreId,
			PageSize: 10,
			PageNumber: isSearch ? 1 : selectedPage + 1,
			Make: LogisticsInputField.Make,
			Model: LogisticsInputField.Model_Varriant,
			Condition: LogisticsInputField.Condition,
			StartDate: LogisticsInputField.StartDate,
			EndDate: LogisticsInputField.EndDate,
			TradeId: LogisticsInputField.OrderNumber,
			PartnerParentCode: Login && Login.PartnerParentStoreCode
		};
		actions.getLogistics({ GetDeviceListRequest });
	};

	const setSelectedPage = (index) => {
		actions.setSelectedPage(index);
	};

	useEffect(
		() => {
			searchLogistics();
		},
		[selectedPage]
	);

	const createBatch = () => {
		let batchItems =
			DeviceList &&
			DeviceList.filter((item) => {
				return item.isSelected === true;
			}).map((item) => {
				return { ShippingOrderId: item.ShippingOrderId, TradeInfoId: item.TradeInfoId };
			});

		let CreateBatchRequest = {
			PartnerStoreId: Login && Login.PartnerStoreId,
			BatchItems: batchItems
		};
		actions.createBatch({ CreateBatchRequest });
	};
	const resetFields = () => {
		actions.setOrderNumber(undefined);
		actions.setStartDate(undefined);
		actions.setEndDate(undefined);
		actions.setMake(undefined);
		actions.setModelVarriant(undefined);
		actions.setCondition(undefined);
		if (selectedPage == 0) {
			let GetDeviceListRequest = {
				PartnerStoreId: Login && Login.PartnerStoreId,
				PageSize: 10,
				PageNumber: 1,
				PartnerParentCode: Login && Login.PartnerParentStoreCode
			};
			actions.getLogistics({ GetDeviceListRequest });
		}
		actions.setSelectedPage(0);
		setValue('TradeID', '');
		setValue('StartDate', '');
		setValue('EndDate', '');
		setValue('Make', '');
		setValue('Model_Varriant', '');
		setValue('Condition', '');
	};

	let columnsList = [
		{ columnName: "Make", order: "" },
		{ columnName: "Model", order: "" },
		{ columnName: "ProductType", order: "" },
		{ columnName: "Condition", order: "" },
		{ columnName: "StoreCode", order: "" },
		{ columnName: "StoreName", order: "" },
		{ columnName: "MemberId", order: "" },
		{ columnName: "TeamMember", order: "" },
		{ columnName: "ReferenceNo", order: "" },
		{ columnName: "LessThan5Days", order: "" }

	];

	const pageLimit = 10
	const totalPages = Math.ceil(TotalRecords / pageLimit)
	const range = (from, to, step = 1) => {
		let i = from
		const range = []
		while (i <= to) {
			range.push(i)
			i += step
		}
		return range
	}
	let pages = range(1, totalPages)
	const [offset, setOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	let [data, setData] = DeviceList ? useState(DeviceList) : useState([])
	const [currentData, setCurrentData] = useState([])

	useEffect(() => {
		setCurrentData(data.slice(offset, offset + pageLimit))
	}, [offset, data])

	let pageCount = Math.ceil(TotalRecords / pageLimit)
	const [sortColumnsList, setSortOrder] = useState(columnsList)
	const sortData = (fieldName, order) => {
		sortColumnsList.map((item) => {
			if (item.columnName === fieldName) {
				item.order = order
			} else {
				item.order = ""
			}
		});
		setSortOrder(sortColumnsList);
		DeviceList &&
			actions.setLogisticsDataAll(DeviceList.sort(dynamicsort(fieldName, order)))
	}

	const dynamicsort = (property, order) => {
		var sort_order = 1;
		if (order === "DESC") {
			sort_order = -1;
		}
		return function (obj1, obj2) {
			// obj1 should come before obj2 in the sorted order
			if (obj1[property] < obj2[property]) {
				return -1 * sort_order;
				// obj1 should come after obj2 in the sorted order
			} else if (obj1[property] > obj2[property]) {
				return 1 * sort_order;
				// obj1 and obj2 are the same
			} else {
				return 0 * sort_order;
			}
		}
	}

	let capitalizeFirst = (string) => {
		return string && string.charAt(0) && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}
	return (
		<div>
			{BatchingItems && BatchingItems.length > 0 ? (
				<BatchedOrder batchingItems={BatchingItems} />
			) : creatItemsArray && creatItemsArray.DeviceList.length > 0 ? (
				<SearchItems />
			) : (

						<div>
							<div className="flex w-full items-center">
								<h2 className="text-lg font-Samsung-Sharp-Sans-Bold pt-30 pl-3">Search</h2>
							</div>
							<div className="flex align-items-center">
								<LabeledInputField
									className="order-no-input report-search-font"
									type="text"
									name="TradeID"
									schema="logistics"
									placeholder="Order No"
									onChange={actions.setOrderNumber}
									register={register}
									errors={errors}
									hideLabel
								/>
								<DateInputField
								    className= "email-audit-date-input report-search-font"
									onChange={actions.setStartDate}
									placeholder="Start Date"
									value={LogisticsInputField && LogisticsInputField.StartDate ? LogisticsInputField.StartDate : ''}
									format="dd-MM-yyyy"
								/>
								<DateInputField
								    className= "email-audit-date-input report-search-font"
									onChange={actions.setEndDate}
									placeholder="End Date"
									value={LogisticsInputField && LogisticsInputField.EndDate ? LogisticsInputField.EndDate : ''}
									format="dd-MM-yyyy"
								/>
								<LabeledInputField
									className="order-no-input report-search-font"
									type="text"
									placeholder="Make"
									name="Make"
									schema="logistics"
									placeholder="Make"
									onChange={actions.setMake}
									register={register}
									errors={errors}
									hideLabel
								/>
								<LabeledInputField
									className="date-input report-search-font"
									type="text"
									name="Model_Varriant"
									schema="logistics"
									placeholder="Model/Variant"
									onChange={actions.setModelVarriant}
									register={register}
									errors={errors}
									hideLabel
								/>
								<LabeledInputField
									className="order-no-input w-100 report-search-font"
									type="text"
									name="Condition"
									schema="logistics"
									placeholder="Condition"
									onChange={actions.setCondition}
									register={register}
									errors={errors}
									hideLabel
								/>

								<button className="blue-button w-80 h-8 ml-15" onClick={() => searchLogistics(true)}>Search</button>
								<button className="black-button w-80 h-8 ml-10" onClick={() => resetFields()}>Reset</button>
								<button
									className={`${!enableCreateBatch ? 'disable-button' : 'purple-button'} w-145 h-10 ml-auto text-lg leading-21`}
									onClick={() => createBatch()}
									disabled={!enableCreateBatch}
								>Create Batch</button>
							</div>
							{DeviceList && DeviceList.length > 0 ? (
								<table className="mt-20 large-desktop:text-sm text-xs border-white w-full h-50 border border-gray-2">
									<thead className="text-transform: uppercase border-white bg-black text-white h-50 text-left ">
										<tr>
											<th className="arrow">
												{ToBeBatched && ToBeBatched.toUpperCase() === 'YES' &&
													<label className="control control--checkbox">
														<input type="checkbox" onChange={(e) => toggleSelectionAll(e)} />
														<div className="control__indicator" />
													</label>
												}
											</th>
											< th className="h-34 w-8  large-desktop:text-sm text-xs  leading-17 sortEnabledColumn" >
												<span className="columnTitle">
													{'>=1'} <br />
												days
												</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
													fieldName="LessThan5Days"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="leading-17 pl-5 w-6 h-34 pr-4 ">Reference <br />No.</th>
											<th className="w-8 pl-5 leading-17">Date/<br />Time</th>
											<th className="w-8 pl-5 align-baseline pt-13">Device IMEI</th>
											<th className="w-8  pl-5 align-baseline pt-13 sortEnabledColumn">
												<span className="columnTitle">Make</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "Make")[0].order}
													fieldName="Make"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8 pl-5 sortEnabledColumn">
												<span className="columnTitle">Model/ <br />Variant</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "Model")[0].order}
													fieldName="Model"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8 pl-5 sortEnabledColumn">
												<span className="columnTitle">Product<br />Type</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "ProductType")[0].order}
													fieldName="ProductType"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8 pl-5 align-baseline pt-13 sortEnabledColumn ">
												<span className="columnTitle">Condition</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "Condition")[0].order}
													fieldName="Condition"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8  pl-5 sortEnabledColumn">
												<span className="columnTitle">Store <br />Code</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "StoreCode")[0].order}
													fieldName="StoreCode"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="large-desktop:w-8 w-23 pl-5  align-baseline pt-13 sortEnabledColumn">
												<span className="columnTitle">Store Name/<br />Location</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "StoreName")[0].order}
													fieldName="StoreName"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											< th className="w-8 pl-5 laptop:pl-2 align-baseline pt-13 sortEnabledColumn" >
												<span className="columnTitle">Member ID</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "MemberId")[0].order}
													fieldName="MemberId"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8 pl-5  align-baseline pt-13 sortEnabledColumn">
												<span className="columnTitle">Team member</span>
												<SortSelection
													sortOrder={sortColumnsList.filter((item) => item.columnName === "TeamMember")[0].order}
													fieldName="TeamMember"
													className="pt-9"
													onClick={(order, fieldName) => sortData(fieldName, order)}
												/>
											</th>
											<th className="w-8 pl-5 laptop:pl-3">To be Batched </th>
											< th className="w-8 pl-5 laptop:pl-2 align-baseline pt-13" > Batch </th>
											<th className="w-8 pl-5 laptop:pl-2 pr-2">Consignment <br />No.</th>
										</tr>
									</thead>
									{DeviceList &&
										DeviceList.map((table_Detail, index) => (
											<tbody className="h-50" key={index}>
												< tr className="large-desktop:text-sm text-xs text-#000000 border border-gray-2" >
													<td className="w-8 pl-36 h-50">
														{ToBeBatched && ToBeBatched.toUpperCase() === 'YES' &&
															<label className="control control--checkbox">
																<input
																	type="checkbox"
																	checked={table_Detail.isSelected}
																	onChange={(e) => toggleSelection(e, table_Detail.ReferenceNo)}
																/>
																<div className="control__indicator" />
															</label>
														}
													</td>
													<td className="w-8 large-desktop:text-sm text-xs  leading-17">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
													<td className="leading-17 pl-5 w-20">{table_Detail.ReferenceNo}</td>
													<td className="leading-17 pl-5 w-20">
														{table_Detail.DateTime ? (
															<div>
																{moment((table_Detail.DateTime).split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + (table_Detail.DateTime).split('T')[1].split('.')[0]}
																{' '}
															</div>
														) : ('-')}
													</td>
													<td>
														<td className="w-130 pl-13 ">
															<span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.DeviceIMEI}<br />
															{table_Detail.SecondDeviceIMEI ? (
																<div>
																	<span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondDeviceIMEI}{' '}
																</div>
															) : ('')}
														</td>
													</td>
													<td className="w-20  pl-5">{table_Detail.Make}</td>
													<td className="w-120 pl-5">{table_Detail.Model}</td>
													<td className="w-70 pl-5">{table_Detail.ProductType}</td>
													<td className="w-20  pl-5">{table_Detail.Condition}</td>
													<td className="w-50  pl-5">{table_Detail.StoreCode}</td>
													<td className="w-160 pl-5">{table_Detail.StoreName}</td>
													<td className="w-75 pl-5">{table_Detail.MemberId}</td>
													<td className="w-10 pl-5">{table_Detail.TeamMember}</td>
													<td className="w-10 pl-5">{capitalizeFirst(ToBeBatched)}</td>
													<td className="text-red-1 w-10 pl-5 font-SamsungOne-400">{ }</td>
													<td className="w-100 pl-5">{ }</td>
												</tr>
											</tbody>
										))}
								</table>
							) : (
									<div className="mt-20 large-desktop:h-573 h-269">No Records Found</div>
								)}
							{DeviceList && DeviceList.length > 0 &&
								<div className="flex justify-between mt-20">
									<div>Page {selectedPage + 1} of {pageCount}</div>
									<Paginate
										previousLabel={'<'}
										nextLabel={'>'}
										breakLabel={<a>...</a>}
										breakClassName={'break-me'}
										pageCount={pageCount}
										selectedPage={selectedPage}
										marginPagesDisplayed={2}
										pageRangeDisplayed={2}
										onPageChange={(page) => setSelectedPage(page.selected)}
										containerClassName={'pagination'}
										activeClassName={'active'}
									/>
								</div>
							}
						</div>
					)}
		</div>
	)
};
export default OrderDetails;
