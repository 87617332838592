import React, { useContext } from 'react';
import { store } from '../../store';
import { useActions } from '../../actions';
import { useForm } from 'react-hook-form';
import LabeledInputField, { RenderSelectField, SortSelection,DateInputField } from '../../../utils/custom-fields';
import spanIconUp from '../../images/ic-chevron_down.png'
import spanIconDown from '../../images/ic-chevron_up.png'
import vodafoneImg from '../../images/img-Samsung.png'
import { useEffect, useState } from 'react';
import Paginate from '../pagination'
const moment = require('moment');


const BatchDetails = () => {

	const { state, dispatch } = useContext(store)

	let { LogisticsBatchDetailsInputField, resources, logisticsBatchData, batchSelectedPage, Login, showCanvasReprint, AusPost } = state
	let ConsignmentNo = AusPost && AusPost.ConsignmentNo

	const { BatchItems, TotalRecords } = logisticsBatchData

	const pageLimit = 10
	const totalPages = Math.ceil(TotalRecords / pageLimit)
	const range = (from, to, step = 1) => {
		let i = from;
		const range = [];
		while (i <= to) {
			range.push(i);
			i += step;
		}
		return range;
	}
	let pages = range(1, totalPages)
	const [offset, setOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)

	const setSelectedPage = (index) => {
		actions.setBatchSelectedPage(index)
	}

	useEffect(() => {
		searchBatchDetails();
	}, [true]);

	useEffect(() => {
		return () => {
			actions.setBatchNumber(undefined);
			actions.setBatchConsignmentNumber(undefined);
			actions.setBatchStartDate(undefined);
			actions.setTradeIDNumber(undefined);
			actions.setBatchSelectedPage(0)
			actions.setLogisticsBatchData({})
		}
	}, []);

	const { register, errors, setValue } = useForm({ mode: 'onChange' })
	const actions = useActions(state, dispatch)
	let [data, setData] = useState(BatchItems)
	let [records, setTotalRecords] = useState(TotalRecords)
	const [currentData, setCurrentData] = useState([])
	let selectedBatch = currentData && currentData.filter((data) => data.isSelected === true)[0]

	let filteredData = []

	useEffect(() => {
		if (logisticsBatchData) {
			setData(BatchItems);
			setTotalRecords(TotalRecords);
		}

		getFilteredData();
	}, [logisticsBatchData]);

	useEffect(() => {
		searchBatchDetails();
	}, [batchSelectedPage]);

	useEffect(() => {
		getFilteredData();
	}, [JSON.stringify(data)])

	const getFilteredData = () => {
		filteredData = []
		if (data && data.length > 0) {
			let sortedData = []
			if (data.length > 1) {
				sortedData = [...data].sort(sortData)
			} else {
				sortedData = [...data]
			}
			sortedData.forEach(function (item) {
				if (item.DateTime.includes('T')) {
					let formattedDateTime = ''
					let sDate = item.DateTime.split('T')
					formattedDateTime = moment(sDate[0], 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + sDate[1].split('.')[0]
					item.DateTime = formattedDateTime
				}

				if (!this[item.Batch]) {
					let createdDate = item.BatchCreatedDate.split('T')
					let cdate = moment(createdDate, 'YYYY-MM-DD').format('DD MMM YYYY')
					this[item.Batch] = { BatchCreatedDate: cdate, Batch: item.Batch, ConsignmentNo: item.ConsignmentNo, isSelected: item.isSelected, groupedItems: [] }
					filteredData.push(this[item.Batch])
				}
				this[item.Batch].groupedItems.push(item)
			}, [])
		}
		setCurrentData(filteredData)
	}

	const sortData = (obj1, obj2) => {
		if (obj1.Batch > obj2.Batch) {
			return -1;
		} else {
			return 1;
		}
	}
	const toggleSelection = (id, reprintFlag) => {
		BatchItems &&
			BatchItems.map((el) => {
				if (el.Batch === id) {
					if (reprintFlag) {
						el.isSelected = true
					} else {
						el.isSelected = !el.isSelected
					}
				} else {
					el.isSelected = false
				}

				return el
			})
		actions.setLogisticsBatchData(BatchItems)
	}

	const searchBatchDetails = (isSearch = false) => {
		if (isSearch) {
			actions.setBatchSelectedPage(0)
		}

		let GetBatchDetailsRequest = {
			PartnerStoreId: Login && Login.PartnerStoreId,
			StartDate: LogisticsBatchDetailsInputField.batchStartDate,
			BatchId: LogisticsBatchDetailsInputField.batchNumber,
			ConsignmentId: LogisticsBatchDetailsInputField.consignmentNumber,
			TradeQuoteNbr: LogisticsBatchDetailsInputField.tradeId,
			PageSize: 10,
			PageNumber: isSearch ? 1 : batchSelectedPage + 1,
		}

		actions.getLogisticsBatchData({ GetBatchDetailsRequest })
	}

	const resetFields = () => {
		actions.setBatchNumber(undefined);
		actions.setBatchConsignmentNumber(undefined);
		actions.setBatchStartDate(undefined);
		actions.setTradeIDNumber(undefined);
		if (batchSelectedPage == 0) {
			let GetBatchDetailsRequest = {
				PartnerStoreId: Login && Login.PartnerStoreId,
				PageSize: 10,
				PageNumber: 1
			}
			actions.getLogisticsBatchData({ GetBatchDetailsRequest })
		}
		actions.setBatchSelectedPage(0)
		setValue("batchNo", "")
		setValue("consignmentNo", "")
		setValue("batchStartDate", "")
		setValue("tradeId", "")
	}
	let capitalizeFirst = (string) => {
		return string && string.charAt(0) && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}
	const reprint = async (batch) => {
		toggleSelection(batch, true)
		let CreateParcelRequest = {
			BatchId: batch.toString(),
		}
		let GetBatchDetailsRequest = {
			PartnerStoreId: Login && Login.PartnerStoreId,
			PageSize: 10,
			PageNumber: batchSelectedPage + 1
		}
		actions.createParcelApi({ CreateParcelRequest }, GetBatchDetailsRequest)
	}
	let batchPageCount = Math.ceil(TotalRecords / pageLimit)
	return (
		<div>
			{!showCanvasReprint &&
				<div>
					<div className="flex w-full items-center">
						<h2 className="text-lg font-Samsung-Sharp-Sans-Bold pt-30 pl-3">Search</h2>
					</div>
					<div className="flex">
						<LabeledInputField
							className="order-no-input report-search-font"
							type="text"
							name="batchNo"
							schema="logistics"
							placeholder="Batch No"
							onChange={actions.setBatchNumber}
							register={register}
							errors={errors}
							hideLabel
						/>
						<LabeledInputField
							className="order-no-input report-search-font"
							type="text"
							name="tradeId"
							schema="logistics"
							placeholder="Trade ID"
							onChange={actions.setTradeIDNumber}
							register={register}
							errors={errors}
							hideLabel
						/>
						<DateInputField
						    className= "email-audit-date-input report-search-font"
							onChange={actions.setBatchStartDate}
							placeholder="Date"
							value={LogisticsBatchDetailsInputField && LogisticsBatchDetailsInputField.batchStartDate ? LogisticsBatchDetailsInputField.batchStartDate : ''}
							format="dd-MM-yyyy"
						/>
						<LabeledInputField
							className="consignment-no-input report-search-font"
							type="text"
							name="consignmentNo"
							schema="logistics"
							placeholder="Star Track Consignment No"
							onChange={actions.setBatchConsignmentNumber}
							register={register}
							errors={errors}
							hideLabel
						/>
						<button className="blue-button w-80 h-8 ml-15" onClick={() => searchBatchDetails(true)}>
							SEARCH
            </button>
						<button className="black-button w-80 h-8 ml-10" onClick={() => resetFields()}>
							RESET
            </button>
					</div>
					{currentData && currentData.length > 0 ? (
						<table className="mt-20 text-sm font-Samsung-Sharp-Sans-Bold w-full">
							<thead className="text-transform: uppercase bg-black text-white h-60 text-left w-full">
								<tr className="w-full h-60 border border-gray-2">
									<th className=" h-34 pl-67 w-197">date</th>
									<th className=" h-34 pl-5 w-100">Batch No.</th>
									<th className=" h-34 pl-5">Star Track Consignment No.</th>
									<th className=" h-34 pl-5"></th>
								</tr>
							</thead>
							{currentData &&
								currentData.map((table_Detail, index) => (
									<tbody key={index} className=" border border-gray-2 ">
										<tr className="text-left h-50 text-sm font-Samsung-Sharp-Sans-Bold border border-gray-2 bg-white" className={`${table_Detail.isSelected ? 'batch-active-row-bg ' : ''}`}>
											<td className="h-50 w-197 pl-30">
												<img className="inline-block cursor-pointer font-Samsung-Sharp-Sans-Bold " src={!table_Detail.isSelected ? spanIconUp : spanIconDown} onClick={() => toggleSelection(table_Detail.Batch)} />
												<span className="pl-21 w-130 text-left text-sm font-Samsung-Sharp-Sans-Bold pl-22">{table_Detail.BatchCreatedDate}</span>
											</td>
											<td className="w-100 h-10 pl-5  text-sm font-Samsung-Sharp-Sans-Bold">{table_Detail.Batch}</td>
											<td className=" h-10 pl-5  text-sm font-Samsung-Sharp-Sans-Bold">{table_Detail.ConsignmentNo}</td>
											<td className=" h-34 pl-5 pr-11">
												<button className={`purple-button ${selectedBatch ? 'w-180' : 'w-150 '} h-10 search-font leading-21 float-right`} onClick={(batch) => reprint(table_Detail.Batch)}>
													{table_Detail.ConsignmentNo ? 'Reprint' : 'PRINT & CONFIRM PICK UP'}
												</button>
											</td>
										</tr>
										{table_Detail.isSelected && table_Detail.groupedItems && (
											<tr className="text-left  text-sm border border-purple-2  bg-white ">
												<td colSpan="3" className="p-0">
													{/*  <table className="thead text-sm border-whitefull w-full  "> */}
													< table className={`large-desktop:w-127  tablet:w-117 w-116 text-sm border border-purple-2`} >
														<thead>
															<tr className="text-transform: uppercase border border-grey-1 bg-grey-1 text-white text-left  font-Samsung-Sharp-Sans-Bold">
																<th className=" h-10 pl-67">Trade ID</th>
																<th className=" h-10 pl-5">Date/Time</th>
																<th className=" h-10 pl-5">Device IMEI</th>
																<th className="h-10 pl-5">Make</th>
																<th className="h-10 pl-5">Model/Variant</th>
																<th className="h-10  pl-5">Product Type</th>
																<th className="h-10  pl-5">Condition</th>
																<th className=" h-10 pl-5">Store Code</th>
																<th className=" h-34 pl-5">Store Name/<br />Location</th>
																<th className="h-10  pl-5">Member ID</th>
																<th className="h-10 pl-5">Team Member</th>
																<th className="h-10  pl-5">Status</th>
																<th className="h-10  pl-5">Batch</th>
																<th className="h-10 pl-5">Consignment No.</th>
															</tr>
														</thead>
														{table_Detail.groupedItems.map((item, index) => (
															<tbody key={index}>
																<tr className="text-left h-10 text-sm  border border-grey-1 bg-white  font-SamsungOne-400">
																	<td className=" h-10 pl-67">{item.ReferenceNo}</td>
																	<td className=" h-10 pl-5">{item.DateTime}</td>
																	<td className=" h-50 pl-5 text-left">{item.DeviceIMEI}</td>
																	<td className="h-10 pl-5">{item.Make}</td>
																	<td className=" h-10 pl-5">{item.Model}</td>
																	<td className="h-10  pl-5">{item.ProductType}</td>
																	<td className="h-50  pl-5">{item.Condition}</td>
																	<td className="h-10 pl-5 ">{item.StoreCode}</td>
																	<td className=" h-10 pl-5">{item.StoreName}</td>
																	<td className="h-10 pl-5">{item.MemberId}</td>
																	<td className="h-10  pl-5">{item.TeamMember}</td>
																	<td className="h-10  pl-5"> {item.Status || '-'}</td>
																	<td className="h-10 pl-5 text-red-1">{item.Batch}</td>
																	<td className="h-10 pl-5">{item.ConsignmentNo}</td>
																</tr>
															</tbody>
														))}
													</table>
												</td>
											</tr>
										)}
									</tbody>
								))}
						</table>
					) : (
							<div className="mt-20 large-desktop:h-573 h-269">No Records Found</div>
						)}
					{currentData && currentData.length > 0 &&
						<div className="flex justify-between mt-20">
							<div>
								Page {batchSelectedPage + 1} of {batchPageCount}
							</div>
							<Paginate
								previousLabel={'<'}
								nextLabel={'>'}
								breakLabel={<a>...</a>}
								breakClassName={'break-me'}
								pageCount={batchPageCount}
								selectedPage={batchSelectedPage}
								marginPagesDisplayed={2}
								pageRangeDisplayed={2}
								onPageChange={(page) => setSelectedPage(page.selected)}
								containerClassName={'pagination'}
								activeClassName={'active'}
							/>
						</div>}

				</div>}

			{showCanvasReprint && (
				<div>
					<div id="reprintme" className="w-1064 h-900 mt-48 font-SamsungOne-400">
						<img className="py-14 inline-block mr-70" src={vodafoneImg} alt="samsung-img" />
						<div className="float-right inline-block">
							<p className="mt-20 log-print-date-font leading-16">
								{moment().format('D MMMM YYYY h:mm:ss')}
							</p>
						</div>
						<table className="mt-30 text-sm log-print-border">
							<thead>
								<tr className="text-transform: uppercase border-white log-print-header-color text-white h-60 text-left font-SamsungOne-400">
									<th className="w-50 pl-5">
										{'>= 1'} <br />
										days
									</th>
									<th className="w-20 h-34 pl-5">
										Reference <br />
										No.
									</th>
									<th className="w-20 h-34 pl-5">
										Date/
										<br />
										Time
									</th>
									<th className="h-34 w-130 pl-5">Device IMEI</th>
									<th className="h-34 w-130 pl-5">
										Device Name <br />
									</th>
									<th className="h-34 w-70 pl-5">
										Product
										<br />
										Type
									</th>
									<th className="w-20 h-34 pl-5">Condition</th>
									<th className="w-50 h-34 pl-5">
										Store <br />
										Code
									</th>
									<th className="h-34 w-130 pl-5">
										Store Name/
										<br />
										Location
									</th>
									<th className="h-34 w-75 pl-5">Member ID</th>
									<th className="h-34 w-116 pl-5">
										Consignment <br />
										No.
									</th>
								</tr>
							</thead>
							{
								selectedBatch &&
								selectedBatch.groupedItems &&
								selectedBatch.groupedItems.map((table_Detail, index) => (
									//table_Detail.groupedItems.map((item, index) => (
									/* batchingItems &&
												batchingItems.map((table_Detail, index) => ( */
									<tbody key={index}>
										<tr className="text-left text-black h-50 text-sm log-print-border bg-white">
											<td className="w-50 pl-5">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
											<td className="w-20 h-50 pl-5">{table_Detail.ReferenceNo}</td>
											<td className="w-20 h-50 pl-5 text-left">
												{table_Detail.DateTime ? (
													<div>
														{table_Detail.DateTime.split(' ')[0]}
														<br />
														{table_Detail.DateTime.split(' ')[1]}{' '}
													</div>
												) : (
														'-'
													)}
											</td>
											<td>
												<td className="w-130 h-50 pl-5">
													<span className=" font-bold">{'P'}&nbsp;</span>
													{table_Detail.DeviceIMEI}
													<br />
													{table_Detail.SecondDeviceIMEI ? (
														<div>
															<span className=" font-bold">{'S'}&nbsp;</span>
															{table_Detail.SecondDeviceIMEI}{' '}
														</div>
													) : (
															''
														)}
												</td>
											</td>
											<td className="h-34 w-130 pl-5">
												{table_Detail.Make} <br />
												{table_Detail.Model}
											</td>
											<td className="h-50 w-70 pl-5">{table_Detail.ProductType}</td>
											<td className="w-20 h-50 pl-5 text-center">{table_Detail.Condition}</td>
											<td className="w-50 h-50 pl-5">{table_Detail.StoreCode}</td>
											<td className="h-50 w-130 pl-5">{table_Detail.StoreName}</td>
											<td className="h-50 w-75 pl-5">{table_Detail.MemberId}</td>
											<td className="h-50 w-116 pl-5">{ConsignmentNo || table_Detail.ConsignmentNo}</td>
										</tr>
									</tbody>
								))
								//))
							}
						</table>
					</div>
				</div>
			)}
		</div>
	)
}
export default BatchDetails
