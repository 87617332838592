import React, { useContext } from 'react';
import { store } from '../../store';
import { useActions } from '../../actions';
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import LabeledInputField from '../../../utils/custom-fields';
import vodafoneImg from '../../images/img-Samsung.png'
import { RenderSelectField, SortSelection } from '../../../utils/custom-fields';
const moment = require('moment');

const BatchedOrder = ({ batchingItems }) => {
	const { state, dispatch } = useContext(store)
	const actions = useActions(state, dispatch)
	let { resources, AusPost, showCanvas, isError } = state
	let ConsignmentNo = AusPost && AusPost.ConsignmentNo

	const printAndConfirm = async () => {
		let CreateParcelRequest = {
			BatchId: batchingItems && batchingItems[0].BatchId.toString(),
		}

		actions.createParcelApi({ CreateParcelRequest })
	}
	let capitalizeFirst = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	let columnsList = [
		{ columnName: "Make", order: "" },
		{ columnName: "Model", order: "" },
		{ columnName: "ProductType", order: "" },
		{ columnName: "Condition", order: "" },
		{ columnName: "StoreCode", order: "" },
		{ columnName: "StoreName", order: "" },
		{ columnName: "MemberId", order: "" },
		{ columnName: "TeamMember", order: "" },
		{ columnName: "ReferenceNo", order: "" },
		{ columnName: "LessThan5Days", order: "" }

	];

	const [sortColumnsList, setSortOrder] = useState(columnsList)


	const sortData = (fieldName, order) => {
		sortColumnsList.map((item) => {
			if (item.columnName === fieldName) {
				item.order = order
			} else {
				item.order = ""
			}
		});
		setSortOrder(sortColumnsList);
		if (batchingItems) {
			actions.setBatchItemsData(batchingItems.sort(dynamicsort(fieldName, order)))
		}
	}

	const dynamicsort = (property, order) => {
		var sort_order = 1;
		if (order === "DESC") {
			sort_order = -1;
		}
		return function (obj1, obj2) {
			// obj1 should come before obj2 in the sorted order
			if (obj1[property] < obj2[property]) {
				return -1 * sort_order;
				// obj1 should come after obj2 in the sorted order
			} else if (obj1[property] > obj2[property]) {
				return 1 * sort_order;
				// obj1 and obj2 are the same
			} else {
				return 0 * sort_order;
			}
		}
	}

	return (
		<div>
			{!showCanvas && (
				<div>
					<div className="flex w-full align-items-center ">
						<button
							className={`${AusPost ? 'disable-button' : 'purple-button1'} w-200 h-10 ml-auto text-lg leading-21`}
							disabled={AusPost}
							onClick={() => printAndConfirm()}>PRINT & CONFIRM PICK UP
						</button>
					</div>
					<div>
						< table className="mt-5 large-desktop:text-sm text-xs border-white w-full h-50 border border-gray-2" >
							<thead className="text-transform: uppercase border-white bg-black text-white h-50 text-left ">
								<tr>
									< th className="h-34 w-8 pl-8 large-desktop:text-sm text-xs leading-17 sortEnabledColumn" >
										<span className="columnTitle">{'>=1'} <br />days</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="leading-17 pl-5 w-8 h-34 ">Reference <br />No.</th>
									<th className="w-8 pl-5 leading-17">Date/<br />Time</th>
									<th className="w-8 pl-5 align-baseline large-desktop:pt-13 pt-12">Device IMEI</th>
									<th className="w-8  pl-5 align-baseline large-desktop:pt-13 pt-12 sortEnabledColumn">
										<span className="columnTitle">Make </span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="w-8 pl-5 sortEnabledColumn">
										<span className="columnTitle">Model/ <br />Variant</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="w-8 pl-5 sortEnabledColumn">
										<span className="columnTitle">Product<br />Type</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									< th className="w-8 pl-5 align-baseline large-desktop:pt-13 pt-12 sortEnabledColumn " >
										<span className="columnTitle">Condition</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="w-8  pl-5 sortEnabledColumn">
										<span className="columnTitle">Store <br />Code</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>

									< th className="large-desktop:w-8 w-15 pb-2 pl-5  align-baseline large-desktop:pt-13 pt-12 sortEnabledColumn" >
										<span className="columnTitle">Store Name/<br />Location</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									< th className="w-8 pl-5  align-baseline large-desktop:pt-13 pt-12 sortEnabledColumn" >
										<span className="columnTitle">Member ID</span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="w-8 pl-5  align-baseline large-desktop:pt-13 pt-12 sortEnabledColumn">
										<span className="columnTitle">Team member </span>
										<SortSelection
											sortOrder={sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
											fieldName="LessThan5Days"
											className="pt-9"
											onClick={(order, fieldName) => sortData(fieldName, order)}
										/>
									</th>
									<th className="w-8 pl-5 laptop:pl-2">To be Batched </th>
									<th className="w-8 pl-5 laptop:pl-2 align-baseline large-desktop:pt-13 pt-12">Batch</th>
									<th className="w-8 pl-5 laptop:pl-2 pr-2">Consignment <br />No.</th>
								</tr>
							</thead>
							{batchingItems &&
								batchingItems.map((table_Detail, index) => (
									<tbody key={index}>
										< tr className="large-desktop:text-sm text-xs text-#000000" >
											<td className="w-10 h-34 large-desktop:text-sm text-xs leading-17 pl-8">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
											<td className="w-20 h-50 pl-5">{table_Detail.ReferenceNo}</td>
											<td className="w-20 h-50 pl-5">
												{table_Detail.DateTime ? (
													<div>
														{
															moment(table_Detail.DateTime)
																.format('DD/MM/YYYY h:mm:ss')
																.split(' ')[0]
														}<br />
														{
															moment(table_Detail.DateTime)
																.format('DD/MM/YYYY h:mm:ss')
																.split(' ')[1]
														}{' '}
													</div>
												) : ('-')}
											</td>
											<td>
												<td className="w-130  pl-5">
													<span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.DeviceIMEI}<br />
													{table_Detail.SecondDeviceIMEI ? (
														<div>
															<span className=" font-bold">{'S'}&nbsp;</span>
															{table_Detail.SecondDeviceIMEI}{' '}
														</div>
													) : ('')}
												</td>
											</td>
											<td className="w-20 h-34 pl-5">{table_Detail.Make}</td>
											<td className="h-34 w-120 pl-5">{table_Detail.Model}</td>
											<td className="h-34 w-70 pl-5">{table_Detail.ProductType}</td>
											<td className="w-20 h-34 pl-5">{table_Detail.Condition}</td>
											<td className="w-50 h-34 pl-5">{table_Detail.StoreCode}</td>
											<td className="h-34 w-130 pl-5">{table_Detail.StoreName}</td>
											<td className="h-34 w-75 pl-5">{table_Detail.MemberId}</td>
											<td className="h-34 w-120 pl-5">{table_Detail.TeamMember}</td>
											<td className="h-34 w-20 pl-5">Yes</td>
											<td className="text-red-1 h-34 w-10 pl-5 font-SamsungOne-400 text-transform: lowercase">{table_Detail.BatchId}</td>
											<td className="h-34 w-100 pl-5">{ConsignmentNo}</td>
										</tr>
									</tbody>
								))}
						</table>
						{isError && (
							<div className="text-red-1 text-center mt-2">Something went wrong.Please try again.</div>
						)}
					</div>
				</div>
			)}
			{showCanvas && (
				<div>
					<div id="printme" className="w-1064 h-900 mt-48 font-SamsungOne-400">
						<img className="py-14 inline-block mr-70" src={vodafoneImg} alt="samsung-img" />
						<div className="float-right inline-block">
							<p className="mt-20 log-print-date-font leading-16">
								{moment().format('D MMMM YYYY h:mm:ss')}
							</p>
						</div>
						< table className="mt-30 large-desktop:text-sm text-xs log-print-border" >
							<thead>
								<tr className="text-transform: uppercase border-white log-print-header-color text-white h-60 text-left font-SamsungOne-400">
									<th className="w-50 pl-5">{'>= 1'} <br />days</th>
									<th className="w-20 h-34 pl-5">Reference <br />No.</th>
									<th className="w-20 h-34 pl-5">Date/<br />Time</th>
									<th className="h-34 w-130 pl-5">Device IMEI</th>
									<th className="h-34 w-130 pl-5">Device Name <br /></th>
									<th className="h-34 w-70 pl-5">Product<br />Type</th>
									<th className="w-20 h-34 pl-5">Condition</th>
									<th className="w-50 h-34 pl-5">Store <br />Code</th>
									<th className="h-34 w-130 pl-5">Store Name/<br />Location</th>
									<th className="h-34 w-75 pl-5">Member ID</th>
									<th className="h-34 w-116 pl-5">Consignment <br />No.</th>
								</tr>
							</thead>
							{batchingItems &&
								batchingItems.map((table_Detail, index) => (
									<tbody key={index}>
										< tr className="text-left text-black h-50 large-desktop:text-sm text-xs log-print-border bg-white" >
											<td className="w-50 pl-5">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
											<td className="w-20 h-50 pl-5">{table_Detail.ReferenceNo}</td>
											<td className="w-20 h-50 pl-5 text-left">
												{table_Detail.DateTime ? (
													<div>
														{
															moment(table_Detail.DateTime)
																.format('DD/MM/YYYY h:mm:ss')
																.split(' ')[0]
														}
														<br />
														{
															moment(table_Detail.DateTime)
																.format('DD/MM/YYYY h:mm:ss')
																.split(' ')[1]
														}{' '}
													</div>
												) : (
														'-'
													)}
											</td>
											<td>
												<td className="w-130 h-50 pl-5">
													<span className=" font-bold">{'P'}&nbsp;</span>
													{table_Detail.DeviceIMEI}
													<br />
													{table_Detail.SecondDeviceIMEI ? (
														<div>
															<span className=" font-bold">{'S'}&nbsp;</span>
															{table_Detail.SecondDeviceIMEI}{' '}
														</div>
													) : (
															''
														)}
												</td>
											</td>
											<td className="h-34 w-130 pl-5">
												{table_Detail.Make} <br />
												{table_Detail.Model}
											</td>
											<td className="h-50 w-70 pl-5">{table_Detail.ProductType}</td>
											<td className="w-20 h-50 pl-5 text-center">{table_Detail.Condition}</td>
											<td className="w-50 h-50 pl-5">{table_Detail.StoreCode}</td>
											<td className="h-50 w-130 pl-5">{table_Detail.StoreName}</td>
											<td className="h-50 w-75 pl-5">{table_Detail.MemberId}</td>
											<td className="h-50 w-116 pl-5">{ConsignmentNo}</td>
										</tr>
									</tbody>
								))}
						</table>
					</div>
				</div>
			)}
		</div>
	);
};

export default BatchedOrder;
